import React, { useState } from 'react'
import { HexColorInput, HexColorPicker, RgbColorPicker } from 'react-colorful'
import { ArrowDown } from 'src/assets/svgs-components'
import {
    checkColorFormat,
    convertColor,
} from 'src/components/color-picker/color-picker.helper'
import { Box, Input, Text } from 'src/components/utility'

export default function ColorPanel({
    initialColor,
    onColorChange,
}: {
    onColorChange?: (color: string) => void
    initialColor?: string
}) {
    const [switcherVal, setSwitcherVal] = useState(
        initialColor ? checkColorFormat(initialColor) : 'Hex'
    )

    const initialColorValue = initialColor
        ? convertColor(initialColor)
        : { hex: '#FFFFFF', rgb: { r: 255, g: 255, b: 255 } }

    const [hexColor, setHexColor] = useState(initialColorValue.hex)
    const [rgbaColor, setRgbaColor] = useState(initialColorValue.rgb)

    const [isColorPointer, setIsColorPointer] = useState(false)

    const alternateColor = switcherVal === 'Hex' ? 'RGB' : 'Hex'
    const alternativeColorValue =
        switcherVal === 'Hex'
            ? `${convertColor(hexColor).rgb.r},${
                  convertColor(hexColor).rgb.g
              },${convertColor(hexColor).rgb.b}`
            : convertColor(`rgb(${rgbaColor.r},${rgbaColor.g},${rgbaColor.b})`)
                  .hex

    return (
        <Box position="relative">
            <Box id="picker" position="relative">
                <Box
                    id="color-picker-box"
                    aria-orientation="horizontal"
                    zIndex={15}
                    bg="white"
                    p={'8px'}
                    borderRadius="8px"
                    border="1px solid #E8ECEF"
                >
                    {switcherVal === 'Hex' ? (
                        <HexColorPicker
                            className="react-colorful__hexcolor_picker"
                            color={hexColor}
                            inputMode="text"
                            onChange={(hex) => {
                                setHexColor(hex)
                                onColorChange && onColorChange(hex)
                            }}
                        />
                    ) : (
                        <RgbColorPicker
                            color={rgbaColor}
                            onChange={(newCol) => {
                                setRgbaColor(newCol)
                                setIsColorPointer(true)
                                onColorChange &&
                                    onColorChange(
                                        convertColor(
                                            `rgb(${newCol.r},${newCol.g},${newCol.b})`
                                        ).hex
                                    )
                            }}
                        />
                    )}
                    <Box
                        border="1px solid #E8ECEF"
                        p={'8px'}
                        mt="12px"
                        borderRadius="4px"
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        id="hex-color-input-wrapper"
                    >
                        <Box
                            border="1px solid #E8ECEF"
                            borderRadius="4px"
                            height="25px"
                            width="69px"
                            mr="12px"
                            px="4px"
                            py="10px"
                            justifyContent="space-between"
                            display="flex"
                            flexDirection="row"
                            alignItems="center"
                        >
                            <Text my="0px">{switcherVal}</Text>
                            <Box
                                display="flex"
                                flexDirection="column"
                                justifyContent="space-between"
                                gridGap="4px"
                                onClick={() => {
                                    switcherVal === 'Hex'
                                        ? setSwitcherVal('RGB')
                                        : setSwitcherVal('Hex')
                                }}
                            >
                                <ArrowDown
                                    style={{ transform: 'rotate(180deg)' }}
                                />
                                <ArrowDown />
                            </Box>
                        </Box>
                        <Box mr="4px">
                            {switcherVal === 'Hex' ? (
                                <HexColorInput
                                    id="hex-color-input"
                                    onInput={(e) => {
                                        setHexColor(e.currentTarget.value)
                                        onColorChange &&
                                            onColorChange &&
                                            onColorChange(e.currentTarget.value)
                                    }}
                                    color={hexColor}
                                    onChange={setHexColor}
                                    style={{
                                        maxWidth: '132px',
                                        height: '21.5px',
                                        border: '1px solid #E8ECEF',
                                        borderRadius: '4px',
                                    }}
                                    placeholder="Type a color"
                                    prefixed
                                    alpha
                                />
                            ) : (
                                <Input
                                    bg="white"
                                    style={{
                                        maxWidth: '132px',
                                        height: '25px',
                                        border: '1px solid #E8ECEF',
                                        borderRadius: '4px',
                                    }}
                                    onChange={(e) => {
                                        setIsColorPointer(false)
                                        const newRgbInput = e.target.value
                                        const [r, g, b] = newRgbInput
                                            .split(',')
                                            .map((value) =>
                                                parseInt(value.trim(), 10)
                                            )
                                        if (
                                            !isNaN(r) &&
                                            !isNaN(g) &&
                                            !isNaN(b)
                                        ) {
                                            onColorChange &&
                                                onColorChange(
                                                    convertColor(
                                                        `rgb(${r},${g},${b})`
                                                    ).hex
                                                )
                                            setRgbaColor({
                                                r: r,
                                                g: g,
                                                b: b,
                                            })
                                        }
                                    }}
                                    value={
                                        isColorPointer
                                            ? `${rgbaColor.r},${rgbaColor.g},${rgbaColor.b}`
                                            : undefined
                                    }
                                    defaultValue={`${rgbaColor.r},${rgbaColor.g},${rgbaColor.b}`}
                                />
                            )}
                        </Box>
                    </Box>
                    <Box
                        display="flex"
                        gridGap="8px"
                        alignItems="center"
                        px="4px"
                    >
                        <Text fontSize={'14px'} color="textSecondary">
                            {alternateColor}:
                        </Text>
                        <Text fontSize={'14px'} color="primary">
                            {alternativeColorValue}
                        </Text>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}
