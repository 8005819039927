import * as React from 'react'
import { Box, Image, Text } from 'src/components/utility'
import { uuid } from 'src/helpers'
import { useConfiguratorContext } from '../../configurator.context'
import { createMaterial } from '../../helper/create-material'

export interface IFabricBasicProps {}

const fabricPresets = [
    {
        name: 'Leather',
        id: uuid(),
        baseMap: '/fabrics/leather/WHL-Y1206_BASE.jpg',
        maps: [
            {
                map: '/fabrics/leather/WHL-Y1206_BASE.jpg',
                code: 'map',
            },
            {
                map: '/fabrics/leather/WHL-Y1206_NRM.jpg',
                code: 'normalMap',
            },
            {
                map: '/fabrics/leather/WHL-Y1206_MTL.jpg',
                code: 'metalnessMap',
            },
            {
                map: '/fabrics/leather/WHL-Y1206_DISP.jpg',
                code: 'displacementMap',
            },
            {
                map: '/fabrics/leather/WHL-Y1206_ROUGH.jpg',
                code: 'roughnessMap',
            },
        ],
    },
    {
        name: 'Cotton',
        id: uuid(),
        baseMap: '/fabrics/cotton/12 OZ RECYCLED CANVAS_BASE.png',
        maps: [
            {
                map: '/fabrics/cotton/12 OZ RECYCLED CANVAS_BASE.png',
                code: 'map',
            },
            {
                map: '/fabrics/cotton/12 OZ RECYCLED CANVAS_NRM.png',
                code: 'normalMap',
            },
            {
                map: '/fabrics/cotton/12 OZ RECYCLED CANVAS_MTL.png',
                code: 'metalnessMap',
            },
            {
                map: '/fabrics/cotton/12 OZ RECYCLED CANVAS_DISP.png',
                code: 'displacementMap',
            },
            {
                map: '/fabrics/cotton/12 OZ RECYCLED CANVAS_ROUGH.png',
                code: 'roughnessMap',
            },
        ],
    },
]

export default function FabricBasic(props: IFabricBasicProps) {
    const { highlights } = useConfiguratorContext()
    const [activeFabric, setActiveFabric] = React.useState<{
        name: string
        id: string
        baseMap: string
        maps: {
            map: string
            code: string
        }[]
    }>()

    function handleFabricUpdate(fabric: {
        name: string
        id: string
        baseMap: string
        maps: {
            map: string
            code: string
        }[]
    }) {
        highlights.map((highlight) => {
            if (highlight.isActive) {
                fabric?.maps.map((map) => {
                    console.log(highlight.mesh.material)
                    highlight.mesh.material[map.code] = createMaterial(
                        map.map,
                        {
                            offset: {
                                u:
                                    highlight.mesh?.material[map.code]?.offset
                                        ?.x || 0,
                                v:
                                    highlight.mesh?.material[map.code]?.offset
                                        ?.y || 0,
                            },
                            scale: {
                                u:
                                    highlight.mesh?.material[map.code]?.repeat
                                        ?.x || 0,
                                v:
                                    highlight.mesh?.material[map.code]?.repeat
                                        ?.y || 0,
                            },
                        }
                    ).upscale
                })
            }
        })
    }

    return (
        <Box px="16px" display="flex" gridColumnGap="4px">
            {fabricPresets.map((fabric) => {
                return (
                    <Box
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                        width="max-content"
                        alignItems="center"
                        className="cursor-pointer"
                        border="solid"
                        borderColor={
                            activeFabric?.id === fabric.id ? 'primary' : 'white'
                        }
                        borderRadius="4px"
                        borderWidth={1}
                        onClick={() => {
                            setActiveFabric(fabric)
                            handleFabricUpdate(fabric)
                        }}
                    >
                        <Box
                            borderRadius="4px"
                            display="flex"
                            flexDirection="column"
                            justifyContent="center"
                            width="max-content"
                            alignItems="center"
                            gridRowGap="4px"
                            border="solid"
                            borderColor="white"
                            borderWidth={1}
                        >
                            <Image
                                borderRadius="4px"
                                width="52px"
                                height="52px"
                                src={fabric.baseMap}
                            />
                            <Text my="0px" fontSize="12px">
                                {fabric.name}
                            </Text>
                        </Box>
                    </Box>
                )
            })}
        </Box>
    )
}
