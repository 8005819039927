import React, { useRef, useState } from 'react'

import {
    Dropdown,
    DropdownButtonWrapper,
} from 'src/components/dropdown/dropdown'
import { Button, Box, Input } from 'src/components/utility'
import { useClickedOutside } from 'src/helpers/hooks/use-clicked-outside'
import AssignFromTodoModal from './assign-from-todo-modal'

function UploadThumbnailMenuDropdown({
    active,
    onClose,
    entityId,
    entityName,
    onUpload,
    productId,
    refetchProduct,
}: {
    active: boolean
    onClose: () => void
    entityId: number
    entityName:
        | 'todos'
        | 'products'
        | 'sub_todos'
        | 'collections'
        | 'media'
        | 'product_variants'
    productId: number
    onUpload: (e: React.ChangeEvent<HTMLInputElement>) => void
    refetchProduct: () => void
}) {
    const wrapperRef = useClickedOutside(onClose, [
        'thumbnail-button',
        'thumbnail-image',
    ])

    const [modalVisibility, setModalVisibility] = useState({
        assignFromTodoModal: false,
    })
    const inputRef = useRef<HTMLInputElement | null>(null)
    function inputClickHandler() {
        if (inputRef && inputRef.current) {
            inputRef.current.click()
        }
    }

    return (
        <Dropdown
            active={active}
            top="28px"
            left="36px"
            right="0px"
            width="224px"
        >
            <Box
                width="100%"
                ref={wrapperRef}
                onClick={(e) => {
                    e.stopPropagation()
                }}
            >
                <DropdownButtonWrapper
                    value="upload_from_computer"
                    width="100%"
                    onClick={inputClickHandler}
                >
                    <Button
                        onClick={(e) => e.preventDefault()}
                        fontFamily="Rubik"
                        color="primary"
                        bg="transparent"
                        border="none"
                        fontSize="14px"
                    >
                        Upload from computer
                    </Button>
                </DropdownButtonWrapper>
                <Input
                    autoComplete="false"
                    multiple={false}
                    type="file"
                    onChange={(e) => {
                        e.preventDefault()
                        onUpload(e)
                    }}
                    accept="image/*"
                    display="none"
                    ref={inputRef}
                    bg="none"
                />
                <DropdownButtonWrapper
                    value="assign_from_todos"
                    width="100%"
                    onClick={() => {
                        setModalVisibility({
                            ...modalVisibility,
                            assignFromTodoModal: true,
                        })
                    }}
                >
                    <Button
                        onClick={(e) => e.preventDefault()}
                        height="100%"
                        fontFamily="Rubik"
                        color="primary"
                        bg="transparent"
                        border="none"
                        fontSize="14px"
                    >
                        Assign from Tech pack
                    </Button>
                </DropdownButtonWrapper>
                {modalVisibility.assignFromTodoModal && (
                    <AssignFromTodoModal
                        isOpen={modalVisibility.assignFromTodoModal}
                        onComplete={() => {
                            refetchProduct()
                            onClose()
                        }}
                        onClose={() => {
                            setModalVisibility({
                                ...modalVisibility,
                                assignFromTodoModal: false,
                            })
                        }}
                        productId={productId}
                        entityId={entityId}
                        entityName={entityName}
                    />
                )}
            </Box>
        </Dropdown>
    )
}

export default UploadThumbnailMenuDropdown
