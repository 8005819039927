import { Html } from '@react-three/drei'
import { useEffect, useRef, useState } from 'react'
import type { Vector3 } from 'three'
import { Box, Button, Input } from '../utility'
import { SendIco } from '../svg-icons'
import { AnnotationImg, AnnotationLink } from '../svg-icons/3d-renderer/actions'
import type {
    AnnotatedComment,
    Link as LinkType,
} from '../3d-renderer/annotation.types'
import { useSearchParams } from 'react-router-dom'
import { getImageUrl, getLocalStorage, uuid } from 'src/helpers'
import { Editor } from '../comments/editor'
import type { useEditor } from '@tiptap/react'
import { toast } from 'react-toastify'
import axios from 'axios'
import { ADD_ANNOTATION_COMMENT } from 'src/services/api/endpoints'
import CommentCard from './comment-card'
import { FilePreview } from 'src/components-v2/file-preview/file-preview'
import { createRedirectionUrl } from 'src/helpers/create-redirection-url'
import {
    checkFileType,
    generateRequestId,
    getThumbnail,
    uploadFileToAnnotation,
} from './annotation-comments.helper'
import { useThree } from '@react-three/fiber'
import { useAnnotationCommentsStore } from 'src/store/hooks/useAnnotationCommentsStore'
import CircleCross from '../svg-icons/circle-cross-ico'
import { useAnnotationAttachmentsStore } from 'src/store/hooks/useAnnotationAttachmentsStore'
import Link from 'src/components-v2/add-links/link'
import ColorPicker from '../color-picker/color-picker'
import { TagComponent } from 'src/screens/products/sub-screens/accordian'
import { useQuery } from '@apollo/client'
import { GetOrgMembers } from 'src/services/graphql/query/get-users'
import { formatColorCode } from 'src/helpers/format-color-code'

type AnnotationCommentsPropsType = {
    activeAnnotationPoint: Vector3
    comments: Array<AnnotatedComment>
    onClose: () => void
    refetch: () => void
    team: any
}

export default function AnnotationCommentInput({
    activeAnnotationPoint,
    comments,
    onClose,
    refetch,
    team,
}: AnnotationCommentsPropsType) {
    const [isFilesUploading, setIsFilesUploading] = useState(false)
    const [files, setFiles] = useState<Array<any>>([])
    const [links, setLinks] = useState<Array<LinkType>>([])
    const [colors, setColors] = useState<Array<any>>([])
    const [editor, setEditor] = useState<ReturnType<typeof useEditor>>()
    const inputRef = useRef<HTMLInputElement | null>(null)
    const [searchParams] = useSearchParams()
    const { fileId, entityName, entityId, ref } = Object.fromEntries(
        searchParams.entries()
    )
    const { camera } = useThree()
    const [isRepliesVisible, setIsRepliesVisible] = useState(true)
    const [isVisible, setIsVisible] = useState<{
        addLinksModal: boolean
    }>({
        addLinksModal: false,
    })
    const { isComparePanelVisible } = useAnnotationCommentsStore()
    const { data: orgMembersData } = useQuery(GetOrgMembers)
    const {
        links: attachmentLinks,
        setShowLinksModal,
        showAddLinksModal,
        removeLink,
        clearLinks,
    } = useAnnotationAttachmentsStore()

    const activeAnnotationPointRef = useRef(activeAnnotationPoint)
    const parentCommentIdRef = useRef<number | null>(null)
    const filesRef = useRef(files)

    useEffect(() => {
        filesRef.current = files
    }, [files])

    // Clear files on change of annotation point
    useEffect(() => {
        activeAnnotationPointRef.current = activeAnnotationPoint
        setFiles([])
        setIsRepliesVisible(true)
    }, [activeAnnotationPoint])

    function openFileDialogueBox() {
        if (inputRef && inputRef.current) {
            inputRef.current.click()
        }
    }

    const parentComment = comments.find((comment) => !comment.parent_id)
    const commentReplies = comments.filter((comment) => {
        return parentComment && comment.parent_id === parentComment.id
    })

    useEffect(() => {
        parentCommentIdRef.current = parentComment?.id || null
    }, [parentComment])

    useEffect(() => {
        setLinks(attachmentLinks)
    }, [attachmentLinks])

    // !QUESTION: Clear links on change of annotation point?
    useEffect(() => {
        return () => {
            setFiles([])
            clearLinks()
        }
    }, [activeAnnotationPoint])

    function onFileUploadProgress(progress: any, path: string) {
        setFiles((_files) =>
            _files.map((file) =>
                file.path === path
                    ? {
                          ...file,
                          progress: (progress.loaded / progress.total) * 100,
                      }
                    : file
            )
        )
    }

    async function onFileInput(files: FileList) {
        let request_id: string | null
        try {
            request_id = await generateRequestId({
                totalFiles: files.length,
            })
        } catch (error) {
            toast('Failed to generate request id', {
                className: 'toaster-error',
            })
            return
        }
        if (!!request_id) {
            setIsFilesUploading(true)
            const filesToUpload = Array.from(files)
            const uploadPromises = filesToUpload.map(async (file: any) => {
                if (!checkFileType(file.name)) {
                    return
                }
                const objURL = URL.createObjectURL(file)
                setFiles((_files) => [
                    ..._files,
                    {
                        name: file.name,
                        path: objURL,
                        blob: file,
                        id: uuid(),
                    },
                ])
                try {
                    const response = await uploadFileToAnnotation({
                        file: file,
                        requestId: request_id!,
                        uploadProgress: (progress) =>
                            onFileUploadProgress(progress, objURL),
                    })
                    setFiles((_files) => {
                        return _files.map((_file) => {
                            if (_file.path === objURL) {
                                return {
                                    ..._file,
                                    id: response?.id,
                                    gid: response?.gid,
                                }
                            }
                            return _file
                        })
                    })
                } catch (error) {
                    toast('Failed to upload file', {
                        className: 'toaster-error',
                    })
                }
            })
            await Promise.all(uploadPromises)
            setIsFilesUploading(false)
        }
    }

    async function onFileDragged(event: React.DragEvent<HTMLDivElement>) {
        try {
            event.preventDefault()
            const data = event.dataTransfer.getData('application/json')
            if (!!data) {
                setFiles([...files, { ...JSON.parse(data), is_assigned: true }])
            } else {
                const filesToUpload = event.dataTransfer.files
                let request_id: string | null
                try {
                    request_id = await generateRequestId({
                        totalFiles: filesToUpload.length,
                    })
                } catch (error) {
                    toast('Failed to generate request id', {
                        className: 'toaster-error',
                    })
                    return
                }
                if (!!request_id) {
                    const filesArray = Array.from(filesToUpload)
                    setIsFilesUploading(true)
                    const uploadPromises = filesArray.map(async (file: any) => {
                        if (!checkFileType(file.name)) {
                            return
                        }
                        const objURL = URL.createObjectURL(file)
                        setFiles((_files) => [
                            ..._files,
                            {
                                name: file.name,
                                path: objURL,
                                blob: file,
                                id: uuid(),
                            },
                        ])
                        try {
                            const response = await uploadFileToAnnotation({
                                file: file,
                                requestId: request_id!,
                                uploadProgress: (progess) =>
                                    onFileUploadProgress(progess, objURL),
                            })
                            setFiles((_files) => {
                                return _files.map((_file) => {
                                    if (_file.path === objURL) {
                                        return {
                                            ..._file,
                                            id: response?.id,
                                            gid: response?.gid,
                                        }
                                    }
                                    return _file
                                })
                            })
                        } catch (error) {
                            toast('Failed to upload file', {
                                className: 'toaster-error',
                            })
                        }
                    })
                    await Promise.all(uploadPromises)
                    setIsFilesUploading(false)
                }
            }
        } catch (error) {
            toast('Failed to upload file', { className: 'toaster-error' })
        }
    }

    const onComment = async (editor: ReturnType<typeof useEditor>) => {
        if (editor?.getText() && editor?.getText().length <= 0) {
            toast('Please enter the comment. ', {
                className: 'custom-toaster toaster-error',
            })
            return
        }
        const json = editor?.getJSON()
        const commentArr: any[] = []
        if (json) {
            json.content?.forEach((content) => {
                content.content?.forEach((item) => {
                    if (item.type === 'mention') {
                        commentArr.push({
                            type: 'mention',
                            name:
                                item.attrs?.label === 'Team'
                                    ? team?.name || item.attrs?.label
                                    : item.attrs?.label,
                            entity_id:
                                item.attrs?.label === 'Team' && team.id
                                    ? team.id
                                    : item.attrs?.id,
                            entity_name:
                                item.attrs?.label === 'Team'
                                    ? 'teams'
                                    : 'users',
                        })
                    } else {
                        if (item.text && item.text?.trim().length !== 0) {
                            commentArr.push(item)
                        }
                    }
                })
            })
            filesRef.current?.forEach((file) => {
                if (!file.id) {
                    return
                } else if (file?.is_assigned) {
                    commentArr.push({
                        type: 'media',
                        name: file.name,
                        entity_name: file.from_library
                            ? 'library_files'
                            : 'files',
                        entity_id: file.id,
                    })
                } else {
                    commentArr.push({
                        type: 'media',
                        name: file.name,
                        entity_name: 'media',
                        entity_id: file.id,
                    })
                }
            })
            links.forEach((link) => {
                commentArr.push({
                    type: 'links',
                    text: link.text,
                    url: link.url,
                })
            })
            colors.forEach((color) => {
                commentArr.push({
                    type: 'colors',
                    color: color,
                    name: null,
                })
            })
            if (commentArr.length > 0) {
                try {
                    const response = await axios.post(
                        ADD_ANNOTATION_COMMENT,
                        {
                            comment: commentArr,
                            entity_name: entityName,
                            entity_id: Number(entityId),
                            file_id: Number(fileId),
                            coordinates: activeAnnotationPointRef.current,
                            parent_id: parentCommentIdRef?.current,
                            metadata: {
                                camera_position: camera.position.toArray(),
                            },
                            library: ref === 'library' ? true : false,
                            file_dimention_type: '3d',
                        },
                        {
                            headers: {
                                'x-auth-token': getLocalStorage('authToken'),
                            },
                        }
                    )
                    if (response.status === 200) {
                        refetch()
                        setFiles([])
                        setLinks([])
                        setColors([])
                        editor?.commands.clearContent(true)
                    } else {
                        toast('Failed to add comment - 1', {
                            className: 'toaster-error',
                        })
                    }
                } catch (e) {
                    toast('Failed to add comment', {
                        className: 'toaster-error',
                    })
                }
            }
        }
        return
    }

    const containerTopOffset =
        commentReplies.length > 0
            ? '-160px'
            : !!parentComment
            ? '-80px'
            : '-40px'

    return (
        <Html
            onDragStart={(e) => {
                console.log(e)
            }}
            onDragOver={(event) => {
                console.log(event)
                event.preventDefault()
            }}
            onDrop={(event: React.DragEvent<HTMLDivElement>) => {
                onFileDragged(event)
            }}
            prepend
            zIndexRange={[2, 2]}
            position={activeAnnotationPoint}
            className="annotation-comments-container"
            center
            onWheel={(e) => e.stopPropagation()}
        >
            <Box
                width={'12px'}
                height={'12px'}
                borderRadius="50%"
                position="absolute"
                top="6px"
                bg="white"
                className="target-point"
            ></Box>
            <Box
                width={isComparePanelVisible ? '2px' : '40px'}
                height={isComparePanelVisible ? '40px' : '2px'}
                bg="white"
                className="target-point"
                position="absolute"
                top="12px"
                left="6px"
            ></Box>
            <Box
                zIndex={10}
                flexDirection="column"
                bg="white"
                display="flex"
                position="absolute"
                left={isComparePanelVisible ? '-180px' : '40px'}
                top={isComparePanelVisible ? '40px' : containerTopOffset}
                className="annotation"
                borderRadius="4px"
                border="solid"
                borderWidth={1}
                borderColor="#B8B8B8"
                width="387px"
                onWheel={(e) => e.stopPropagation()}
                onDragStart={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    console.log(e)
                }}
                onDragOver={(event: any) => {
                    event.stopPropagation()
                    event.preventDefault()
                }}
                onDrop={(event: React.DragEvent<HTMLDivElement>) =>
                    onFileDragged(event)
                }
            >
                <Button
                    onClick={(e) => {
                        e.stopPropagation()
                        onClose()
                    }}
                    bg="transparent"
                    border="none"
                    fontStyle="Rubik"
                    fontSize="12px"
                    color="links"
                    position="absolute"
                    right="-12px"
                    top="-12px"
                    width="max-content"
                    id="collection-menu-button"
                >
                    <CircleCross bg="#778CA2" />
                </Button>
                <Box
                    display="flex"
                    flexDirection="column"
                    maxHeight={'360px'}
                    overflow={'auto'}
                >
                    {!!parentComment && (
                        <Box
                            display="flex"
                            width="100%"
                            flexDirection="column"
                            gridGap="4px"
                        >
                            <CommentCard
                                parentComment={parentComment}
                                repliesCount={commentReplies?.length || 0}
                                isRepliesVisible={isRepliesVisible}
                                setIsRepliesVisible={(isVisible) =>
                                    setIsRepliesVisible(isVisible)
                                }
                                refetch={refetch}
                            />
                            {isRepliesVisible && commentReplies?.length > 0 && (
                                <Box
                                    pl="16px"
                                    pr="4px"
                                    display="flex"
                                    flexDirection="column"
                                    gridGap="4px"
                                    borderLeft="2px solid #B8B8B8"
                                    ml="20px"
                                    // maxHeight="100px"
                                    // overflow="auto"
                                >
                                    {commentReplies.map((comment) => {
                                        return (
                                            <CommentCard
                                                parentComment={comment}
                                                refetch={refetch}
                                            />
                                        )
                                    })}
                                </Box>
                            )}
                        </Box>
                    )}
                    <Box
                        borderLeft={
                            isRepliesVisible && commentReplies?.length > 0
                                ? '2px solid #B8B8B8'
                                : 'none'
                        }
                        ml={
                            isRepliesVisible && commentReplies?.length > 0
                                ? '20px'
                                : '0px'
                        }
                        pl={
                            isRepliesVisible && commentReplies?.length > 0
                                ? '16px'
                                : '4px'
                        }
                        pr="4px"
                        py="4px"
                    >
                        <Box
                            border="1px solid #B8B8B8"
                            borderRadius="4px"
                            py="8px"
                        >
                            <Box
                                width="100%"
                                alignItems="center"
                                justifyContent="space-between"
                                display="flex"
                                borderRadius="4px"
                                bg="white"
                                p="8px"
                            >
                                <Editor
                                    onEnter={(
                                        editor: ReturnType<typeof useEditor>
                                    ) => editor && onComment(editor)}
                                    setEditor={(editor) => setEditor(editor)}
                                    setQuery={() => {}}
                                    team={team}
                                    users={
                                        orgMembersData?.organisation_members ||
                                        []
                                    }
                                ></Editor>

                                <Button
                                    onClick={() => editor && onComment(editor)}
                                    type="submit"
                                    bg="transparent"
                                    display="flex"
                                    alignItems="center"
                                    border="none"
                                    disabled={isFilesUploading}
                                >
                                    <SendIco color={'#022143'} />
                                </Button>
                            </Box>
                            {files?.length > 0 && (
                                <Box
                                    display="flex"
                                    gridGap="8px"
                                    flexWrap="wrap"
                                    mt="8px"
                                >
                                    {files?.map((file: any) => {
                                        return (
                                            <FilePreview
                                                width="80px"
                                                height="84px"
                                                redirectionUrl={createRedirectionUrl(
                                                    {
                                                        path: file.name,
                                                        gid: file.gid,
                                                        ref: 'todo',
                                                    }
                                                )}
                                                isDownloadable
                                                key={file.id}
                                                name={file.name}
                                                url={
                                                    file?.gid
                                                        ? getImageUrl(file.gid)
                                                        : file.path
                                                }
                                                params={[
                                                    {
                                                        key: 'ref',
                                                        value: 'todo',
                                                    },
                                                    {
                                                        key: 'fileId',
                                                        value: file.id,
                                                    },
                                                    {
                                                        key: 'gid',
                                                        value: file.gid,
                                                    },
                                                ]}
                                                fileId={file.id}
                                                thumbnail={
                                                    file?.is_assigned
                                                        ? getThumbnail(file)
                                                        : file.path
                                                }
                                                isUrlRequired={false}
                                                onDelete={() =>
                                                    setFiles((files) =>
                                                        files.filter(
                                                            (_file) =>
                                                                _file.id !==
                                                                file.id
                                                        )
                                                    )
                                                }
                                                isDeletePermitted={true}
                                                redirect={false}
                                                openFileInNewTab={false}
                                                percentage={
                                                    file.progress || '0'
                                                }
                                                showLoader={isFilesUploading}
                                            />
                                        )
                                    })}
                                </Box>
                            )}
                            {links?.length > 0 && (
                                <Box
                                    display="flex"
                                    gridGap="8px"
                                    flexWrap="wrap"
                                    mt="8px"
                                >
                                    {links.map((link) => (
                                        <Link
                                            link={link}
                                            removeLink={() => {
                                                removeLink(link.url)
                                            }}
                                            showRemoveButton={true}
                                        />
                                    ))}
                                </Box>
                            )}
                            {colors?.length > 0 && (
                                <Box
                                    display="flex"
                                    gridGap="8px"
                                    flexWrap="wrap"
                                    mt="8px"
                                >
                                    {colors.map((color) => {
                                        return (
                                            <TagComponent
                                                onUpdate={() => {}}
                                                onDelete={(e) => {
                                                    e.stopPropagation()
                                                    setColors((colors) => {
                                                        return colors.filter(
                                                            (c) => c !== color
                                                        )
                                                    })
                                                }}
                                                name={color}
                                                color={formatColorCode(color)}
                                                key={color}
                                                colorPickerRelativePoint="bottom"
                                                isEditPermitted={true}
                                            />
                                        )
                                    })}
                                </Box>
                            )}
                            <Box
                                display="flex"
                                alignItems="center"
                                gridColumnGap="16px"
                                p="8px"
                                pl={
                                    isRepliesVisible &&
                                    commentReplies?.length > 0
                                        ? '24px'
                                        : '8px'
                                }
                            >
                                <Input
                                    autoComplete="false"
                                    multiple
                                    type="file"
                                    onChange={(
                                        e: React.ChangeEvent<HTMLInputElement>
                                    ) => {
                                        e.stopPropagation()
                                        e.target.files &&
                                            onFileInput(e?.target?.files)
                                    }}
                                    onClick={(e: any) => {
                                        e.target.value = null
                                    }}
                                    display="none"
                                    ref={inputRef}
                                    bg="none"
                                />
                                <Button
                                    onClick={openFileDialogueBox}
                                    p="0px"
                                    border="none"
                                    bg="transparent"
                                >
                                    <AnnotationImg />
                                </Button>
                                <ColorPicker
                                    onAdd={(color: string) => {
                                        setColors((colors) => [
                                            ...colors,
                                            color,
                                        ])
                                    }}
                                />
                                <Button
                                    p="0px"
                                    border="none"
                                    bg="transparent"
                                    onClick={() =>
                                        setShowLinksModal(!showAddLinksModal)
                                    }
                                >
                                    <AnnotationLink />
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Html>
    )
}
