import { useMutation } from '@apollo/client'
import {
    UpdateTodoAssignee,
    UpdateTodoDueDate,
    UpdateTodoStatusAction,
} from 'src/services/graphql/entities/todo/todo.mutation'

function useUpdateTodo(queries?: string[]) {
    const refetchQueries = queries || []
    const [updateTodoDueDateMutation] = useMutation(UpdateTodoDueDate)
    const [updateTodoAssigneeMutation] = useMutation(UpdateTodoAssignee)
    const [updateTodoStatusAction] = useMutation(UpdateTodoStatusAction)

    function updateTodoDueDate({
        id,
        due_date,
        onCompleted,
    }: {
        id: number
        due_date: string
        onCompleted?: () => void
    }) {
        updateTodoDueDateMutation({
            variables: {
                id,
                due_date,
            },
            optimisticResponse: {
                update_todos_by_pk: {
                    __typename: 'todos',
                    id,
                    due_date,
                },
            },
            refetchQueries: [
                'GetTodos',
                'GetTodo',
                'getTodosData',
                'GetProducts',
                'GetProductsForCollection',
                'getProduct',
                'fetchCollectionById',
                'GetTodosByUserId',
                'GetDashboardTodos',
                'GetProductTodos',
                ...refetchQueries,
            ],
            onCompleted,
        })
    }

    function updateTodoStatus({
        id,
        status,
        onCompleted,
    }: {
        id: number
        status: string
        onCompleted?: () => void
    }) {
        updateTodoStatusAction({
            variables: {
                todo_id: String(id),
                status,
            },
            optimisticResponse: {
                update_todos_by_pk: {
                    __typename: 'todos',
                    id,
                    status,
                },
            },
            refetchQueries: [
                'GetTodos',
                'GetTodo',
                'getTodosData',
                'GetProducts',
                'GetProductsForCollection',
                'getProduct',
                'fetchCollectionById',
                'GetTodosByUserId',
                'GetDashboardTodos',
                'GetProductTodos',
                'GetUserTodos',
                ...refetchQueries,
            ],
            onCompleted,
        })
    }

    function updateTodoAssignee({
        todoId,
        assignee_id,
        onCompleted,
    }: {
        todoId: number
        assignee_id: number | null
        onCompleted?: () => void
    }) {
        updateTodoAssigneeMutation({
            variables: {
                id: todoId,
                user_id: assignee_id,
            },
            refetchQueries: [
                'GetTodos',
                'GetTodo',
                'getTodosData',
                'GetProducts',
                'GetProductsForCollection',
                'getProduct',
                'fetchCollectionById',
                'GetTodosByUserId',
                ...refetchQueries,
            ],
            onCompleted,
        })
    }

    return { updateTodoDueDate, updateTodoStatus, updateTodoAssignee }
}

export default useUpdateTodo
