import type { MeshProps } from '@react-three/fiber'
import * as React from 'react'

const Preset: Enviornment[] = [
    {
        file: '1stStudioHDRI_FountaneConfgtr.hdr',
        lights: [
            <directionalLight
                frustumCulled={true}
                position={[20, 30, 30]}
                intensity={1.4}
                visible={true}
                castShadow={true}
            />,
            <directionalLight
                frustumCulled={true}
                position={[-10, 0, 2]}
                intensity={0.5}
                visible={false}
                castShadow={false}
            />,
        ],
    },
    {
        file: '1stStudioHDRI_FountaneConfgtr_adjustd.hdr',
        lights: [
            <directionalLight
                frustumCulled={true}
                position={[20, 30, 30]}
                intensity={1.8}
                visible={true}
                castShadow={true}
            />,
            <directionalLight
                frustumCulled={true}
                position={[-10, 0, 2]}
                intensity={0.8}
                visible={true}
                castShadow={false}
            />,
        ],
    },
    {
        file: 'Studio_MediumContrast.hdr',
        lights: [],
    },
    {
        file: 'Studio_LowContrast.hdr',
        lights: [],
    },
]

const Context = React.createContext<{
    highlights: {
        //@ts-ignore
        mesh: Object3D<Object3DEventMap>
        isActive: boolean
    }[]
    setHighlights: React.Dispatch<
        React.SetStateAction<
            {
                //@ts-ignore
                mesh: Object3D<Object3DEventMap>
                isActive: boolean
            }[]
        >
    >
    maps: Maps
    setMaps: React.Dispatch<React.SetStateAction<Maps>>
    config: Config
    setConfig: React.Dispatch<React.SetStateAction<Config>>
    enviornment: Enviornment
    envList: typeof Preset
    operation: 'pan' | 'annotation' | 'renderImage'
    setOperation: React.Dispatch<
        React.SetStateAction<'pan' | 'annotation' | 'renderImage'>
    >
    setEnviornment: React.Dispatch<React.SetStateAction<Enviornment>>
    artWork: {
        name: string
        path: string
        blob: Blob
        id: string
    }[]
    setArtwork: React.Dispatch<
        React.SetStateAction<
            {
                name: string
                path: string
                blob: Blob
                id: string
            }[]
        >
    >
    activeArtWork:
        | {
              name: string
              path: string
              blob: Blob
              id: string
          }
        | undefined
    setActiveArtwork: React.Dispatch<
        React.SetStateAction<
            | {
                  name: string
                  path: string
                  blob: Blob
                  id: string
              }
            | undefined
        >
    >
    advancedMode: boolean
    setAdvancedMode: React.Dispatch<React.SetStateAction<boolean>>
    imageRenderDimensions: {
        width: number
        height: number
    }
    setImageRenderDimensions: React.Dispatch<
        React.SetStateAction<{
            width: number
            height: number
        }>
    >
    selectedColor: string | undefined
    setSelectedColor: React.Dispatch<React.SetStateAction<string | undefined>>
    setGraphic: React.Dispatch<
        React.SetStateAction<
            | {
                  name: string
                  path: string
                  blob: Blob
                  id: string
                  config: {
                      offset: typeof UV
                      repeat: typeof UV
                      rotation: number
                      transparency: number
                  }
              }
            | undefined
        >
    >
    graphic:
        | {
              name: string
              path: string
              blob: Blob
              id: string
              config: {
                  offset: typeof UV
                  repeat: typeof UV
                  rotation: number
                  transparency: number
              }
          }
        | undefined
}>({
    highlights: [],
    setHighlights: () => {},
    setMaps: () => {},
    operation: 'pan',
    setOperation: () => {},
    setArtwork: () => {},
    artWork: [],
    envList: Preset,
    maps: [
        {
            file: null,
            type: 'Base Map',
            code: 'map',
            sliderType: null,
            id: 1,
        },
        {
            file: null,
            type: 'Normal Map',
            code: 'normalMap',
            sliderType: null,
            id: 2,
        },
        {
            file: null,
            type: 'Roughness Map',
            code: 'roughnessMap',
            sliderType: 'ROUGH_NESS',
            id: 3,
        },
        {
            file: null,
            type: 'Metalness Map',
            code: 'metalnessMap',
            sliderType: 'METAL_NESS',
            id: 5,
        },
        {
            file: null,
            type: 'Displacement Map',
            code: 'displacementMap',
            sliderType: null,
            id: 4,
        },
    ],
    enviornment: {
        file: '',
        lights: [],
    },
    setEnviornment: () => {},
    setConfig: () => {},
    config: {
        scale: {
            u: 1,
            v: 1,
        },
        offset: {
            u: 0,
            v: 0,
        },
        rotation: 0,
        metalnessIntensity: 0,
        roughnessIntensity: 0,
    },
    advancedMode: false,
    setAdvancedMode: () => {},
    activeArtWork: undefined,
    setActiveArtwork: () => {},
    imageRenderDimensions: {
        width: 3840,
        height: 2160,
    },
    setImageRenderDimensions: () => {},
    selectedColor: undefined,
    setSelectedColor: () => {},
    setGraphic: () => {},
    graphic: undefined,
})

const UV = {
    u: 0,
    v: 0,
}

export interface IConfiguratorContextProps {
    children: JSX.Element
}

export default function ConfiguratorContext(props: IConfiguratorContextProps) {
    const { children } = props
    const [operation, setOperation] = React.useState<
        'pan' | 'annotation' | 'renderImage'
    >('pan')
    const [advancedMode, setAdvancedMode] = React.useState(false)
    const [selectedColor, setSelectedColor] = React.useState<
        string | undefined
    >()
    const [activeArtWork, setActiveArtwork] = React.useState<{
        name: string
        path: string
        blob: Blob
        id: string
    }>()
    const [graphic, setGraphic] = React.useState<{
        name: string
        path: string
        blob: Blob
        id: string
        config: {
            offset: typeof UV
            repeat: typeof UV
            rotation: number
            transparency: number
        }
    }>()
    const [artWork, setArtwork] = React.useState<
        {
            name: string
            path: string
            blob: Blob
            id: string
        }[]
    >([])
    const [imageRenderDimensions, setImageRenderDimensions] = React.useState({
        height: 1080,
        width: 2046,
    })
    const [highlights, setHighlights] = React.useState<
        {
            mesh: MeshProps
            isActive: boolean
        }[]
    >([])
    const [config, setConfig] = React.useState({
        scale: {
            u: 1,
            v: 1,
        },
        offset: {
            u: 0,
            v: 0,
        },
        rotation: 0,
        metalnessIntensity: 0,
        roughnessIntensity: 0,
    })
    const [enviornment, setEnviornment] = React.useState<Enviornment>(Preset[0])
    const [maps, setMaps] = React.useState<Maps>([
        {
            file: null,
            type: 'Base Map',
            code: 'map',
            sliderType: null,
            id: 1,
        },
        {
            file: null,
            type: 'Normal Map',
            code: 'normalMap',
            sliderType: null,
            id: 2,
        },
        {
            file: null,
            type: 'Roughness Map',
            code: 'roughnessMap',
            sliderType: 'ROUGH_NESS',
            id: 3,
        },
        {
            file: null,
            type: 'Metalness Map',
            code: 'metalnessMap',
            sliderType: 'METAL_NESS',
            id: 5,
        },
        {
            file: null,
            type: 'Displacement Map',
            code: 'displacementMap',
            sliderType: null,
            id: 4,
        },
    ])
    return (
        <Context.Provider
            value={{
                envList: Preset,
                highlights,
                setHighlights,
                maps,
                setMaps,
                config,
                setConfig,
                enviornment,
                setEnviornment,
                operation,
                setOperation,
                artWork,
                setArtwork,
                advancedMode,
                setAdvancedMode,
                activeArtWork,
                setActiveArtwork,
                imageRenderDimensions,
                setImageRenderDimensions,
                selectedColor,
                setSelectedColor,
                graphic,
                setGraphic,
            }}
        >
            {children}
        </Context.Provider>
    )
}

export const useConfiguratorContext = (hex?: string) => {
    const context = React.useContext(Context)
    return context
}
