import React, { useState } from 'react'
import RecentActivitiesDropdown from 'src/components-v2/recent-activities-dropdown/recent-activities-dropdown'
import type { RecentActivityFilters } from 'src/components-v2/recent-activities-dropdown/recent-activities.types'
import { RecentActivityIco } from 'src/components/svg-icons'
import { Box, Button, Text } from 'src/components/utility'
import { useActivitiesStore } from 'src/store/hooks/useActivitiesStore'

function ProductActivities({ product }: { product: any }) {
    const [showRecentActivity, setShowRecentActivity] = useState(false)
    const [activeFilter, setActiveFilter] =
        useState<RecentActivityFilters>('default')

    const { activities, status } = useActivitiesStore()

    const productActivities = activities.filter(
        (activity: any) =>
            activity.entity_id === String(product.id) &&
            activity.entity_name === 'product_variants'
    )

    return (
        <Box position="relative">
            {productActivities?.length > 0 && (
                <Box
                    bg="primary"
                    width="18px"
                    height="18px"
                    borderRadius="50%"
                    position="absolute"
                    top="-14px"
                    right="0"
                    color="white"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                >
                    <Text fontSize="10px" my="0px">
                        {productActivities.length}
                    </Text>
                </Box>
            )}
            <Button
                onClick={() => setShowRecentActivity(!showRecentActivity)}
                bg="transparent"
                border="none"
                fontStyle="Rubik"
                fontSize="12px"
                color="links"
                position="relative"
                width="max-content"
                id="recent-activity-button"
            >
                <RecentActivityIco active={showRecentActivity} />
            </Button>
            {showRecentActivity && (
                <RecentActivitiesDropdown
                    entityTitle={product.name}
                    recentActivities={productActivities}
                    setShowRecentActivity={setShowRecentActivity}
                    activeFilter={activeFilter}
                    setActiveFilter={setActiveFilter}
                    isLoading={status === 'connecting'}
                />
            )}
        </Box>
    )
}

export default ProductActivities
