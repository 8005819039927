import { gql } from '@apollo/client'

export const GetTodo = gql`
    query GetTodo($id: bigint!) {
        todos_by_pk(id: $id) {
            id
            name
            types
            is_delayed
            creator {
                id
                name
            }
            created_at
            updated_at
            assignee {
                id
                name
                other_data
            }
            description
            due_date
            product_variant {
                id
                product_id
                name
                other_data
                team {
                    id
                    name
                    team_members {
                        user {
                            other_data
                            name
                            id
                        }
                    }
                }
                collection {
                    id
                    name
                    manager_id
                }
            }
            status
            tags
            other_data
            sub_todos(order_by: { created_at: asc }) {
                id
                heading
                design_color
                sub_todo_sequences {
                    id
                    sequence
                }
                files(
                    where: { assigned_as: { _eq: "reference_pic" } }
                    order_by: { id: desc }
                ) {
                    id
                    file {
                        path
                        gid
                        name
                        thumbnail
                        id
                        other_data
                        created_at
                        user {
                            id
                            name
                            other_data
                        }
                    }
                }
                sub_todo_sections(order_by: { created_at: asc }) {
                    id
                    name
                    links
                    colors
                    reference_points {
                        id
                        coordinates
                    }
                    files(order_by: { created_at: desc }) {
                        id
                        file {
                            path
                            gid
                            name
                            thumbnail
                            id
                            other_data
                            moved_to_lib
                            created_at
                            user {
                                id
                                name
                                other_data
                            }
                        }
                        library_file {
                            path
                            gid
                            name
                            thumbnail
                            id
                            other_data
                            created_at
                            creator {
                                id
                                name
                                other_data
                            }
                        }
                    }
                    other_data
                    description
                }
            }
        }
    }
`

export const GetTodoProductId = gql`
    query GetTodoProductId($id: bigint!) {
        todos_by_pk(id: $id) {
            id
            product {
                id
                name
            }
        }
    }
`

export const GetTodoProductTeam = gql`
    query GetTodoProductTeam($id: bigint!) {
        todos_by_pk(id: $id) {
            id
            product_variant {
                id
                team {
                    id
                    name
                    team_members {
                        user {
                            other_data
                            name
                            id
                        }
                    }
                }
            }
        }
    }
`

export const GetPreviousTodo = gql`
    query getPreviousTodo($todoId: bigint!, $productVariantId: bigint!) {
        todos(
            limit: 1
            order_by: { id: desc }
            where: {
                id: { _lt: $todoId }
                product_variant_id: { _eq: $productVariantId }
            }
        ) {
            id
            name
            status
        }
    }
`

export const GetProductTodos = gql`
    query GetProductTodos($id: bigint!) {
        product_variants_by_pk(id: $id) {
            id
            name
            other_data
            collection {
                id
                name
                manager_id
            }
            todos(order_by: { id: asc }) {
                id
                name
                other_data
                due_date
                is_delayed
                status
                assignee {
                    email
                    name
                    id
                    other_data
                    organisation_members {
                        role
                    }
                }
                types
            }
        }
    }
`

export const GetDependentTodos = gql`
    query GetDependentTodos($product_variant_id: bigint!, $todo_id: bigint!) {
        todos(
            where: {
                product_variant_id: { _eq: $product_variant_id }
                id: { _neq: $todo_id }
            }
            order_by: { id: asc }
        ) {
            id
            name
            status
        }
    }
`
