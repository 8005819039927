import React from 'react'
import { CloseIco, DownloadIco } from 'src/components/svg-icons'
import { Box, Button, Text } from 'src/components/utility'
import {
    createRedirectionUrl,
    isFileExcel,
    isFilePDForAi,
    isFilePPT,
} from 'src/helpers/create-redirection-url'
import { getImageUrl, getLibraryFileUrl } from 'src/helpers/get-image-url'
import { libraryDefaultKeys } from 'src/screens/library/library.constants'
import FileComments from '../file-comments'
import ShareIco from 'src/components/svg-icons/share-ico'
import { toast } from 'react-toastify'
import PdfAiPreviewSection from './pdf-ai-preview-section'
import UnableToPreviewSection from './unable-to-preview-section'
import ExcelPptPreviewSection from './excel-ppt-preview-section'
import { ProfileImg } from 'src/components/profile-img/profile-img'
import { getFormattedDate } from 'src/helpers'
import { appendSearchParamsToUrl } from 'src/helpers/append-search-param-to-url'
import ImagePreview from './image-preview'

function PreviewSection({
    onClose,
    file,
    additionalParams = [],
    commentsEntityName,
    isSplitMode,
    entityId,
    productId,
}: {
    onClose: () => void
    file: any
    additionalParams?: Array<{ key: string; value: string | number | null }>
    commentsEntityName: 'assigned_files' | 'product_approval_files'
    isSplitMode?: boolean
    entityId?: number
    productId?: number
}) {
    const fileData = file?.library_file
        ? {
              ...file.library_file,
              from_library: true,
          }
        : file?.file
        ? {
              ...file.file,
              from_library: false,
          }
        : null

    const extension = fileData?.name.substring(
        fileData.name.lastIndexOf('.') + 1
    )
    const ImageExtensions = ['jpeg', 'png', 'jpg']

    const url = fileData.from_library
        ? getLibraryFileUrl(fileData.gid)
        : getImageUrl(fileData.gid)

    const redirectionUrl = createRedirectionUrl({
        path: fileData.name,
        gid: fileData.gid,
        ref: fileData.from_library ? 'library' : 'todo',
    })

    const openInNewTab = (url: string) => {
        const paramsList = [
            {
                key: 'name',
                value: encodeURIComponent(fileData.name),
            },
            {
                key: 'ref',
                value: fileData.from_library ? 'library' : 'todo',
            },
            {
                key: 'fileId',
                value: fileData.id,
            },
            {
                key: 'gid',
                value: fileData.gid,
            },
            {
                key: 'callback_url',
                value: appendSearchParamsToUrl({
                    url: location.pathname,
                    searchParams: {
                        key: 'section_id',
                        value: String(entityId),
                    },
                }),
            },
            ...additionalParams,
        ]
        let paramsString = paramsList
            .map(({ key, value }, index) => {
                if (index === 0) {
                    return `?${key}=${value}`
                } else {
                    return `&${key}=${value}`
                }
            })
            .join('')
        let _url = `${redirectionUrl}${paramsString}`

        _url += `&fileId=${fileData.id}&${libraryDefaultKeys.showFileDetails}=${fileData.id}`

        if (true) {
            _url += `&newTab=1`
            window.open(window.location.origin + `${_url}`, '_blank')
        }
    }

    function shareClickHandler() {
        const paramsList = [
            {
                key: 'url',
                value: encodeURIComponent(url),
            },
            {
                key: 'name',
                value: encodeURIComponent(fileData.name),
            },
            {
                key: 'ref',
                value: fileData.from_library ? 'library' : 'todo',
            },
            {
                key: 'fileId',
                value: fileData.id,
            },
            {
                key: 'gid',
                value: fileData.gid,
            },
            ...additionalParams,
        ]
        let paramsString = paramsList
            .map(({ key, value }, index) => {
                if (index === 0) {
                    return `?${key}=${value}`
                } else {
                    return `&${key}=${value}`
                }
            })
            .join('')
        let _url = `${redirectionUrl}${paramsString}`

        _url += `&fileId=${fileData.id}&${libraryDefaultKeys.showFileDetails}=${fileData.id}`
        navigator.clipboard.writeText(window.location.origin + _url)
        toast('File link copied to the clipboard', {
            className: 'custom-toaster toaster-success',
        })
    }

    function renderPreviewComponent(extension: string) {
        if (ImageExtensions.includes(extension.toLowerCase())) {
            return (
                <ImagePreview
                    key={url}
                    url={url}
                    entityId={entityId || 0}
                    fileId={fileData.id}
                    isLibraryFile={fileData.from_library}
                />
            )
        } else if (isFilePDForAi(extension)) {
            return <PdfAiPreviewSection gid={fileData.gid} url={url} />
        } else if (isFileExcel(extension)) {
            return (
                <ExcelPptPreviewSection
                    url={url}
                    fileName={fileData.name}
                    type="xlsx"
                />
            )
        } else if (isFilePPT(extension)) {
            return (
                <ExcelPptPreviewSection
                    url={url}
                    fileName={fileData.name}
                    type="pptx"
                />
            )
        }
        return (
            <UnableToPreviewSection
                extension={extension}
                url={url}
                onOpenConfigurator={() => {
                    openInNewTab(url)
                }}
            />
        )
    }

    const downloadFileHandler = async (url: string, fileName: string) => {
        const response = await fetch(url)
        const data = await response.blob()
        const blobUrl = URL.createObjectURL(data)
        const link = document.createElement('a')
        link.href = blobUrl
        link.download = fileName
        link.click()
        URL.revokeObjectURL(blobUrl)
    }

    return (
        <Box
            height="100%"
            width="100%"
            display="grid"
            gridTemplateRows={'auto 1fr'}
        >
            <Box
                height={isSplitMode ? 'max-content' : '65px'}
                width="100%"
                display={isSplitMode ? 'flex' : 'grid'}
                gridTemplateColumns={isSplitMode ? '1fr' : '1fr auto'}
                alignItems="center"
                justifyContent={isSplitMode ? 'end' : 'space-between'}
                borderColor="gray"
                py={isSplitMode ? '4px' : '8px'}
                px={isSplitMode ? '16px' : '0px'}
            >
                {!isSplitMode && (
                    <Box
                        display="flex"
                        flexDirection="column"
                        gridGap="8px"
                        width={'100%'}
                        overflow={'hidden'}
                    >
                        <Text
                            my="0px"
                            fontSize="20px"
                            title={fileData.name}
                            minWidth="0"
                            width="100%"
                            overflow={'hidden'}
                            style={{
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                            }}
                        >
                            {fileData.name}
                        </Text>
                        {/* !!TODO: Update the element once the backend sends the respective data */}
                        <Box display="flex" alignItems="center" gridGap="8px">
                            <ProfileImg
                                size="18px"
                                name={
                                    fileData.from_library
                                        ? fileData.creator.name
                                        : fileData.user.name
                                }
                                src={
                                    fileData.from_library
                                        ? fileData.creator.other_data
                                              ?.profile_pic
                                            ? getImageUrl(
                                                  fileData.creator.other_data
                                                      .profile_pic
                                              )
                                            : ''
                                        : fileData.user.other_data?.profile_pic
                                        ? getImageUrl(
                                              fileData.user.other_data
                                                  .profile_pic
                                          )
                                        : ''
                                }
                            />
                            <Text my="0px" fontSize="10px">
                                {getFormattedDate(fileData.created_at)}
                            </Text>
                        </Box>
                    </Box>
                )}
                <Box display="flex" alignItems="center" height={'max-content'}>
                    <Button
                        onClick={shareClickHandler}
                        bg="transparent"
                        border="none"
                        fontStyle="Rubik"
                        fontSize="12px"
                        color="links"
                        position="relative"
                        width="max-content"
                        id="todo-dropdown-button"
                    >
                        <ShareIco />
                    </Button>
                    <Button
                        onClick={(e) => {
                            e.stopPropagation()
                            downloadFileHandler(url, fileData.name)
                        }}
                        bg="transparent"
                        border="none"
                        fontStyle="Rubik"
                        fontSize="12px"
                        color="links"
                        position="relative"
                        width="max-content"
                        id="todo-dropdown-button"
                    >
                        <DownloadIco color={'gray'} />
                    </Button>
                    <FileComments
                        fileId={file.id}
                        entityName={commentsEntityName}
                        productId={productId}
                    />
                    <Button
                        onClick={onClose}
                        bg="transparent"
                        border="none"
                        fontStyle="Rubik"
                        fontSize="12px"
                        color="links"
                        position="relative"
                        width="max-content"
                        id="todo-dropdown-button"
                    >
                        <CloseIco />
                    </Button>
                </Box>
            </Box>
            <Box bg="backgroundColor" width="100%">
                {url && extension && renderPreviewComponent(extension)}
            </Box>
        </Box>
    )
}

export default React.memo(
    PreviewSection,
    (prevProps, nextProps) => prevProps?.file?.id === nextProps?.file?.id
)
