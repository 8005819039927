import React from 'react'
import { ColoredButton } from 'src/components/buttons/colored-button'
import { NO_PREVIEW_LG } from 'src/components/svg-icons/folder-icons/large-icons'
import { Box, Text } from 'src/components/utility'
import { PREVIEW_NOT_SUPPORTED_FILE_TYPES } from 'src/helpers/enums'

function UnableToPreviewSection({
    extension,
    url,
    onOpenConfigurator,
    text = "We're sorry, we can't preview this file type here.",
    showControls = true,
}: {
    extension: string
    url: string
    onOpenConfigurator: () => void
    text?: string
    showControls?: boolean
}) {
    return (
        <Box
            width="100%"
            height="100%"
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            gridGap="16px"
        >
            <NO_PREVIEW_LG />
            <Text
                my="0px"
                mx="16px"
                textAlign="center"
                fontSize="14px"
                color="textSecondary"
            >
                {text}
                {showControls &&
                !PREVIEW_NOT_SUPPORTED_FILE_TYPES.includes(extension)
                    ? ` To view this content, please open it in
                            Configurator.`
                    : ''}
            </Text>
            {showControls ? (
                PREVIEW_NOT_SUPPORTED_FILE_TYPES.includes(extension) ? (
                    <ColoredButton
                        variant="primary"
                        height="max-content"
                        onClick={() => {
                            window.location.href = url
                        }}
                    >
                        Download
                    </ColoredButton>
                ) : (
                    <ColoredButton
                        variant="primary"
                        height="max-content"
                        onClick={onOpenConfigurator}
                    >
                        Open in Configurator
                    </ColoredButton>
                )
            ) : (
                <></>
            )}
        </Box>
    )
}

export default UnableToPreviewSection
