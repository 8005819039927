export const RightArrow = () => (
    <svg
        width="7"
        height="11"
        viewBox="0 0 7 11"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1.89537 10.4047C1.66695 10.6395 1.2898 10.6395 1.06138 10.4047C0.841695 10.1789 0.841695 9.81921 1.06138 9.59338L5.04349 5.50001L1.06138 1.40664C0.841695 1.18082 0.841695 0.821147 1.06138 0.595322C1.2898 0.360522 1.66695 0.360522 1.89537 0.595322L5.9884 4.80271C6.36603 5.19089 6.36603 5.80913 5.9884 6.19731L1.89537 10.4047Z"
            fill="#778CA2"
        />
    </svg>
)
