import * as React from 'react'

export const DescriptionIco = () => (
    <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.774 5a.774.774 0 0 0 0 1.548h14.452a.774.774 0 1 0 0-1.548H4.774Zm0 6.194a.774.774 0 0 0 0 1.548h14.452a.774.774 0 1 0 0-1.549H4.774ZM4 18.16c0-.427.347-.774.774-.774h14.452a.774.774 0 1 1 0 1.549H4.774A.774.774 0 0 1 4 18.16Zm.774-10.064a.774.774 0 0 0 0 1.548h9.807a.774.774 0 1 0 0-1.548H4.774ZM4 15.065c0-.428.347-.775.774-.775h9.807a.774.774 0 1 1 0 1.549H4.774A.774.774 0 0 1 4 15.065Z"
            fill="#778CA2"
        />
    </svg>
)
