import * as React from 'react'
import { ProfileImg } from 'src/components/profile-img/profile-img'
import { Dots } from 'src/components/svg-icons'
import { Box, Button, Image, Text } from 'src/components/utility'
import { commentParser, getImageUrl, getLocalStorage } from 'src/helpers'
import { buildComment } from '../functions'
import { formatDistance, subDays } from 'date-fns'
import { useNavigate } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import { MarkNotificationAsOpened } from 'src/services'
import EntityInfoList from './entity-info-list'
import { getLibraryFileUrl } from 'src/helpers/get-image-url'
import { ExtensionIcoLG } from 'src/components/file-display/file-display-2'
import { _3DFiles } from 'src/helpers/enums'
import { toast } from 'react-toastify'

export type INotificationCardProps = {
    notification: Notifications
}

type TRouteReturnType = {
    path: string
    key: keyof TNotifcationEntityInfo
}

function NotificationCardFwdRef(props: INotificationCardProps, ref: any) {
    const entity = props.notification
    const navigate = useNavigate()
    const [MarkNotificationAsOpenedMutation] = useMutation(
        MarkNotificationAsOpened
    )
    const { systemParsed } = buildComment(entity.display_string || '')
    const tokens = entity?.entity_info?.split('/') || []
    const distance =
        entity.created_at || entity.created_at
            ? formatDistance(
                  subDays(new Date(entity.created_at), 0),
                  new Date(),
                  { addSuffix: true }
              )
            : ''
    function getRoute(entityName: string): TRouteReturnType | undefined {
        if (entityName === 'todos') {
            return { path: '/todo', key: 'todo' }
        }
        if (entityName === 'product' || entityName === 'product_variants') {
            return { path: '/collections/product', key: 'product_variant' }
        }
        if (entityName === 'collections') {
            return { path: '/collections', key: 'collection' }
        }
        if (entityName === 'sub_todo_sections') {
            return { path: '/todo', key: 'todo' }
        }
        if (entityName === 'teams') {
            return { path: '/team', key: 'team' }
        }
        return
    }

    function createUrl(entity: Notifications, dimension: '2d' | '3d') {
        let URL = new URLSearchParams()
        const highlight = entity.body.redirection.highlight
        const file = highlight.file
        const BASE_PATH = getRoute(entity.entity_name)
        if (dimension === '2d') {
            URL.append('sectionId', entity.body.redirection.entity_id)
            URL.append('commentId', highlight.annotation_id)
            URL.append('fileId', file.file_id)
            URL.append('isLibraryFile', String(file.from_library))
            URL.append('mode', 'annotate')
            if (BASE_PATH) {
                const ID = entity.body.entity_info_details[BASE_PATH?.key]?.id
                return BASE_PATH.path + '/' + ID + '?' + URL.toString()
            }
            return null
        } else {
            URL.append('gid', file.gid)
            URL.append('fileId', file.file_id)
            URL.append('name', file.file_name)
            URL.append('entityName', highlight.entity_name)
            URL.append('entityId', highlight.entity_id)
            URL.append('commentId', highlight.annotation_id)
            URL.append(
                'productId',
                entity.body.entity_info_details.product_variant?.id || ''
            )

            URL.append('ref', file.from_library ? 'library' : 'notifications')
            URL.append('mode', 'annotation')
            return '/file-preview?' + URL.toString()
        }
    }

    async function downloadFile(url: string) {
        try {
            const token = getLocalStorage('authToken')
            const downloadUrl = `${url}?token=${token}`

            // Open the URL in a new tab
            window.open(downloadUrl, '_blank')
        } catch (e) {
            toast('Failed to open file URL', {
                className: 'custom-toaster toaster-error',
            })
        }
    }

    async function createNavigator() {
        const openComments =
            entity.type !== 'others' && entity.type !== 'status' ? true : false
        const dimension = entity.body?.redirection?.highlight?.file?.file_type
        const file = entity.body?.redirection?.highlight?.file
        const BASE_PATH = getRoute(entity.entity_name)
        switch (entity.type) {
            case 'annotation':
            case 'annotations':
                if (dimension) {
                    const route = createUrl(entity, dimension)
                    if (route) {
                        return navigate(route)
                    }
                }
                return
            case 'comments':
            case 'status':
            case 'others':
                if (entity?.body?.redirection?.url) {
                    await downloadFile(entity?.body?.redirection?.url)
                    return
                }
                if (!BASE_PATH?.key) return
                let URL =
                    BASE_PATH?.path +
                    '/' +
                    (entity?.body && entity?.body?.entity_info_details
                        ? entity.body?.entity_info_details[BASE_PATH?.key]?.id
                        : entity.entity_id)
                const SEARCH_PARAMS = new URLSearchParams()
                const isSubTodoSection =
                    entity.entity_name === 'sub_todo_sections'
                if (isSubTodoSection) {
                    SEARCH_PARAMS.append(
                        'sectionId',
                        entity.entity_id.toString()
                    )
                    SEARCH_PARAMS.append('showComments', '1')
                    if (file) {
                        SEARCH_PARAMS.append('fileId', file.file_id)
                        SEARCH_PARAMS.append(
                            'isLibraryFile',
                            String(file.from_library)
                        )
                    }
                }
                navigate(URL + '?' + SEARCH_PARAMS.toString(), {
                    state: isSubTodoSection
                        ? undefined
                        : {
                              isCommentNotification: openComments,
                          },
                })
        }
    }

    function markNotificationAsRead() {
        MarkNotificationAsOpenedMutation({
            variables: {
                id: entity.id,
            },
            onError: () => {},
        })
        createNavigator()
    }

    const coverPicNameSplit = entity?.body?.cover_pic?.name.split('.') || []
    const extension = coverPicNameSplit[coverPicNameSplit.length - 1]

    return (
        <Box
            display="flex"
            alignItems="center"
            py="12px"
            px="16px"
            bg="white"
            gridColumnGap="8px"
            border="solid"
            className="cursor-pointer"
            borderColor="gray250"
            justifyContent="space-between"
            borderWidth={1}
            onClick={markNotificationAsRead}
            ref={ref}
        >
            <Box
                display="flex"
                alignItems={'mention' in props ? 'center' : 'start'}
                gridColumnGap="8px"
            >
                {!entity.opened && (
                    <Box
                        bg="#4D7CFE"
                        borderRadius="50%"
                        mt="12px"
                        width="6px"
                        height="6px"
                    />
                )}
                <Box display="flex" alignItems="start" gridColumnGap="8px">
                    {entity.body.cover_pic && (
                        <Box display="flex" gridColumnGap="8px">
                            {_3DFiles.includes(extension) ? (
                                <ExtensionIcoLG type={extension} />
                            ) : (
                                <Image
                                    width="72px"
                                    height="72px"
                                    loading="lazy"
                                    borderRadius="4px"
                                    src={
                                        !entity.body.cover_pic.fromLibrary
                                            ? getImageUrl(
                                                  entity.body.cover_pic
                                                      ?.thumbnail ||
                                                      entity.body.cover_pic?.gid
                                              )
                                            : getLibraryFileUrl(
                                                  entity.body.cover_pic
                                                      ?.thumbnail ||
                                                      entity.body.cover_pic?.gid
                                              )
                                    }
                                />
                            )}
                            <Box height="72px" width="2px" bg="gray250"></Box>
                        </Box>
                    )}
                    <Box>
                        <Box
                            display="flex"
                            alignItems="center"
                            gridColumnGap="8px"
                            height="100%"
                            mb="8px"
                        >
                            {entity?.action_done_by?.name && (
                                <ProfileImg
                                    src={getImageUrl(
                                        entity?.action_done_by?.other_data
                                            ?.thumbnail
                                    )}
                                    size="28px"
                                    fontSize="16px"
                                    name={entity?.action_done_by?.name || ''}
                                />
                            )}
                            <Text my="0px">{systemParsed.parsed}</Text>
                        </Box>

                        {/* {typeof parentComment === 'object' && (
                            <Text mt="8px" fontSize="14px" my="0px">
                                "{parentComment.parsed}"
                            </Text>
                        )} */}

                        {entity.body.comment && (
                            <Text fontSize="14px" my="0px">
                                "{commentParser(entity.body.comment).parsed}"
                            </Text>
                        )}

                        <Box display="flex">
                            <Box display="flex" gridColumnGap="16px">
                                <EntityInfoList
                                    entityInfo={entity.body.entity_info_details}
                                />
                            </Box>
                            <Text
                                ml="12px"
                                fontSize="12px"
                                color="textSecondaryDarkBlue"
                            >
                                {distance}
                            </Text>
                        </Box>
                    </Box>
                    <Box
                        alignItems="center"
                        display={'mention' in props ? 'flex' : 'block'}
                        gridColumnGap="12px"
                    >
                        {/* {entityInfoParser()} */}
                        {/* {entity.type === 'comments' && (
                            <Text mt="4px" fontSize="14px" my="0px">
                                "{ogCommentParsed.parsed}"
                            </Text>
                        )} */}
                    </Box>
                </Box>
            </Box>
            {false && (
                <Button bg="transparent" border="none" p="0px">
                    <Dots active={false} />
                </Button>
            )}
        </Box>
    )
}

export default React.forwardRef(NotificationCardFwdRef)
