import { useApolloClient, useQuery } from '@apollo/client'
import React, { useState } from 'react'
import { toast } from 'react-toastify'
import SubtodoCard from 'src/components-v2/todo/subtodo-card/subtodo-card'
import type { Subtodo } from 'src/components-v2/todo/todo.types'
import { Loader } from 'src/components/loader/loader'
import { Modal } from 'src/components/modals/modal'
import VariantIco from 'src/components/svg-icons/variant-ico'
import { Box, Image, Input, Text } from 'src/components/utility'
import {
    GetBaseProductVariantTechPackByProductId,
    GetProductVariantsByProductId,
} from 'src/services/graphql/entities/product/product.queries'
import useCreateVariant from '../useCreateVariant.hook'
import { GetCollection } from 'src/services/graphql/entities/collection/get-collection'

function CreateVariantModal({
    isOpen,
    onClose,
    baseProductId,
    productVariantId,
    navigateToTechPack,
}: {
    onClose: () => void
    isOpen: boolean
    baseProductId: number
    productVariantId: number
    navigateToTechPack?: boolean
}) {
    const [name, setName] = useState('')
    const [thumbnailFile, setThumbnailFile] = useState<File | null>(null)
    const [subtodos, setSubtodos] = useState<Array<Subtodo>>([])
    const client = useApolloClient()
    const { createVariantHandler, isCreating } = useCreateVariant()
    const inputRef = React.useRef<HTMLInputElement | null>(null)
    const { data, loading } = useQuery(
        GetBaseProductVariantTechPackByProductId,
        {
            variables: {
                product_id: baseProductId,
            },
            onCompleted: (data) => {
                setSubtodos(
                    data?.product_variants[0].todos[0].sub_todos
                        ? [...data.product_variants[0].todos[0].sub_todos]
                        : []
                )
            },
        }
    )

    const techPackTodo = data?.product_variants[0].todos[0]
    const subtodoSequences =
        subtodos.map((subtodo) => ({
            sequence: subtodo.sub_todo_sequences?.[0]?.sequence,
            id: subtodo.id,
        })) || []

    const sequenceMapping = subtodoSequences.reduce(
        (acc: any, { id, sequence }: { id: number; sequence: number }) => {
            acc[id] = sequence
            return acc
        },
        {}
    )

    const thumbnailImage = thumbnailFile
        ? URL.createObjectURL(thumbnailFile)
        : undefined

    return (
        <Modal
            onClose={onClose}
            isModal={isOpen}
            onClick={() =>
                createVariantHandler({
                    productVariantId: productVariantId,
                    name: name,
                    thumbnailFile: thumbnailFile,
                    onCompleted: async () => {
                        client.refetchQueries({
                            include: [
                                GetProductVariantsByProductId,
                                GetCollection,
                            ],
                        })
                        toast('Variant created successfully', {
                            className: 'custom-toaster toaster-success',
                        })
                        onClose()
                    },
                    navigateToTechPack,
                })
            }
            primaryButtonTitle="Create"
            secondaryButtonTitle="Cancel"
            isLoading={isCreating}
            title="Add Variant"
            bg="white"
        >
            <Box
                width="85vw"
                height="75vh"
                overflow="auto"
                display="grid"
                gridTemplateRows="auto auto 1fr"
            >
                <Box display="flex" gridGap="16px" px="16px" py="4px">
                    <Box
                        p="8px"
                        bg="#F2F4F6"
                        borderRadius="4px"
                        onClick={() => {
                            inputRef.current?.click()
                        }}
                        className="cursor-pointer"
                    >
                        <Image
                            src={thumbnailImage || '/image-placeholder.jpg'}
                            alt="image"
                            width="40px"
                            height="40px"
                            borderRadius="4px"
                            id="thumbnail-image"
                        />
                    </Box>
                    <Input
                        autoComplete="false"
                        accept="image/*"
                        type="file"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            e.stopPropagation()
                            e.target.files &&
                                setThumbnailFile(e?.target?.files[0])
                        }}
                        onClick={(e: any) => {
                            e.target.value = null
                        }}
                        display="none"
                        ref={inputRef}
                        bg="none"
                    />
                    <Box display="flex" gridGap="8px" py="8px" width="100%">
                        <VariantIco size="24" color="#778CA2" />
                        <Input
                            bg="transparent"
                            placeholder="Enter variant name"
                            border="none"
                            width="100%"
                            value={name}
                            height="max-content"
                            fontSize="16px"
                            onChange={(e) => setName(e.target.value)}
                        />
                    </Box>
                </Box>
                <Text
                    color="primary"
                    fontSize="16px"
                    px="16px"
                    my="8px"
                    mb="16px"
                >
                    Base tech pack and variant details:
                </Text>
                {loading ? (
                    <Loader />
                ) : !!data ? (
                    <Box
                        style={{ borderRadius: '12px 12px 0px 0px' }}
                        bg="#F8FAFB"
                        py="12px"
                        px="40px"
                        borderTop="1px solid #A7B9D1"
                        display="grid"
                        gridTemplateColumns={'1fr 1fr'}
                        gridGap="16px"
                        height="100%"
                        overflow="auto"
                    >
                        {techPackTodo &&
                            subtodos
                                ?.sort((a, b) => {
                                    return (
                                        sequenceMapping[a.id] -
                                        sequenceMapping[b.id]
                                    )
                                })
                                .map((subtodo: any, index: any) => (
                                    <SubtodoCard
                                        subtodo={subtodo}
                                        refetchTodo={() => {}}
                                        isTechPack={
                                            techPackTodo.types === 'techPack'
                                        }
                                        onFocus={() => {}}
                                        todoId={techPackTodo.id}
                                        productId={techPackTodo.product?.id}
                                        key={subtodo.id}
                                        isReadOnly={true}
                                    />
                                ))}
                    </Box>
                ) : null}
            </Box>
        </Modal>
    )
}

export default CreateVariantModal
