import { Box, Button, Input } from 'src/components/utility'
import Link from './link'
import AddLinkModal from './add-link-modal'
import { useState } from 'react'
import LinkIco from 'src/components/svg-icons/link-ico'
import { DeleteIco } from 'src/components/svg-icons'
import { toast } from 'react-toastify'
import axios from 'axios'
import { ADD_LINK, DELETE_LINK } from 'src/services/api/endpoints'
import { getLocalStorage, uuid } from 'src/helpers'
import type { Link as LinkType } from 'src/components/3d-renderer/annotation.types'
import useUpdateTemplate from 'src/hooks/useUpdateTemplate.hook'
import { Loader } from 'src/components/loader/loader'

function AddLinks({
    links,
    isEditPermitted = false,
    deleteSection,
    sectionId,
    refetchTodo,
    id,
    isTemplate,
}: {
    links: Array<{ id: string; text: string; url: string }>
    isEditPermitted?: boolean
    deleteSection: () => void
    sectionId: number
    refetchTodo: () => void
    id: string
    isTemplate?: boolean
}) {
    const [showAddLinkModal, setShowAddLinkModal] = useState(false)
    const [isHovered, setIsHovered] = useState(false)
    const [inputLink, setInputLink] = useState('')
    const { updateTemplateSection } = useUpdateTemplate()
    const [isDeleting, setIsDeleting] = useState(false)

    // function that takes in string, checks if it has https:// or http:// and returns the string with https:// if it doesn't have it
    function checkLink(link: string) {
        if (link.includes('https://') || link.includes('http://')) {
            return link
        } else {
            return `https://${link}`
        }
    }

    function isLinkValid(link: string) {
        const pattern = new RegExp(
            '^(https?:\\/\\/)' + // protocol
                '([^\\s\\.]+\\.)+' + // domain name and extension
                '.+', // anything after the extension
            'i'
        )
        return !!pattern.test(link)
    }

    async function addLink(link: LinkType) {
        try {
            if (!isLinkValid(checkLink(link.url))) {
                toast('Please add a valid link', {
                    className: 'custom-toaster toaster-error',
                })
                return
            } else if (links.find((l) => l.url === checkLink(link.url))) {
                toast('Link already exists', {
                    className: 'custom-toaster toaster-error',
                })
                return
            } else {
                if (isTemplate) {
                    updateTemplateSection({
                        sectionId,
                        payload: {
                            links: [
                                ...links,
                                { text: link.text, url: checkLink(link.url) },
                            ],
                        },
                    })
                } else {
                    const response = await axios.post(
                        ADD_LINK,
                        {
                            entity_name: 'sub_todo_sections',
                            entity_id: sectionId,
                            links: [
                                {
                                    url: checkLink(link.url),
                                    text: link.text,
                                },
                            ],
                        },
                        {
                            headers: {
                                'x-auth-token': getLocalStorage('authToken'),
                            },
                        }
                    )
                    if (response.status === 200) {
                        refetchTodo()
                    }
                }
            }
        } catch {
            toast('Failed to add link', {
                className: 'custom-toaster toaster-error',
            })
        }
    }

    async function removeLinks(
        linksToDelete: Array<{ text: string; url: string }>
    ) {
        try {
            setIsDeleting(true)
            if (isTemplate) {
                updateTemplateSection({
                    sectionId,
                    payload: {
                        links: links.filter(
                            (link) =>
                                !linksToDelete.find(
                                    (linkToDelete) =>
                                        linkToDelete.url === link.url
                                )
                        ),
                    },
                })
            } else {
                const response = await axios.post(
                    DELETE_LINK,
                    {
                        entity_name: 'sub_todo_sections',
                        entity_id: sectionId,
                        links: linksToDelete,
                    },
                    {
                        headers: {
                            'x-auth-token': getLocalStorage('authToken'),
                        },
                    }
                )
                if (response.status === 200) {
                    refetchTodo()
                }
            }
        } catch {
            toast('Failed to remove links', {
                className: 'custom-toaster toaster-error',
            })
        } finally {
            setIsDeleting(false)
        }
    }

    return (
        <Box
            display="grid"
            gridTemplateColumns="auto 1fr 28px"
            gridGap="8px"
            bg="white"
            p="4px"
            borderRadius="4px"
            height="max-content"
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            border={isHovered ? '1px solid #B8B8B8' : '1px solid #fff'}
            id={id}
            width={'100%'}
        >
            <LinkIco />
            <Box
                display="flex"
                flexDirection="column"
                gridGap="10px"
                flexWrap="wrap"
                id="subtodo-link"
                width={'100%'}
                overflow={'hidden'}
            >
                {links?.length > 0 &&
                    links.map((link) => (
                        <Link
                            link={link}
                            removeLink={(link) => removeLinks([link])}
                            showRemoveButton={isEditPermitted}
                        />
                    ))}
                {isEditPermitted && (
                    <Input
                        bg="transparent"
                        placeholder="Enter Link"
                        value={inputLink}
                        fontSize={'12px'}
                        onChange={(e) => setInputLink(e.target.value)}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                if (inputLink.trim().length > 0) {
                                    addLink({
                                        id: uuid(),
                                        text: '',
                                        url: inputLink,
                                    })
                                    setInputLink('')
                                } else {
                                    toast('Please enter a valid link', {
                                        className:
                                            'custom-toaster toaster-error',
                                    })
                                }
                            }
                        }}
                        width="100%"
                        border="none"
                    />
                )}

                {showAddLinkModal && (
                    <AddLinkModal
                        addLink={addLink}
                        onClose={() => setShowAddLinkModal(false)}
                    />
                )}
            </Box>
            {isHovered &&
                isEditPermitted &&
                (isDeleting ? (
                    <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                    >
                        <Loader outline="#FF2C18" size="18px" />
                    </Box>
                ) : (
                    <Button
                        onClick={async () => {
                            await removeLinks(links)
                            deleteSection()
                        }}
                        border="none"
                        fontStyle="Rubik"
                        fontSize="12px"
                        color="#027AC3"
                        position="relative"
                        width="max-content"
                        bg="#F0F2F3"
                    >
                        <DeleteIco />
                    </Button>
                ))}
        </Box>
    )
}

export default AddLinks
