import { gql } from '@apollo/client'

export const GetDashboardAllProducts = gql`
    query getDashboardProduct(
        $userId: bigint_comparison_exp
        $status: status_enum_comparison_exp = {}
        $order_by: [products_order_by!] = { created_at: desc }
        $limit: Int
        $offset: Int
        $collectionId: bigint_comparison_exp = {}
    ) {
        products(
            where: {
                team: { team_members: { user_id: $userId } }
                status: $status
                collection: { id: $collectionId }
            }
            order_by: $order_by
            limit: $limit
            offset: $offset
        ) {
            id
            name
            product_variants {
                id
                is_primary
                name
                is_delayed
                due_date
                status
                other_data
                collection {
                    id
                    name
                    manager_id
                }
                team {
                    id
                    name
                    team_members {
                        user {
                            other_data
                            name
                            id
                        }
                    }
                }
                totalTodos: todos_aggregate {
                    aggregate {
                        count
                    }
                }
                completedTodos: todos_aggregate(
                    where: { status: { _eq: complete } }
                ) {
                    aggregate {
                        count
                    }
                }
            }
        }
    }
`
export const GetDashboardMyProduct = gql`
    query getDashboardProduct(
        $status: status_enum_comparison_exp = {}
        $order_by: [products_order_by!] = { created_at: desc }
        $limit: Int
        $offset: Int
        $collectionId: bigint_comparison_exp = {}
        $approverId: bigint_comparison_exp
    ) {
        products(
            where: {
                status: $status
                collection: { id: $collectionId }
                approver_id: $approverId
            }
            order_by: $order_by
            limit: $limit
            offset: $offset
        ) {
            id
            name
            product_variants {
                id
                is_primary
                name
                is_delayed
                due_date
                status
                other_data
                collection {
                    id
                    name
                    manager_id
                }
                team {
                    id
                    name
                    team_members {
                        user {
                            other_data
                            name
                            id
                        }
                    }
                }
                totalTodos: todos_aggregate {
                    aggregate {
                        count
                    }
                }
                completedTodos: todos_aggregate(
                    where: { status: { _eq: complete } }
                ) {
                    aggregate {
                        count
                    }
                }
            }
        }
    }
`

export const DashboardYourProductAggregate = gql`
    query getProductAggregate(
        $id: bigint_comparison_exp = {}
        $approverId: bigint_comparison_exp = {}
    ) {
        new: products_aggregate(
            where: {
                status: { _eq: new }
                collection: { id: $id }
                approver_id: $approverId
            }
        ) {
            aggregate {
                count
            }
        }
        inProgress: products_aggregate(
            where: {
                status: { _eq: inProgress }
                collection: { id: $id }
                approver_id: $approverId
            }
        ) {
            aggregate {
                count
            }
        }
        completed: products_aggregate(
            where: {
                status: { _eq: complete }
                collection: { id: $id }
                approver_id: $approverId
            }
        ) {
            aggregate {
                count
            }
        }
        inReview: products_aggregate(
            where: {
                status: { _eq: inReview }
                collection: { id: $id }
                approver_id: $approverId
            }
        ) {
            aggregate {
                count
            }
        }
        revisionRequested: products_aggregate(
            where: {
                status: { _eq: requestedForResubmission }
                collection: { id: $id }
                approver_id: $approverId
            }
        ) {
            aggregate {
                count
            }
        }
    }
`
export const DashboardAllProductAggregate = gql`
    query getProductAggregate(
        $userId: bigint_comparison_exp = {}
        $id: bigint_comparison_exp = {}
    ) {
        new: products_aggregate(
            where: {
                status: { _eq: new }
                team: { team_members: { user_id: $userId } }
                collection: { id: $id }
            }
        ) {
            aggregate {
                count
            }
        }
        inProgress: products_aggregate(
            where: {
                status: { _eq: inProgress }
                team: { team_members: { user_id: $userId } }
                collection: { id: $id }
            }
        ) {
            aggregate {
                count
            }
        }
        completed: products_aggregate(
            where: {
                status: { _eq: complete }
                team: { team_members: { user_id: $userId } }
                collection: { id: $id }
            }
        ) {
            aggregate {
                count
            }
        }
        inReview: products_aggregate(
            where: {
                status: { _eq: inReview }
                team: { team_members: { user_id: $userId } }
                collection: { id: $id }
            }
        ) {
            aggregate {
                count
            }
        }
        revisionRequested: products_aggregate(
            where: {
                status: { _eq: requestedForResubmission }
                team: { team_members: { user_id: $userId } }
                collection: { id: $id }
            }
        ) {
            aggregate {
                count
            }
        }
    }
`
