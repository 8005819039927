import { Canvas } from '@react-three/fiber'
import * as React from 'react'
import ConfiguratorScene from './configurator-scene'
import { MapConfigurator } from './maps.renderer'
import { MaterialConfigurator } from './material.renderer'
import Enviornments from './enviornment'
import { Box, Text } from 'src/components/utility'
import { useConfiguratorContext } from './configurator.context'
import AnnotationComments from 'src/components/annotation-comments/annotation-comments'
import { GetProductTeam } from 'src/services/graphql/query/get-product-team'
import { useQuery } from '@apollo/client'
import { useSearchParams } from 'react-router-dom'
import {
    GetAnnotatedComments,
    GetAnnotatedCommentsForLibraryFile,
    GetProductIdFromSectionId,
} from 'src/services/graphql/query/get-annotated-comments'
import { getImageUrl } from 'src/helpers'
import { getLibraryFileUrl } from 'src/helpers/get-image-url'
import { ReviewerOptions } from '../reviewer-options'
import { useAnnotationCommentsStore } from 'src/store/hooks/useAnnotationCommentsStore'
import ComparePanel from '../compare-panel/compare-panel'
import { IconButton } from 'src/components/buttons/icon-button'
import CompareIco from 'src/components/svg-icons/compare-ico'
import Panel from './panel'
import ImageConfigurator from './panel-functions/artwork/image-configurator'
import UnableToPreviewSection from 'src/components-v2/file-preview-modal/preview-section/unable-to-preview-section'
import { SuspenseLoading } from 'src/components/suspense-loading/suspense-loading'
import ImagePreviewRenderer from './image-preview-renderer'

export interface IConfiguratorProps {
    file?: {
        url: string
        name: string
    }
    configuraturRef: 'upload' | 'fetch'
}

export default function ConfiguratorRenderer(props: IConfiguratorProps) {
    const { file } = props
    const { operation, setOperation, highlights } = useConfiguratorContext()
    const [image, setImage] = React.useState<string>('')
    const [isFilePreviewModalOpen, setIsFilePreviewModalOpen] =
        React.useState(false)
    const [searchParams] = useSearchParams()
    const [fileExists, setFileExists] = React.useState(true)
    const [loading, setLoading] = React.useState(false)
    const [progress, setProgress] = React.useState(0)
    const { isComparePanelVisible, setIsComparePanelVisible } =
        useAnnotationCommentsStore()
    const [fileUrl, setFileUrl] = React.useState('')
    const { fileId, entityName, entityId, ref, gid } = Object.fromEntries(
        searchParams.entries()
    )
    const captureImageRef = React.useRef<() => string | null>(null)

    const { data: annotatedCommentsData, refetch: refetchAnnotatedComments } =
        useQuery(GetAnnotatedComments, {
            skip: ref === 'library',
            variables: {
                entity_name: entityName,
                entity_id: Number(entityId),
                file_id: Number(fileId),
            },
            fetchPolicy: 'cache-and-network',
        })

    const {
        data: annotatedCommentsDataForLibraryFile,
        refetch: refetchAnnotatedCommentsForLibraryFile,
    } = useQuery(GetAnnotatedCommentsForLibraryFile, {
        skip: ref !== 'library',
        variables: {
            entity_name: entityName,
            entity_id: Number(entityId),
            library_file_id: Number(fileId),
        },
        fetchPolicy: 'cache-and-network',
    })

    const productId = searchParams.get('productId')

    const { data: productData } = useQuery<{
        sub_todo_sections: {
            todo: {
                product_variant: {
                    id: string
                    product: {
                        id: string
                    }
                }
            }
        }[]
    }>(GetProductIdFromSectionId, {
        skip:
            !searchParams.get('sub_todo_sections') &&
            !searchParams.get('entityId'),
        variables: {
            entity_id: searchParams.get('entityId'),
        },
    })

    const { data: teamData } = useQuery(GetProductTeam, {
        skip:
            (!productId || productId === '0') &&
            !productData?.sub_todo_sections[0]?.todo?.product_variant.product
                ?.id,
        variables: {
            product_id:
                productId ||
                productData?.sub_todo_sections[0]?.todo?.product_variant.product
                    ?.id,
        },
    })

    const comments =
        annotatedCommentsData?.annotations ||
        annotatedCommentsDataForLibraryFile?.annotations ||
        []

    React.useEffect(() => {
        ;(async function () {
            if (file?.url) {
                setLoading(true)
                try {
                    const data = await fetch(file?.url, {
                        method: 'GET',
                        redirect: 'manual',
                    })
                    if (data.status !== 404) {
                        setFileExists(true)
                        setFileUrl(file?.url)
                    } else {
                        setFileExists(false)
                    }
                    setLoading(false)
                } catch (e) {
                    setFileExists(false)
                    setLoading(false)
                }
            }
            if (gid) {
                try {
                    setLoading(true)
                    if (ref !== 'library') {
                        const data = await fetch(getImageUrl(gid), {
                            method: 'GET',
                            redirect: 'manual',
                        })
                        console.log({ data })
                        if (data.status !== 404) {
                            setFileExists(true)
                            setFileUrl(getImageUrl(gid))
                        } else {
                            setFileExists(false)
                        }
                        setLoading(false)
                    } else {
                        const data = await fetch(getLibraryFileUrl(gid), {
                            method: 'GET',
                            redirect: 'manual',
                        })
                        console.log({ data })
                        if (data.status !== 404) {
                            setFileExists(true)
                            setFileUrl(getLibraryFileUrl(gid))
                        } else {
                            setFileExists(false)
                        }
                        setLoading(false)
                    }
                } catch (e) {
                    setFileExists(false)
                    setLoading(false)
                }
            }
        })()
    }, [])

    const fileName = searchParams.get('name') || file?.name || ''
    if (!fileExists)
        return (
            <UnableToPreviewSection
                onOpenConfigurator={() => {}}
                url=""
                text="Sorry we are not able to preview this file at the moment."
                extension={searchParams.get('name')?.split('.')[1] || ''}
                showControls={false}
            />
        )

    const nameToken = fileName.split('.')
    const extension = nameToken[nameToken.length - 1] as unknown as
        | 'glb'
        | 'gltf'
        | 'obj'
        | 'mtl'
        | 'fbx'

    const activeMeshesLength = highlights.filter(
        (highlight) => highlight.isActive
    ).length
    console.log({ image })
    if ((!file?.url || (file?.url !== '' && file?.url.length < 0)) && !gid)
        return <></>
    return (
        <>
            {fileExists && !loading && (
                <Box position="relative" height="100%" width="100%">
                    <React.Suspense
                        fallback={
                            <Box
                                width="100%"
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                height="100%"
                            >
                                {<SuspenseLoading progress={progress} />}
                            </Box>
                        }
                    >
                        <Box display="flex" width="100%" height="100%">
                            {!loading && (
                                <Canvas
                                    style={{
                                        height: '100%',
                                        backgroundColor: '#F8FAFB',
                                        width: isComparePanelVisible
                                            ? '50%'
                                            : '100%',
                                    }}
                                >
                                    {(file?.url || gid) && (
                                        <ConfiguratorScene
                                            ref={captureImageRef}
                                            setFileExists={(exists) =>
                                                setFileExists(exists)
                                            }
                                            extension={extension}
                                            setProgress={(progress) =>
                                                setProgress(progress)
                                            }
                                            fileUrl={fileUrl}
                                            fileName={fileName}
                                            setLoading={(isLoading) =>
                                                setLoading(isLoading)
                                            }
                                            loading={loading}
                                            refetch={() => {
                                                refetchAnnotatedCommentsForLibraryFile()
                                                refetchAnnotatedComments()
                                            }}
                                            comments={comments}
                                        />
                                    )}
                                </Canvas>
                            )}
                            {isComparePanelVisible && (
                                <ComparePanel
                                    onClose={() =>
                                        setIsComparePanelVisible(false)
                                    }
                                    productId={
                                        productData?.sub_todo_sections[0]?.todo
                                            ?.product_variant?.id || null
                                    }
                                />
                            )}
                        </Box>
                        <Box
                            position="absolute"
                            borderRadius="4px"
                            right="0px"
                            top="0px"
                            display="flex"
                            bg="white"
                        >
                            {false && (
                                <>
                                    <MapConfigurator />
                                    <MaterialConfigurator />
                                </>
                            )}
                            {false && <ImageConfigurator />}
                            {fileExists &&
                                activeMeshesLength > 0 &&
                                (extension === 'glb' || extension === 'gltf') &&
                                !isComparePanelVisible &&
                                operation === 'pan' && <Panel />}
                        </Box>

                        {false && (
                            <Box
                                position="absolute"
                                right="80px"
                                top="12px"
                                bg="white"
                            >
                                <Enviornments />
                            </Box>
                        )}

                        {fileExists && !loading && teamData && (
                            <AnnotationComments
                                comments={comments}
                                team={teamData?.products_by_pk?.team || {}}
                                refetch={() => {
                                    refetchAnnotatedCommentsForLibraryFile()
                                    refetchAnnotatedComments()
                                }}
                            />
                        )}
                        {fileExists && !loading && (
                            <ReviewerOptions
                                onChange={(operation) =>
                                    setOperation(operation)
                                }
                                onImageCapture={() => {
                                    if (!captureImageRef.current) return
                                    setImage(captureImageRef.current() || '')
                                    setIsFilePreviewModalOpen(true)
                                }}
                            />
                        )}
                        {fileExists && !loading && (
                            <Box position="absolute" bottom="92px" left="0px">
                                <IconButton
                                    disabled={
                                        props.configuraturRef === 'upload'
                                    }
                                    iconLeft={<CompareIco />}
                                    style={{ backgroundColor: 'white' }}
                                    variant="tertiaryOutline"
                                    onClick={() =>
                                        setIsComparePanelVisible(
                                            !isComparePanelVisible
                                        )
                                    }
                                >
                                    <Text
                                        my="0px"
                                        fontSize="12px"
                                        fontWeight={400}
                                    >
                                        Compare
                                    </Text>
                                </IconButton>
                            </Box>
                        )}
                    </React.Suspense>
                </Box>
            )}
            {/* {true && (
                <Box
                    width="100%"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    height="100%"
                >
                    {<SuspenseLoading progress={progress} />}
                </Box>
            )} */}
            <ImagePreviewRenderer
                imageSource={image}
                isModalOpen={isFilePreviewModalOpen}
                setIsModalOpen={(isModalOpen) =>
                    setIsFilePreviewModalOpen(isModalOpen)
                }
            />
        </>
    )
}
