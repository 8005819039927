import type { SetStateAction } from 'react'
import React, { useEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import { CloseIco } from '../svg-icons'
import { Box, Button, Text } from '../utility'

function ToastComponent({
    action = null,
    close = false,
    variant,
    text,
    onActionClick,
    isToastVisible,
    setIsToastVisible,
}: {
    action?: null | JSX.Element
    close?: boolean
    variant: 'Success' | 'Information' | 'Error' | 'Warning'
    text: string
    onActionClick?: () => void | null
    isToastVisible: boolean | null
    setIsToastVisible: React.Dispatch<SetStateAction<boolean | null>>
}) {
    const [variantColor, setVariantColor] = useState('approved')
    const [toastClass, setToastClass] = useState<string | null>(null)
    const [display, setDisplay] = useState('none')
    useEffect(() => {
        switch (variant) {
            case 'Success':
                setVariantColor('approved')
                break
            case 'Information':
                setVariantColor('links')
                break
            case 'Warning':
                setVariantColor('warning')
                break
            case 'Error':
                setVariantColor('delay')
                break
            default:
                setVariantColor('approved')
                break
        }
    }, [variant])

    useEffect(() => {
        if (isToastVisible !== null) {
            if (!isToastVisible) {
                setTimeout(() => {
                    setToastClass('hide')
                    setTimeout(() => {
                        setDisplay('none')
                        setIsToastVisible(false)
                    }, 500)
                }, 3000)
            } else {
                setTimeout(() => {
                    setToastClass('show')
                    if (!close) {
                        setTimeout(() => {
                            setDisplay('unset')
                            setToastClass('hide')
                            setTimeout(() => {
                                setDisplay('none')
                                setIsToastVisible(false)
                            }, 500)
                        }, 3000)
                    }
                }, 0)
            }
        }
    }, [isToastVisible])

    function handleClose() {
        setDisplay('flex')
        setToastClass('hide')
        setTimeout(() => {
            setDisplay('none')
            setIsToastVisible(false)
        }, 500)
    }

    return (
        <Box
            className={`toast ${toastClass}`}
            boxShadow="0px 4px 24px rgba(0, 0, 0, 0.1)"
            zIndex={5}
            justifyContent="space-between"
            pr="12px"
            bg="white"
            minWidth="282px"
            height="max-content"
            borderRadius="8px"
            overflow="hidden"
            display={display}
        >
            <Box display="flex" width="100%">
                <Box
                    width="8px"
                    height="auto"
                    bg={variantColor}
                    mr="12px"
                ></Box>
                <Text fontFamily="Rubik" color="primary" width="70%">
                    {text}
                </Text>
            </Box>
            <Box display="flex" justifyContent="center" alignItems="center">
                {close ? (
                    <Button
                        onClick={() => {
                            handleClose()
                        }}
                        bg="transparent"
                        border="none"
                    >
                        <CloseIco color="#778CA2" />
                    </Button>
                ) : (
                    action
                )}
            </Box>
        </Box>
    )
}

export const Toast = ({
    action = null,
    close = false,
    variant,
    text,
    onActionClick,
    isToastVisible,
    setIsToastVisible,
}: {
    action?: null | JSX.Element
    close?: boolean
    variant: 'Success' | 'Information' | 'Error' | 'Warning'
    text: string
    onActionClick?: () => void | null
    isToastVisible: boolean | null
    setIsToastVisible: React.Dispatch<SetStateAction<boolean | null>>
}) => {
    const toast = document.getElementById('toast')
    if (toast) {
        return createPortal(
            <ToastComponent
                action={action}
                close={close}
                variant={variant}
                text={text}
                onActionClick={onActionClick}
                setIsToastVisible={setIsToastVisible}
                isToastVisible={isToastVisible}
            />,
            toast
        )
    }
    return <h1>Couldn&apos;t find root</h1>
}
