import * as React from 'react'
import { Alpha, Hue, Saturation, useColor } from 'react-color-palette'
import 'react-color-palette/css'
import { UpDownArrow } from 'src/assets/svgs-components/arrows'
import { Box, Text } from 'src/components/utility'
import { getSentenceCase } from 'src/helpers'
import { useConfiguratorContext } from '../configurator.context'
import type { Shade } from 'shade-generator'
import ShadeGenerator from 'shade-generator'
import { SuccessTickSm } from 'src/components/svg-icons'
import { checkColorFormat } from 'src/components/color-picker/color-picker.helper'
import ColorPanel from './color-panel'

export interface IColorProps {}

export default function Color(props: IColorProps) {
    const [color, setColor] = useColor('')
    const [colorType, setColorType] = React.useState<'hex' | 'rgb'>('hex')

    const [shades, setShades] = React.useState(
        ShadeGenerator.hue('#000000').shadesMap('hex')
    )
    const { highlights, selectedColor, setSelectedColor } =
        useConfiguratorContext()

    function handleOnColorChange(color: string) {
        if (color) {
            highlights.forEach((highlight) => {
                if (highlight.mesh.material.color && highlight.isActive)
                    return highlight.mesh.material.color.setHex(
                        color.replace('#', '0x')
                    )
            })
        }
    }
    React.useEffect(() => {
        if (highlights[0]) {
            const hex = `#${highlights[0].mesh.material.color.getHexString()}`
            const isColor = checkColorFormat(hex)
            if (isColor) {
                setSelectedColor(hex)
                setShades(
                    ShadeGenerator.hue(
                        hex.replace('#', '0').length > 6
                            ? hex.substring(0, 7)
                            : hex
                    ).shadesMap('hex')
                )
            }
        }
    }, [highlights])

    const shadeArr = Object.keys(shades).slice(0, 10) as Shade[]
    const anchorEl = document.getElementById('shades')

    return (
        <Box
            height={window.innerHeight < 750 ? '200px' : 'auto'}
            overflowY={window.innerHeight < 750 ? 'auto' : 'initial'}
            display="flex"
            flexDirection="column"
            px="12px"
            gridRowGap="8px"
        >
            <Text my="0px" fontSize="12px">
                Similar Shades
            </Text>
            <Box
                display="grid"
                gridTemplateColumns="1fr 1fr 1fr 1fr 1fr"
                flexWrap="wrap"
                gridColumnGap="8px"
                gridRowGap="8px"
                justifyContent="center"
                id="shades"
            >
                {shadeArr.map((shade: Shade) => {
                    return (
                        <Box
                            borderRadius="4px"
                            border="solid"
                            borderWidth={1}
                            borderColor={
                                selectedColor?.toLowerCase() ===
                                shades[shade].toLowerCase()
                                    ? 'primary'
                                    : 'white'
                            }
                        >
                            <Box
                                bg={shades[shade]}
                                height="48px"
                                borderRadius="4px"
                                border="solid"
                                borderWidth={1}
                                borderColor="white"
                                onClick={() => {
                                    handleOnColorChange(shades[shade])
                                    setSelectedColor(shades[shade])
                                }}
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                            >
                                {selectedColor?.toLowerCase() ===
                                    shades[shade].toLowerCase() && (
                                    <SuccessTickSm color="#C2CFE0" />
                                )}
                            </Box>
                        </Box>
                    )
                })}
            </Box>

            {false && (
                <>
                    <Saturation
                        height={200}
                        color={color}
                        onChange={(color) => {
                            setColor(color)

                            const colorLength = color.hex.replace(
                                '#',
                                '0'
                            ).length

                            if (colorLength === 6 || colorLength === 3) {
                                handleOnColorChange(color.hex)
                                setShades(
                                    ShadeGenerator.hue(
                                        colorLength > 6
                                            ? color.hex.substring(0, 7)
                                            : color.hex
                                    ).shadesMap('hex')
                                )
                            }
                        }}
                    />
                    <Hue
                        color={color}
                        onChange={(color) => {
                            setColor(color)
                            handleOnColorChange(color.hex)
                            setShades(
                                ShadeGenerator.hue(
                                    color.hex.replace('#', '0').length > 6
                                        ? color.hex.substring(0, 7)
                                        : color.hex
                                ).shadesMap('hex')
                            )
                        }}
                    />
                    <Alpha
                        color={color}
                        onChange={(color) => {
                            setColor(color)
                            handleOnColorChange(color.hex)
                            setShades(
                                ShadeGenerator.hue(
                                    color.hex.replace('#', '0').length > 6
                                        ? color.hex.substring(0, 7)
                                        : color.hex
                                ).shadesMap('hex')
                            )
                        }}
                    />
                </>
            )}
            <ColorPanel
                key={selectedColor}
                initialColor={selectedColor}
                onColorChange={(hex) => {
                    const hexLength = hex.replace('#', '0').length
                    const isColor = checkColorFormat(hex)
                    let color = hex
                    console.log({ hexLength, isColor })
                    if (hexLength === 3)
                        color = `#${hex.replace('#', '').repeat(2)}`
                    console.log({ color })
                    if (
                        isColor &&
                        (hexLength === 3 || hexLength === 6 || hexLength === 7)
                    ) {
                        handleOnColorChange(hex)
                        setShades(
                            ShadeGenerator.hue(
                                hexLength > 6 ? color.substring(0, 7) : color
                            ).shadesMap('hex')
                        )
                    }
                }}
            />
            {false && (
                <Box display="flex" alignItems="center" gridColumnGap="8px">
                    <Box
                        border="solid"
                        borderWidth={1}
                        borderColor="gray250"
                        borderRadius="4px"
                        width="max-content"
                        px="6px"
                        py="4px"
                        display="flex"
                        alignItems="center"
                        gridColumnGap="4px"
                    >
                        {getSentenceCase(colorType, 'NAME')}
                        <Box
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                            justifyContent="center"
                            mt="2px"
                            onClick={() =>
                                setColorType(
                                    colorType === 'hex' ? 'rgb' : 'hex'
                                )
                            }
                        >
                            <UpDownArrow />
                        </Box>
                    </Box>
                </Box>
            )}
        </Box>
    )
}
