import { Box, Input, Text } from 'src/components/utility'
import { useConfiguratorContext } from '../../configurator.context'
import { DivisionLine } from 'src/components/division-line/division-line'
import { Vector2 } from 'three'

export function GraphicHelpers() {
    const { graphic, setGraphic, highlights } = useConfiguratorContext()

    function updateRepeatAndOffset(
        value: number,
        key: 'u' | 'v',
        type: 'offset' | 'repeat'
    ) {
        const mesh = highlights[0].mesh
        if (graphic) {
            switch (type) {
                case 'offset':
                    if (key === 'u')
                        mesh.material.mapLayer1.offset = new Vector2(
                            mesh.material.mapLayer1.offset.x,
                            value
                        )
                    else
                        mesh.material.mapLayer1.offset = new Vector2(
                            value,
                            mesh.material.mapLayer1.offset.y
                        )
                    setGraphic({
                        ...graphic,
                        config: {
                            ...graphic.config,
                            offset: {
                                ...graphic.config.offset,
                                [key]: value,
                            },
                        },
                    })
                    return
                case 'repeat':
                    if (key === 'u')
                        mesh.material.mapLayer1.repeat = new Vector2(
                            mesh.material.mapLayer1.repeat.x,
                            value
                        )
                    else
                        mesh.material.mapLayer1.repeat = new Vector2(
                            value,
                            mesh.material.mapLayer1.repeat.y
                        )
                    setGraphic({
                        ...graphic,
                        config: {
                            ...graphic.config,
                            repeat: {
                                ...graphic.config.repeat,
                                [key]: value,
                            },
                        },
                    })
                    return
            }
        }
    }
    function updateTransparencyAndRotation(
        value: number,
        type: 'rotation' | 'tranparency'
    ) {
        if (!graphic) return
        const mesh = highlights[0].mesh
        switch (type) {
            case 'tranparency':
                mesh.material.transparencyLayer1 = value
                return setGraphic({
                    ...graphic,
                    config: {
                        ...graphic.config,
                        transparency: value,
                    },
                })
            case 'rotation':
                mesh.material.mapLayer1.rotation = value
                setGraphic({
                    ...graphic,
                    config: {
                        ...graphic.config,
                        rotation: value,
                    },
                })
                return
        }
    }

    return (
        <>
            <DivisionLine />
            <Box
                width="100%"
                display="flex"
                flexDirection="column"
                gridRowGap="12px"
                mt="16px"
            >
                <Box>
                    <Text my="0px" mb="8px">
                        Scale
                    </Text>
                    <UVScale
                        sensitivity={0.01}
                        u={graphic?.config.repeat.u || 0}
                        v={graphic?.config.repeat.v || 0}
                        onChange={(value, key) =>
                            updateRepeatAndOffset(value, key, 'repeat')
                        }
                    />
                </Box>
                <Box>
                    <Text my="0px" mb="8px">
                        Offset
                    </Text>
                    <UVScale
                        maxValue={100}
                        sensitivity={0.01}
                        u={graphic?.config.offset.u || 0}
                        v={graphic?.config.offset.v || 0}
                        onChange={(value, key) =>
                            updateRepeatAndOffset(value, key, 'offset')
                        }
                    />
                </Box>

                <Box>
                    <Text my="0px" mb="8px">
                        Opacity%
                    </Text>
                    <Box display="flex" justifyContent="space-between">
                        <Input
                            type="number"
                            value={graphic?.config.transparency || 0}
                            bg="transparent"
                            onChange={(e) =>
                                updateTransparencyAndRotation(
                                    Number(e.target.value),
                                    'tranparency'
                                )
                            }
                            border="solid"
                            borderWidth={1}
                            borderColor="secondaryLighterBlue"
                            borderRadius="4px"
                            width="71px"
                            height="32px"
                            max="1"
                        />
                        <Input
                            mt="12px"
                            className="intensity-slider"
                            width="60%"
                            value={graphic?.config.transparency || 0}
                            onChange={(e) =>
                                updateTransparencyAndRotation(
                                    Number(e.target.value),
                                    'tranparency'
                                )
                            }
                            type="range"
                            min="0"
                            step="0.01"
                            max="1"
                            bg="trasparent"
                        ></Input>
                    </Box>
                </Box>
                <Box>
                    <Text my="0px" mb="8px">
                        Rotation
                    </Text>
                    <Text fontSize="12px" my="0px" mb="4px">
                        Degrees
                    </Text>
                    <Box display="flex" justifyContent="space-between">
                        <Input
                            type="number"
                            value={graphic?.config.rotation || 0}
                            bg="transparent"
                            onChange={(e) =>
                                updateTransparencyAndRotation(
                                    Number(e.target.value),
                                    'rotation'
                                )
                            }
                            border="solid"
                            borderWidth={1}
                            borderColor="secondaryLighterBlue"
                            borderRadius="4px"
                            width="71px"
                            height="32px"
                            max="100"
                        />
                        <Input
                            mt="12px"
                            className="intensity-slider"
                            width="60%"
                            value={graphic?.config.rotation || 0}
                            onChange={(e) =>
                                updateTransparencyAndRotation(
                                    Number(e.target.value),
                                    'rotation'
                                )
                            }
                            type="range"
                            min="0"
                            step="0.1"
                            max="6"
                            bg="trasparent"
                        ></Input>
                    </Box>
                </Box>
            </Box>
        </>
    )
}

function UVScale({
    u,
    v,
    onChange,
    sensitivity = 0.1,
    maxValue = 1,
}: {
    u: number
    v: number
    onChange: (u: number, key: 'u' | 'v') => void
    sensitivity?: number
    maxValue?: number
}) {
    return (
        <Box
            width="100%"
            display="flex"
            flexDirection="column"
            gridRowGap="8px"
        >
            <Box
                width="100%"
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
                gridRowGap="4px"
            >
                <Text fontSize="12px" my="0px">
                    U
                </Text>
                <Box display="flex" justifyContent="space-between">
                    <Input
                        type="number"
                        value={u}
                        bg="transparent"
                        onChange={(e) => onChange(Number(e.target.value), 'u')}
                        border="solid"
                        borderWidth={1}
                        borderColor="secondaryLighterBlue"
                        borderRadius="4px"
                        width="71px"
                        height="32px"
                        max={maxValue}
                    />
                    <Input
                        mt="12px"
                        className="intensity-slider"
                        width="60%"
                        value={u}
                        onChange={(e) => onChange(Number(e.target.value), 'u')}
                        type="range"
                        min="0"
                        step={sensitivity}
                        max={maxValue}
                        bg="trasparent"
                    ></Input>
                </Box>
            </Box>
            <Box
                width="100%"
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
                gridRowGap="4px"
            >
                <Text fontSize="12px" my="0px">
                    V
                </Text>
                <Box display="flex" justifyContent="space-between">
                    <Input
                        type="number"
                        value={v}
                        bg="transparent"
                        onChange={(e) => onChange(Number(e.target.value), 'v')}
                        border="solid"
                        borderWidth={1}
                        borderColor="secondaryLighterBlue"
                        borderRadius="4px"
                        width="71px"
                        height="32px"
                        max={maxValue}
                    />
                    <Input
                        mt="12px"
                        className="intensity-slider"
                        width="60%"
                        value={v}
                        onChange={(e) => onChange(Number(e.target.value), 'v')}
                        type="range"
                        min="0"
                        step={sensitivity}
                        max={maxValue}
                        bg="trasparent"
                    ></Input>
                </Box>
            </Box>
        </Box>
    )
}
