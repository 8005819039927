import { DropDownArrow } from '../svg-icons'
import { Button } from '../utility'

export function IconDropdownButton({
    children,
    isActive = false,
    bg = '#ffffff',
    onClick,
    width,
    maxWidth,
    id,
}: {
    children: React.ReactNode
    isActive?: boolean
    bg?: string
    onClick?: () => void
    width?: string
    maxWidth?: string
    id?: string
}) {
    return (
        <Button
            bg={isActive ? '#022143' : bg}
            color={isActive ? '#ffffff' : 'primary'}
            py="8px"
            px="16px"
            borderRadius="4px"
            border="1px solid #A7B9D1"
            display="grid"
            alignItems="center"
            justifyContent="space-between"
            fontSize="14px"
            onClick={onClick}
            width={width}
            maxWidth={maxWidth}
            overflow="hidden"
            style={{
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                gap: '8px',
                gridTemplateColumns: '1fr 24px',
            }}
            id={id || 'dropdown-button'}
        >
            {children}
            <DropDownArrow color={isActive ? '#ffffff' : '#000000'} />
        </Button>
    )
}
