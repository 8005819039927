import React from 'react'
import Skeleton from 'react-loading-skeleton'
import { ActivityFeed } from 'src/components/recent-activity/activity-feed'
import { RecentActivityEmptyStateIco } from 'src/components/svg-icons'
import { Box, Text } from 'src/components/utility'
import { useClickedOutside } from 'src/helpers/hooks/use-clicked-outside'
import type { RecentActivityFilters } from './recent-activities.types'

function RecentActivitiesDropdown({
    entityTitle,
    recentActivities,
    setShowRecentActivity,
    activeFilter,
    setActiveFilter,
    isLoading,
}: {
    entityTitle: string
    recentActivities: Array<any>
    setShowRecentActivity: React.Dispatch<React.SetStateAction<boolean>>
    activeFilter: RecentActivityFilters
    setActiveFilter: React.Dispatch<React.SetStateAction<RecentActivityFilters>>
    isLoading?: boolean
}) {
    const wrapperRef = useClickedOutside(
        () => setShowRecentActivity(false),
        ['recent-activity-button']
    )

    return (
        <Box
            width="290px"
            height="80vh"
            position="absolute"
            right="0"
            top="24px"
            bg="#F0F2F3"
            p="24px"
            borderRadius="4px"
            display="grid"
            gridTemplateRows="auto 1fr"
            gridGap="16px"
            ref={wrapperRef}
            zIndex={15}
            boxShadow="rgba(153, 155, 168, 0.25) 0px 2px 12px"
        >
            <Box display="flex" flexDirection="column" gridGap="10px">
                <Text my="0px" className="word-break">
                    {entityTitle}
                </Text>
                <Text my="0px" fontSize="14px" fontWeight={500}>
                    Recent Activity
                </Text>
            </Box>
            {/* <Box display="flex" flexWrap="wrap" gridGap="4px">
                <PillButton
                    bg={activeFilter === 'today' ? 'primary' : 'white'}
                    color={activeFilter === 'today' ? 'white' : 'primary'}
                    onClick={() => {
                        if (activeFilter === 'today') {
                            setActiveFilter('default')
                        } else {
                            setActiveFilter('today')
                        }
                    }}
                >
                    Today's
                </PillButton>
                <PillButton
                    bg={activeFilter === 'yesterday' ? 'primary' : 'white'}
                    color={activeFilter === 'yesterday' ? 'white' : 'primary'}
                    onClick={() => {
                        if (activeFilter === 'yesterday') {
                            setActiveFilter('default')
                        } else {
                            setActiveFilter('yesterday')
                        }
                    }}
                >
                    Yesterday's
                </PillButton>
            </Box> */}
            {isLoading ? (
                <Skeleton count={10} />
            ) : recentActivities?.length > 0 ? (
                <Box
                    mt="12px"
                    pr="8px"
                    display="flex"
                    flexDirection="column"
                    gridRowGap="16px"
                    height="100%"
                    overflowY="auto"
                >
                    <ActivityFeed activity={recentActivities} />
                </Box>
            ) : (
                <Box
                    minHeight="50%"
                    overflow="hidden"
                    display=" flex"
                    justifyContent="center"
                    alignItems="center"
                    flexDirection="column"
                >
                    <Box
                        display="flex"
                        overflow="hidden"
                        justifyContent="center"
                        alignItems="center"
                        flexDirection="column"
                    >
                        <RecentActivityEmptyStateIco />
                        <Text fontSize="14px" color="textSecondaryDarkBlue">
                            No recorded activity!
                        </Text>
                    </Box>
                </Box>
            )}
        </Box>
    )
}

export default RecentActivitiesDropdown
