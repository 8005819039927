import { NetworkStatus } from '@apollo/client'
import * as React from 'react'
import ProductVariantFilterContainer from 'src/components-v2/product-variants/product-list-variant-filter-container/product-variant-filter-container'
import { CardLoader } from 'src/components/collections/card-loader'
import { Box } from 'src/components/utility'
import type { GetDashboardProductQuery } from 'src/generated/graphql'
import useIntersectionObserver from 'src/helpers/hooks/use-intersection-observer'
import { EmptyTodoState } from 'src/screens/dashboard/sub-screens/todos/empty-state-todo'

export interface IProductEntityListProps {
    entity: NonNullable<GetDashboardProductQuery>['products']
    onPageChange: () => void
    networkStatus: NetworkStatus
    isLoading: boolean
    totalProducts: number
}

export default function ProductEntityList(props: IProductEntityListProps) {
    const { entity, onPageChange, isLoading, networkStatus, totalProducts } =
        props
    const containerRef = React.useRef<HTMLDivElement>(null)
    const [isAtTop, setIsAtTop] = React.useState(true)
    const { isIntersecting, lastElementRef } = useIntersectionObserver({
        isLoading: isLoading,
        threshold: 0.5,
    })
    const isFetchingMore = networkStatus === NetworkStatus.fetchMore

    React.useEffect(() => {
        if (isIntersecting) {
            onPageChange()
        }
    }, [isIntersecting])

    React.useEffect(() => {
        const handleScroll = () => {
            if (containerRef.current) {
                setIsAtTop(containerRef.current.scrollTop === 0)
            }
        }

        const container = containerRef.current
        if (container) {
            container.addEventListener('scroll', handleScroll)
        }

        return () => {
            if (container) {
                container.removeEventListener('scroll', handleScroll)
            }
        }
    }, [])

    return (
        <Box
            ref={containerRef}
            height="500px"
            overflowY="auto"
            id="dashboard_product_entity_container"
            style={{
                overscrollBehavior:
                    isAtTop || totalProducts === entity.length
                        ? 'auto'
                        : 'none',
            }}
        >
            {entity.map((product, index) => {
                const isLastProduct = entity.length === index + 1
                return (
                    <Box
                        ref={isLastProduct ? lastElementRef : null}
                        id={`parent_product_${product.id}`}
                    >
                        <ProductVariantFilterContainer
                            isFullCard={false}
                            status="all"
                            key={index}
                            product={product}
                            refetchQueries={['getProductAggregate']}
                        />
                    </Box>
                )
            })}
            {isFetchingMore && <CardLoader skeletonCount={3} />}
            {entity.length <= 0 && (
                <Box>
                    <EmptyTodoState
                        boxHeight={'500px'}
                        title={'No Products Yet!'}
                        description={'Try creating products.'}
                        enableCreateButton={true}
                    />
                </Box>
            )}
        </Box>
    )
}
