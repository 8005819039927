/**
 * @param {Date} date
 * @returns formatted date `31 Oct’21`
 * @default PLAIN
 */

export function getFormattedDate(
    date: Date | string,
    type?: 'PLAIN' | 'FORMATTED',
    hideYear?: boolean
) {
    const dateSplit = new Date(date).toDateString().split(' ')

    if (type !== 'FORMATTED') {
        return (
            dateSplit[2] +
            ' ' +
            dateSplit[1] +
            (hideYear ? '' : ' ' + dateSplit[3])
        )
    }

    return (
        dateSplit[2] +
        ' ' +
        dateSplit[1] +
        (hideYear ? '' : '’' + dateSplit[3]?.substring(2))
    )
}
