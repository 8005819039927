import { useEffect, useRef, useState } from 'react'
import Image from '@tiptap/extension-image'
import Link from '@tiptap/extension-link'
import Mention from '@tiptap/extension-mention'
import Placeholder from '@tiptap/extension-placeholder'
import Underline from '@tiptap/extension-underline'
import { EditorContent, useEditor } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import suggestions from './suggestions'
import { Box } from '../utility'
import HardBreak from '@tiptap/extension-hard-break'
import type { Team } from 'src/services/graphql/query/@types/collections'

export const Editor = ({
    team,
    setQuery,
    setEditor,
    onEnter,
    initialContent = '',
    isReadOnly = false,
    height,
    placeholder,
    users,
}: {
    onEnter: (editor: any) => any
    team?: Team | null
    setQuery: (query: string) => void
    setEditor: (editor: ReturnType<typeof useEditor>) => void
    initialContent?: string
    isReadOnly?: boolean
    height?: string
    placeholder?: string
    users?: Array<{ user: TeamMember }>
}) => {
    const onlyUnique = (membersList: any[]) => {
        const uniqListObj: any = {}
        for (const item of membersList) {
            uniqListObj[item.user.id] = item
        }
        return Object.values(uniqListObj)
    }

    const [focus, setFocus] = useState(false)
    const editor = useEditor(
        {
            onUpdate: (e) => {
                setQuery(e.editor.getText())
            },
            editable: !isReadOnly,
            extensions: [
                StarterKit,
                Placeholder.configure({
                    placeholder: placeholder
                        ? placeholder
                        : 'Write a comment...',
                }),
                HardBreak.extend({
                    addKeyboardShortcuts() {
                        return {
                            Enter: (e) => {
                                this.editor.setEditable(false)
                                onEnter(this.editor).then(() =>
                                    this.editor.setEditable(true)
                                )
                                return true
                            },
                        }
                    },
                }),
                Image,
                Link,
                Underline,
                Mention.configure({
                    HTMLAttributes: {
                        class: 'mention',
                    },
                    suggestion: {
                        items: ({ query }: { query: string }) => {
                            if (!query && team) {
                                return team?.team_members
                                    ? [
                                          {
                                              user: {
                                                  name: 'Team',
                                                  id: team.id,
                                                  email: '',
                                              },
                                          },
                                          ...team.team_members,
                                      ]
                                    : [
                                          {
                                              user: {
                                                  name: 'Team',
                                                  id: team.id,
                                                  email: '',
                                              },
                                          },
                                      ]
                            }
                            const _members = users ? users : []
                            const members = !!team
                                ? [
                                      {
                                          user: {
                                              name: 'Team',
                                              id: team.id,
                                              email: '',
                                          },
                                      },
                                      ..._members,
                                  ]
                                : _members

                            return members.filter((member: any) =>
                                member.user.name
                                    .toLowerCase()
                                    .includes(query.toLowerCase())
                            )
                        },
                        ...suggestions,
                    },
                }),
            ],
            content: initialContent,
        },
        [team, isReadOnly, users]
    )

    const parentDiv = useRef<HTMLDivElement | null>(null)

    useEffect(() => {
        const stopPropagation = (event: KeyboardEvent) => {
            if (event.key === 'ArrowLeft' || event.key === 'ArrowRight') {
                event.stopPropagation()
            }
        }

        const parentElement = parentDiv.current
        parentElement?.addEventListener('keydown', stopPropagation)

        return () => {
            parentElement?.removeEventListener('keydown', stopPropagation)
        }
    }, [parentDiv.current])

    useEffect(() => {
        if (editor) {
            setEditor(editor)
        }
    }, [editor])

    return (
        <Box
            ref={parentDiv}
            onFocus={() => {
                setFocus(true)
            }}
            onBlur={() => {
                setFocus(false)
            }}
            display="flex"
            flexDirection="column"
            gridRowGap="16px"
            width="90%"
            height={height ? height : 'inherit'}
        >
            <EditorContent
                style={{
                    width: '100%',
                    height: '50px !important',
                    textAlign: 'left',
                }}
                editor={editor}
            />
        </Box>
    )
}
