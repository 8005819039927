import * as React from 'react'
import { Box, Button, Image, Input, Link } from 'src/components/utility'
import { useConfiguratorContext } from '../../configurator.context'
import { uuid } from 'src/helpers'
import { getLocalImageUrl } from 'src/helpers/get-image-url'
import { captureImage, createMaterial } from '../../helper/create-material'
import {
    Download,
    UploadFile,
} from 'src/components/svg-icons/3d-renderer/actions'
import { DeleteIco } from 'src/components/svg-icons'
import { GraphicHelpers } from './helpers'
import { MeshCustomMaterial } from '../material/custom-material'
//@ts-ignore
// import { MeshCustomMaterial } from 'three/src/materials/MeshCustomMaterial.js'

export interface IFabricBasicProps {}

const UV = {
    u: 0,
    v: 0,
}

export default function GraphicBasic(props: IFabricBasicProps) {
    const { highlights, setGraphic, graphic } = useConfiguratorContext()
    const inputRef = React.useRef<HTMLInputElement | undefined>()
    const graphicImage = graphic
        ? getLocalImageUrl(new File([graphic.blob], graphic.name)) ||
          'https://i0.wp.com/sumac.com.hk/wp-content/uploads/2022/11/placeholder.png?ssl=1'
        : highlights.length === 1 && highlights[0].mesh.material.map
        ? captureImage(highlights[0].mesh, 'mapLayer1') ||
          'https://i0.wp.com/sumac.com.hk/wp-content/uploads/2022/11/placeholder.png?ssl=1'
        : 'https://i0.wp.com/sumac.com.hk/wp-content/uploads/2022/11/placeholder.png?ssl=1'

    function handleOnUpload(e: React.ChangeEvent<HTMLInputElement>) {
        if (!e.target.files) return
        const file = e.target.files[0]
        const mat = new MeshCustomMaterial()
        const selectedMaterial = highlights[0]
        if (file) {
            const url = getLocalImageUrl(file)
            console.log({ url })
            if (url) {
                mat.mapLayer1 = createMaterial(url, {
                    offset: {
                        u: selectedMaterial.mesh.material?.map?.offset?.x || 0,
                        v: selectedMaterial.mesh.material?.map?.offset?.y || 0,
                    },
                    scale: {
                        u: selectedMaterial.mesh.material?.map?.repeat?.x || 0,
                        v: selectedMaterial.mesh.material?.map?.repeat?.y || 0,
                    },
                }).plain
                mat.map = selectedMaterial.mesh.material.map
                mat.color = selectedMaterial.mesh.material.color
                highlights[0].mesh.material = mat
            }
        }

        setGraphic({
            blob: new Blob([file]),
            id: uuid(),
            name: file.name,
            path: file.webkitRelativePath,
            config: {
                offset: {
                    u: selectedMaterial.mesh.material?.map?.offset?.x || 0,
                    v: selectedMaterial.mesh.material?.map?.offset?.y || 0,
                },
                repeat: {
                    u: selectedMaterial.mesh.material?.map?.repeat?.x || 0,
                    v: selectedMaterial.mesh.material?.map?.repeat?.y || 0,
                },
                rotation: selectedMaterial.mesh.material?.map?.rotation || 0,
                transparency: 0,
            },
        })
    }

    return (
        <Box px="16px" display="flex" gridColumnGap="4px">
            <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                width="100%"
                alignItems="center"
                className="cursor-pointer"
            >
                <Box
                    borderRadius="4px"
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    // width="max-content"
                    alignItems="center"
                    gridRowGap="4px"
                    border="solid"
                    width="100%"
                    borderColor="white"
                    borderWidth={1}
                >
                    <Box width="100%">
                        <Image
                            className="img-clear"
                            width="100%"
                            height="100px"
                            src={graphicImage}
                        />
                        <Box
                            display="flex"
                            alignItems="center"
                            gridColumnGap="20px"
                        >
                            <Button
                                p="0px"
                                bg="transparent"
                                border="none"
                                onClick={() =>
                                    inputRef.current &&
                                    inputRef.current.click &&
                                    inputRef.current.click()
                                }
                            >
                                <UploadFile />
                            </Button>
                            <Input
                                autoComplete="false"
                                type="file"
                                ref={inputRef}
                                onChange={(
                                    e: React.ChangeEvent<HTMLInputElement>
                                ) => handleOnUpload(e)}
                                onClick={(e: any) => {
                                    e.target.value = null
                                }}
                                display="none"
                                bg="none"
                            />

                            <Link
                                href={graphicImage}
                                download={'base_map' + '.png'}
                                bg="white"
                                style={{
                                    border: 'none',
                                    borderRadius: '4px',
                                    padding: '8px',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    display: 'flex',
                                }}
                                className="download-link"
                            >
                                <Download />
                            </Link>
                            <Button
                                onClick={() => {
                                    highlights[0].mesh.material.mapLayer1 = null
                                    setGraphic(undefined)
                                }}
                                p="0px"
                                bg="transparent"
                                border="none"
                            >
                                <DeleteIco />
                            </Button>
                        </Box>
                    </Box>
                </Box>
                <GraphicHelpers />
            </Box>
        </Box>
    )
}
