import React from 'react'
import ProductListCard from 'src/components-v2/entity-list-card/product-list.card'
import { Box } from 'src/components/utility'
import type { GetProductsQuery } from 'src/generated/graphql'

function ProductListVariantContainer({
    product,
    refetch,
}: {
    product: Pick<GetProductsQuery, 'products'>['products'][number]
    refetch?: () => void
    dashboardView?: boolean
    tabView?: boolean
}) {
    const baseProduct = product?.product_variants?.find(
        (product) => product.is_primary
    )
    const productVariants = product?.product_variants?.filter(
        (product) => !product.is_primary
    )

    return (
        <Box display="flex" flexDirection="column">
            {baseProduct && (
                <ProductListCard
                    key={baseProduct.id}
                    product={{
                        id: baseProduct.id,
                        name: baseProduct.name,
                        coverPic: baseProduct.other_data?.cover_pic,
                        collection: {
                            id: baseProduct.collection.id,
                            name: baseProduct.collection.name,
                        },
                        parentProductId: product.id,
                        dueDate: baseProduct.due_date,
                        isDelayed: baseProduct.is_delayed,
                        status: baseProduct.status,
                        team: baseProduct.team,
                        todosCount: {
                            total: baseProduct.totalTodos.aggregate?.count || 0,
                            completed:
                                baseProduct.completedTodos.aggregate?.count ||
                                0,
                        },
                    }}
                    refetchProducts={refetch}
                />
            )}
            {productVariants &&
                productVariants?.length > 0 &&
                productVariants.map((variant) => (
                    <ProductListCard
                        key={variant.id}
                        product={{
                            id: variant.id,
                            name: variant.name,
                            coverPic: variant.other_data?.cover_pic,
                            collection: {
                                id: variant.collection.id,
                                name: variant.collection.name,
                            },
                            parentProductId: product.id,
                            dueDate: variant.due_date,
                            isDelayed: variant.is_delayed,
                            status: variant.status,
                            team: variant.team,
                            todosCount: {
                                total: variant.totalTodos.aggregate?.count || 0,
                                completed:
                                    variant.completedTodos.aggregate?.count ||
                                    0,
                            },
                        }}
                        isVariant
                        refetchProducts={refetch}
                    />
                ))}
        </Box>
    )
}

export default ProductListVariantContainer
