import * as React from 'react'
import ProductVariantFilterContainer from 'src/components-v2/product-variants/product-list-variant-filter-container/product-variant-filter-container'
import { EntityEmptyState } from 'src/components/collections/entity-empty-state'
import { Box } from 'src/components/utility'
import type { GetDashboardProductQuery } from 'src/generated/graphql'
import useStore from 'src/store/hooks/useStore'

export interface IProductTabDataProps {
    product: NonNullable<GetDashboardProductQuery>['products']
    tabView: boolean
    refetch?: () => void
}

export default function ProductTabData(props: IProductTabDataProps) {
    const { tabView = true } = props
    const { clearDrawerTabs } = useStore()
    if (props?.product?.length <= 0)
        return <EntityEmptyState entityName="Products" />
    return (
        <Box mt="16px" display="flex" flexDirection="column" gridRowGap="8px">
            {props.product.length > 0 ? (
                props.product?.map((product, index) => {
                    return (
                        // <ProductVariantContainer
                        //     dashboardView={tabView}
                        //     refetch={() => props.refetch && props.refetch()}
                        //     product={product}
                        //     tabView={tabView}
                        // />
                        <ProductVariantFilterContainer
                            onClick={() => clearDrawerTabs()}
                            isFullCard={false}
                            status="all"
                            key={product?.name + index}
                            product={product}
                        />
                    )
                })
            ) : (
                <EntityEmptyState entityName="Products" />
            )}
        </Box>
    )
}
