import { ApolloProvider } from '@apollo/client'
import { useEffect, useState } from 'react'
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import 'src/config/firebase'
import { onMessageListener } from 'src/config/firebase'
import { Protected } from 'src/protected/protected'
import { Layout, Toaster } from './components'
import { DrawerContainer } from './components/drawer/drawer-container'
import { Box } from './components/utility'
import { getLocalStorage, logoutUser } from './helpers'
import { useApolloClient } from './helpers/initialize-apollo'
import { NotificationParser } from './helpers/notification-parser'
import NoInternetConnection from './no-internet'
import {
    AddMembers,
    Dashboard as DashboardV1,
    ForgotPassword,
    Loading,
    Login,
    PDFViewer,
    PasswordReset,
    Signup,
    Support,
} from './screens'

import { Page404 } from './screens/404/404'
import { FilePreview } from './screens/file-preview/file-preview'
import { PasswordResetLink } from './screens/forgot-password/password-reset-link'
import AddFilesToLibrary from './screens/library/add-files'
import InsideFolderView from './screens/library/inside-folder'
import Library from './screens/library/library'
import { SelectLibraryFiles } from './screens/library/select-files'
import { LibraryTabData } from './screens/library/tab-data'
import Reports from './screens/reports/reports'
import { Todos } from './screens/todos/todos'
import useStore from './store/hooks/useStore'
import Product from './screens-v2/product/product'
import Collection from './screens-v2/collection/collection'
import { Collections } from './screens-v2/collections/collections'
import { useCommentsAndActivitesWebSocket } from './helpers/hooks/use-comments-activities-socket'
import { OrganisationAndAnalytics } from './screens-v2/organisations-analytics/organisations'
import TodoTemplates from './screens-v2/todo-templates/todo-templates'
import TodoTemplatesFavourites from './screens-v2/todo-templates-favourites/todo-templates-favourites'
import Todo from './screens-v2/todo/todo'
import Teams from './screens-v2/teams/teams'
import { IndividualCollaboratorTab } from './components/drawer/individual-collaborator-tab/individual-collaborator-tab'
import Configure from './screens-v2/configure/configure'
import OrganizationContext from './screens-v2/organisations-analytics/organisation.context'
import Notifications from './screens-v2/notifications/notification'
import NotificationContext from './screens-v2/notifications/notification.context'
import Dashboard from './screens-v2/dashboard/dashboard'

function App() {
    const [downloadUrl, setDownloadUrl] = useState<{
        url: string
        type: string | undefined
    } | null>(null)
    const { isLoading } = useStore()
    const [token, setToken] = useState<string | null>(
        getLocalStorage('authToken')
    )
    const client = useApolloClient(token)
    const location = useLocation()
    const navigate = useNavigate()
    const { step, setStep } = useStore()

    // Establishing websocket connection to get real time comments and activities
    useCommentsAndActivitesWebSocket()

    useEffect(() => {
        if (
            step === 2 &&
            !location.pathname.includes('/library/selectfiles/') &&
            !location.pathname.includes('product/edit-product/') &&
            !location.pathname.includes('create-products')
        ) {
            setStep({ payload: 'PREV' })
        }
    }, [location])

    useEffect(() => {
        if (downloadUrl?.type && downloadUrl.type === 'DOWNLOAD') {
            window.location.href = downloadUrl.url
        }
    }, [downloadUrl])

    function checkRoleUpdation(payload: any) {
        if (!payload) return
        if (payload.notification.title === 'Organisation Update') {
            client.resetStore()
            const user = getLocalStorage('user')
            if (user.length > 0) {
                toast('You are now logged out. Thank you for using Virtu', {
                    className: 'custom-toaster toaster-success',
                })
            }
            logoutUser()
            navigate('/login')
        }
    }

    const routes = {
        products: '',
        collections: '',
        todos: '',
    }

    //!TODO: ADD HIGHLIGHT OPTION IN STATE
    // state={
    //     {
    //         highlight: {
    //             operation: 'collection',
    //             id: 156
    //         }
    //     }
    // }

    //{ message: display_string, redirection: {entity_name: "Products",entity_id:12,highlight:{entity_name:"todos",entity_id:22}}} NEW UPDATE
    onMessageListener()
        .then((payload: any) => {
            toast(
                <Box>
                    <b>
                        <NotificationParser
                            getUrl={(url, type) =>
                                setDownloadUrl({ type, url })
                            }
                            comment={payload.notification.title}
                        />
                    </b>
                    <br />
                    <Box className="word-wrap">
                        <NotificationParser
                            getUrl={(url, type) =>
                                setDownloadUrl({ type, url })
                            }
                            comment={
                                JSON.parse(payload.notification.body).message
                            }
                        />
                    </Box>
                </Box>,
                {
                    className: 'custom-toaster toaster-info',
                    style: { width: 'max-content' },
                    onClick: () => {
                        /**
                         * @TODO Create a redirection generator function which redirects
                         * on the basis of topic
                         */
                        // const topicData = JSON.parse(payload.data[TOPIC])
                        // navigate(`/collections/product/${topicData.id}`)
                    },
                }
            )

            checkRoleUpdation(payload)
        })
        .catch((err) => {
            console.log({ err })
        })

    // Broadcast Channel linked to service worker, this will be triggered if user is inactive and receives a notification
    const channel = new BroadcastChannel('sw-messages')
    channel.addEventListener('message', (event) => {
        console.log(event?.data?.payload)
        checkRoleUpdation(event?.data?.payload)
    })

    useEffect(() => {
        if (!isLoading) {
            navigate('/')
        } else if (
            location.pathname === '/login'
            // location.pathname === '/collections'
        ) {
            navigate(location.pathname + location.search, { replace: true })
        }
    }, [])

    return (
        <NoInternetConnection>
            <ApolloProvider client={client}>
                <div className="App">
                    <DrawerContainer>
                        {isLoading && <Loading />}
                        {!isLoading && (
                            <>
                                <Routes>
                                    {/* Home route */}

                                    <Route
                                        path="/dashboard"
                                        element={
                                            <Protected>
                                                <Layout>
                                                    <DashboardV1 />
                                                </Layout>
                                            </Protected>
                                        }
                                    />
                                    <Route
                                        path="/"
                                        element={
                                            <Protected>
                                                <Layout>
                                                    <Dashboard />
                                                </Layout>
                                            </Protected>
                                        }
                                    />

                                    {/* Entity routes */}

                                    <Route
                                        path="/collections"
                                        element={
                                            <Protected>
                                                <Layout
                                                    paddingX="0px"
                                                    paddingBottom="0px"
                                                >
                                                    <Collections />
                                                </Layout>
                                            </Protected>
                                        }
                                    />

                                    <Route
                                        path="/collections/:id"
                                        element={
                                            <Protected>
                                                <Layout
                                                    paddingBottom="0px"
                                                    paddingX="0px"
                                                >
                                                    <Collection />
                                                </Layout>
                                            </Protected>
                                        }
                                    />

                                    <Route
                                        path="/collections/product/:productId"
                                        element={
                                            <Protected>
                                                <Layout
                                                    paddingX="0px"
                                                    paddingBottom="0px"
                                                >
                                                    <Product />
                                                </Layout>
                                            </Protected>
                                        }
                                    />

                                    <Route
                                        path="/todo/:todoId"
                                        element={
                                            <Protected>
                                                <Layout
                                                    paddingX="0px"
                                                    paddingBottom="0px"
                                                >
                                                    <Todo />
                                                </Layout>
                                            </Protected>
                                        }
                                    />

                                    <Route
                                        path="/todos"
                                        element={
                                            <Protected>
                                                <Layout
                                                    paddingX="0px"
                                                    paddingBottom="0px"
                                                >
                                                    <Todos />
                                                </Layout>
                                            </Protected>
                                        }
                                    />

                                    {/* Library routes */}

                                    <Route
                                        path="/library"
                                        element={
                                            <Protected>
                                                <Layout>
                                                    <Library>
                                                        <LibraryTabData />
                                                    </Library>
                                                </Layout>
                                            </Protected>
                                        }
                                    />
                                    <Route
                                        path="/library/todo-templates"
                                        element={
                                            <Protected>
                                                <Layout
                                                    paddingBottom="0px"
                                                    paddingX="0px"
                                                >
                                                    <TodoTemplates />
                                                </Layout>
                                            </Protected>
                                        }
                                    />

                                    <Route
                                        path="/library/todo-templates/favourites"
                                        element={
                                            <Protected>
                                                <Layout
                                                    paddingBottom="0px"
                                                    paddingX="8px"
                                                >
                                                    <TodoTemplatesFavourites />
                                                </Layout>
                                            </Protected>
                                        }
                                    />

                                    <Route
                                        path="/library/folder/:folderId"
                                        element={
                                            <Protected>
                                                <Layout>
                                                    <Library>
                                                        <InsideFolderView />
                                                    </Library>
                                                </Layout>
                                            </Protected>
                                        }
                                    />

                                    <Route
                                        path="/library/addfiles/:categoryId"
                                        element={
                                            <Protected>
                                                <Layout>
                                                    <AddFilesToLibrary />
                                                </Layout>
                                            </Protected>
                                        }
                                    />

                                    <Route
                                        path="/library/editfile/:editFileId"
                                        element={
                                            <Protected>
                                                <Layout>
                                                    <AddFilesToLibrary />
                                                </Layout>
                                            </Protected>
                                        }
                                    />

                                    <Route
                                        path="/file-preview"
                                        element={
                                            <Protected>
                                                <Layout>
                                                    <FilePreview />
                                                </Layout>
                                            </Protected>
                                        }
                                    />

                                    <Route
                                        path="/library/selectfiles/:entityId/:entityName"
                                        element={
                                            <Protected>
                                                <SelectLibraryFiles />
                                            </Protected>
                                        }
                                    />

                                    <Route
                                        path="/library/selectfiles/:entityName"
                                        element={
                                            <Protected>
                                                <SelectLibraryFiles />
                                            </Protected>
                                        }
                                    />

                                    <Route
                                        path="/library/selectfiles/:entityId/:entityName/:folderId"
                                        element={
                                            <Protected>
                                                <SelectLibraryFiles />
                                            </Protected>
                                        }
                                    />

                                    <Route
                                        path="/pdf-viewer"
                                        element={
                                            <Protected>
                                                <Layout
                                                    paddingBottom={'0px'}
                                                    paddingX="0px"
                                                >
                                                    <PDFViewer />
                                                </Layout>
                                            </Protected>
                                        }
                                    />

                                    {/* Organization and team routes */}

                                    <Route
                                        path="/organizations"
                                        element={
                                            <Protected>
                                                <Layout paddingX={'0px'}>
                                                    <OrganizationContext>
                                                        <OrganisationAndAnalytics />
                                                    </OrganizationContext>
                                                </Layout>
                                            </Protected>
                                        }
                                    />

                                    <Route
                                        path="/team/:id"
                                        element={
                                            <Protected>
                                                <Layout paddingX="0px">
                                                    <Teams />
                                                </Layout>
                                            </Protected>
                                        }
                                    />

                                    <Route
                                        path="/user/:id"
                                        element={
                                            <Protected>
                                                <Layout paddingX="0px">
                                                    <IndividualCollaboratorTab
                                                        tabView={false}
                                                    />
                                                </Layout>
                                            </Protected>
                                        }
                                    />

                                    <Route
                                        path="/add-members"
                                        element={
                                            <Protected>
                                                <AddMembers />
                                            </Protected>
                                        }
                                    />

                                    {/* Miscellaneous routes */}

                                    <Route
                                        path="/notifications"
                                        element={
                                            <Protected>
                                                <Layout>
                                                    <NotificationContext>
                                                        <Notifications />
                                                    </NotificationContext>
                                                </Layout>
                                            </Protected>
                                        }
                                    />

                                    <Route
                                        path="/reports"
                                        element={
                                            <Protected>
                                                <Layout>
                                                    <Reports />
                                                </Layout>
                                            </Protected>
                                        }
                                    />

                                    <Route
                                        path="/support"
                                        element={
                                            <Protected>
                                                <Layout>
                                                    <Support />
                                                </Layout>
                                            </Protected>
                                        }
                                    />

                                    <Route
                                        path="/configure"
                                        element={
                                            <Protected>
                                                <Layout>
                                                    <Configure />
                                                </Layout>
                                            </Protected>
                                        }
                                    />

                                    {/**
                                     * UNPROTECTED ROUTES
                                     *
                                     * All the routes below can be accessed without authentication. */}

                                    <Route
                                        path="/signup"
                                        element={<Signup />}
                                    />

                                    <Route
                                        path="/login"
                                        element={
                                            <Login
                                                setToken={(token) =>
                                                    setToken(token)
                                                }
                                            />
                                        }
                                    />
                                    <Route
                                        path="/password-reset-link"
                                        element={<PasswordResetLink />}
                                    />
                                    <Route
                                        path="/password-reset"
                                        element={<PasswordReset />}
                                    />
                                    <Route
                                        path="/forgot-password"
                                        element={<ForgotPassword />}
                                    />

                                    <Route
                                        path="/*"
                                        element={<Page404 />}
                                    ></Route>
                                </Routes>
                                <Toaster />
                            </>
                        )}
                    </DrawerContainer>
                </div>
            </ApolloProvider>
        </NoInternetConnection>
    )
}

export default App
