/* eslint-disable @typescript-eslint/no-use-before-define */
import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import type { ChangeEvent } from 'react'
import React, { useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import {
    DivisionLine,
    StackedImage,
    TagAutoCommplete,
    VCalendar,
} from 'src/components'
import { ColoredButton } from 'src/components/buttons/colored-button'
import { ProfileImg } from 'src/components/profile-img/profile-img'
import {
    CalendarIco,
    CircleCheck,
    DescriptionIco,
    FilledCloseIco,
    PersonIco,
    ProductNameIco,
    TagsIco,
    TeamIco,
} from 'src/components/svg-icons'
import { Box, Button, Input, Text, TextArea } from 'src/components/utility'
import {
    debounce,
    debugLog,
    getFormattedDate,
    getImageUrl,
    useUser,
} from 'src/helpers'
import { updateTags } from 'src/helpers/sort-and-insert-tags'
import {
    AddNewTagWithRelation,
    AddTagWithRelation,
    CreateProduct,
    GetCollectionByID,
    GetTags,
    GetTeamDetailsById,
    SearchManagers,
    UpdateProduct,
} from 'src/services'
import { GetProductById } from 'src/services/graphql/query/get-product-by-id'
import useStore from 'src/store/hooks/useStore'

export const ProductForm = () => {
    const [date, setDate] = useState<Date | null>(null)
    const navigate = useNavigate()
    const [collection, setCollection] = useState<any>()
    // const { state }: { state: any } = useLocation()
    // debugLog(state)
    const [approverDueDate, setApproverDueDate] = useState<Date | null>(null)
    const dateSplit = new Date(new Date().toLocaleDateString()).toDateString()
    const { addTags, resetTodos, resetForm } = useStore()

    // const [isCalendarVisible, setIsCalendarVisible] = useState<boolean>(false)
    const [isCalendar, setIsCalendar] = useState({
        isDueDate: false,
        isApproversDueDate: false,
    })
    const [query, setQuery] = useState<string>('')
    const [email, setEmail] = useState('')
    const approverCalendarRef = useRef<HTMLDivElement | null>(null)
    const dueDateCalendarRef = useRef<HTMLDivElement | null>(null)
    const [team, setTeam] = useState<any>()
    const [formLoading, setFormLoading] = useState(false)
    const [isManagerSelected, setIsManagerSelected] = useState(false)
    const { step, stepLimit, productForm } = useStore((state) => ({
        step: state.step,
        stepLimit: state.stepLimit,
        productForm: state.productForm,
    }))

    const [getTeamDetailsById, teamDetails] = useLazyQuery(GetTeamDetailsById)
    const { setStep, updateProductForm, setTeamMembers } = useStore()

    const [managers, setManagers] = useState<ManagerUser[]>([])
    const [manager, setManager] = useState<ManagerUser | null>(null)
    const [isManagerDropdown, setIsManagerDropdown] = useState(false)
    const { setProductForm, setTodos } = useStore()

    const params = useParams()
    const collectionId = params.id
    const [fetchCollection, collectionData] = useLazyQuery(GetCollectionByID)
    const [searchManagers, foundManagers] = useLazyQuery(SearchManagers)
    const collectionByID = useQuery(GetCollectionByID, {
        variables: {
            id: params.id,
        },
    })

    const { organization_id } = useUser()
    const [CreateProductMutation, productUpdating] = useMutation(CreateProduct)
    const getTags = useQuery(GetTags)
    const location = useLocation()
    const [AddNewTagWithRelationMutation] = useMutation(AddNewTagWithRelation)
    const [AddTagWithRelationMutation] = useMutation(AddTagWithRelation)
    const [UpdateProductMutation] = useMutation(UpdateProduct)
    const {
        data,
        refetch,
    }: {
        data: { products_by_pk: IndividualProduct } | undefined
        refetch: any
    } = useQuery(GetProductById, {
        skip: !params.productId,
        variables: {
            id: params.productId || 0,
            organisation_id: organization_id,
        },
    })
    const [timeOutRef, setTimeOutRef] =
        useState<ReturnType<typeof setTimeout>>()

    const getManagersDebounce = debounce(
        findManagers,
        400,
        setTimeOutRef,
        timeOutRef
    )
    useEffect(() => {
        if (data && data.products_by_pk) {
            const todos = {
                data: data.products_by_pk.todos
                    ?.slice()
                    ?.sort((a, b) => Number(a.id) - Number(b.id))
                    .map((todo) => {
                        return {
                            created_at: todo?.created_at,
                            due_date: todo?.due_date,
                            description: todo?.description,
                            assignee: todo?.assignee,
                            status: todo?.status,
                            id: todo?.id,
                            name: todo?.name,
                            dueDate: todo?.due_date,
                            duration: todo?.duration,
                            assignee_id: todo?.assignee?.id,
                            expanded: false,
                            editableHeading: false,
                            isCompleted: 'UNEDITED',
                            sub_todos: {
                                data: todo?.sub_todos
                                    ?.slice()
                                    ?.sort(
                                        (a, b) => Number(a.id) - Number(b.id)
                                    )
                                    .map((sub_todo) => {
                                        return {
                                            id: sub_todo.id,
                                            created_at: sub_todo.created_at,
                                            heading: sub_todo.heading,
                                            description: sub_todo.description,
                                            file:
                                                sub_todo.files
                                                    .map((file) => {
                                                        if (
                                                            file.path?.length >
                                                            0
                                                        ) {
                                                            return {
                                                                name: file.name,
                                                                path: file.path,
                                                                blob: file.blob,
                                                                id: file.id,
                                                            }
                                                        }
                                                        return file
                                                    })
                                                    .filter(
                                                        (file) =>
                                                            file.path?.length >
                                                            0
                                                    ) || [],
                                            type: sub_todo.other_data.type,
                                            editableHeading: false,
                                            color: sub_todo.other_data.color,
                                        }
                                    }),
                            },
                        }
                    }),
            }
            const product = {
                ...data.products_by_pk,
                tags:
                    data.products_by_pk.tags?.map((tag, key) => ({
                        id: key.toString(),
                        name: tag.tag,
                        color: tag.color,
                    })) || [],
                todos: todos,
            }
            setProductForm({
                product,
            })
            setTodos({
                todos: todos.data,
            })
            setEmail(data.products_by_pk.approver.email)
        }
    }, [data])
    useEffect(() => {
        if (collectionId && collectionId !== '0') {
            fetchCollection({
                variables: {
                    id: collectionId,
                },
            })
        }
    }, [collectionId])

    useEffect(() => {
        if (collectionData.data && collectionData.data.collections_by_pk) {
            setCollection(collectionData.data?.collections_by_pk)
        }
    }, [collectionData.data])

    useEffect(() => {
        if (date) {
            const dateSplit = new Date(date).toISOString()
            updateProductForm({ payload: dateSplit, action: 'due_date' })
        }
    }, [date])

    useEffect(() => {
        if (isManagerSelected && manager) {
            updateProductForm({
                payload: manager.user.id,
                action: 'approver_id',
            })
        }
    }, [isManagerSelected, manager])

    useEffect(() => {
        ;(async function () {
            if (productForm.approver_id !== 0) {
                const data = await findManagers(
                    `${productForm.approver_id}`,
                    'id'
                )
                setIsManagerSelected(true)
                console.log(data)
                setManager(data[0])
            }
        })()
    }, [])

    useEffect(() => {
        if (approverDueDate) {
            const dateSplit = new Date(approverDueDate).toISOString()
            updateProductForm({
                payload: dateSplit,
                action: 'approver_due_date',
            })
            // debugLog(productForm)
        }
    }, [approverDueDate])

    useEffect(() => {
        if (team) {
            updateProductForm({ payload: team.id, action: 'team_id' })
        }
    }, [team])

    useEffect(() => {
        setEmail(query)
    }, [query])

    useEffect(() => {
        if (collection) {
            if (collection?.team?.id) {
                updateProductForm({
                    payload: collection.team.id,
                    action: 'team_id',
                })
                if (collection?.team?.id) {
                    getTeamDetailsById({
                        variables: {
                            id: collection?.team?.id,
                        },
                    })
                }
            }
            if (collection && collection?.id) {
                updateProductForm({
                    payload: collection?.id,
                    action: 'collection_id',
                })
            }
        }
    }, [collection])

    useEffect(() => {
        if (teamDetails && teamDetails.data && teamDetails.data.teams_by_pk)
            setTeam(teamDetails.data.teams_by_pk)
    }, [teamDetails])

    async function findManagers(query: string, type: 'query' | 'id' = 'query') {
        if (productForm.approver_id === 0 && query.length <= 0)
            return setManagers([])
        const managers = await searchManagers({
            variables: {
                query: type === 'query' ? `%${query}%` : query,
                id: type === 'id' ? query : 0,
            },
        })
        setManagers(managers.data.organisation_members)
        return managers.data.organisation_members
    }

    useEffect(() => {
        if (managers) {
            const managerId = managers.find(
                (user: any) => user.id === productForm.approver_id
            )
            if (managerId) {
                setEmail(managerId.user.email)
            }
        }
    }, [managers])

    function createProduct() {
        if (productForm.name.trim().length <= 0) {
            return toast('Please enter product name', {
                className: 'custom-toaster toaster-error',
            })
        }

        if (productForm.description.length <= 0) {
            return toast('Please enter description', {
                className: 'custom-toaster toaster-error',
            })
        }
        if (productForm.due_date.length <= 0) {
            return toast('Please select due date', {
                className: 'custom-toaster toaster-error',
            })
        }
        if (!team) {
            return toast('Please select a team', {
                className: 'custom-toaster toaster-error',
            })
        }
        if (productForm.tags.length <= 0) {
            return toast('Tags not added.', {
                className: 'custom-toaster toaster-error',
            })
        }
        if (productForm.approver_id === 0) {
            return toast('Please select an approver', {
                className: 'custom-toaster toaster-error',
            })
        }
        if (productForm.approver_due_date.toString().length <= 0) {
            return toast('Please select approver due date', {
                className: 'custom-toaster toaster-error',
            })
        }

        if (
            team &&
            productForm.name.trim().length > 0 &&
            productForm.approver_due_date.length > 0 &&
            productForm.due_date.length > 0 &&
            productForm.approver_id !== 0
        ) {
            setTeamMembers({ payload: team.team_members })
            // setStep({ payload: 'NEXT' })
            _createProduct()
        }
    }
    const _createProduct = () => {
        try {
            const payload: any = {
                ...productForm,
                due_date: new Date(
                    new Date(productForm.due_date).setUTCHours(23, 59, 59)
                ).toISOString(),
                todos: {
                    data: productForm.todos.data.map((todo) => ({
                        name: todo.name,
                        sub_todos: {
                            data: todo.sub_todos.data.map((subTodo) => ({
                                heading: subTodo.heading,
                                description: subTodo.description,
                                other_data: {
                                    file: [],
                                    type: subTodo.type,
                                    color: subTodo.color,
                                },
                            })),
                        },
                        user_id: todo.assignee_id || null,
                        due_date: todo.dueDate
                            ? new Date(
                                  new Date(todo.dueDate).setUTCHours(23, 59, 59)
                              ).toISOString()
                            : null,
                    })),
                },
                tags: productForm.tags.map((tag: any) => ({
                    tag: tag.name,
                    color: tag.color || '#FFE395',
                })),
            }

            CreateProductMutation({
                variables: {
                    object: payload,
                },
                onCompleted: async (data) => {
                    setFormLoading(true)
                    const _productData = data.insert_products_one
                    updateTags(
                        getTags,
                        productForm.tags,
                        {
                            AddNewTagWithRelationMutation,
                            AddTagWithRelationMutation,
                        },
                        {},
                        undefined,
                        _productData.id,
                        'product'
                    )
                    await collectionByID.refetch({
                        id: collectionByID.data.collections_by_pk.id,
                    })
                    navigate(
                        `/collections/product/${_productData.id}?name=${_productData.name}&collection=${collectionByID.data.collections_by_pk.id}`
                    )
                    resetForm()
                    toast('Product created Successfully', {
                        className: 'custom-toaster toaster-success',
                    })
                    setFormLoading(false)
                },
                onError: (e) => {
                    console.log(e)
                    setFormLoading(false)
                    toast('Failed to create product', {
                        className: 'custom-toaster toaster-error',
                    })
                },
            })
        } catch (error) {
            console.log(error)
            setFormLoading(false)
            toast('Failed to create product', {
                className: 'custom-toaster toaster-error',
            })
        }
    }

    const updateProduct = () => {
        const productID = params.productId
        if (!productID) return
        UpdateProductMutation({
            variables: {
                id: productID,
                approver_id: productForm.approver_id,
                approver_due_date: new Date(
                    new Date(productForm.approver_due_date).setUTCHours(
                        23,
                        59,
                        59,
                        999
                    )
                ).toISOString(),
                description: productForm.description,
                tags: productForm.tags.map((tag: any) => ({
                    tag: tag.name,
                    color: tag.color || '#FFE395',
                })),
                due_date: new Date(
                    new Date(productForm.due_date).setUTCHours(23, 59, 59, 999)
                ).toISOString(),
                name: productForm.name,
            },
            onCompleted: (data) => {
                updateTags(
                    getTags,
                    productForm.tags,
                    {
                        AddNewTagWithRelationMutation,
                        AddTagWithRelationMutation,
                    },
                    {},
                    undefined,
                    Number(productID),
                    'product'
                )
                navigate(
                    `/collections/product/${productID}?name=${data.update_products_by_pk.name}&collectionId=${collectionByID.data.collections_by_pk.id}`
                )
            },
        })
    }

    return (
        <>
            <form onSubmit={(e) => e.preventDefault()}>
                <Box
                    width="100%"
                    height="auto"
                    display="flex"
                    flexDirection="column"
                    justifyContent="space-between"
                >
                    {/* Form */}
                    <Box>
                        <Box
                            display="flex"
                            gridColumnGap="12px"
                            justifyContent="center"
                            px="32px"
                            flexDirection="column"
                        >
                            {/* <Text
                                fontFamily="Rubik"
                                fontSize="12px"
                                color="textSecondary"
                                mb="8px"
                                mt="0px"
                            >
                                STEP {step} OF {stepLimit}{' '}
                            </Text> */}
                            <Text
                                fontFamily="Rubik"
                                fontSize="20px"
                                color="primary"
                                position="relative"
                                mt="0px"
                            >
                                {' '}
                                Add Product Details
                            </Text>
                        </Box>
                        <DivisionLine width={1} color="gray250" />
                    </Box>
                    <Box px="32px">
                        <Box display="flex" py="12px" gridColumnGap="12px">
                            <ProductNameIco />
                            <Input
                                autoComplete="false"
                                id="product_form_name"
                                bg="white"
                                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                    updateProductForm({
                                        payload: e.target.value,
                                        action: 'name',
                                    })
                                }
                                value={productForm.name}
                                color="primary"
                                border="none"
                                fontSize="20px"
                                placeholder="Name the Product"
                                className="placeholder-light"
                            ></Input>
                        </Box>
                        <Box
                            mt="12px"
                            display="flex"
                            alignItems="center"
                            gridColumnGap="12px"
                        >
                            <TagsIco />
                            <TagAutoCommplete
                                storeDispatch={addTags}
                                tags={productForm.tags || []}
                            />
                        </Box>
                        <Box
                            mt="16px"
                            display="flex"
                            alignItems="flex-start"
                            gridColumnGap="12px"
                        >
                            <DescriptionIco />
                            <Box
                                display="flex"
                                mt="2px"
                                flexDirection="column"
                                justifyContent="center"
                            >
                                <Text
                                    mt="16px"
                                    fontSize="16px"
                                    my="0px"
                                    fontFamily="Rubik"
                                    color="textSecondaryDarkBlue"
                                >
                                    Description
                                </Text>
                                <TextArea
                                    onChange={(
                                        e: ChangeEvent<HTMLTextAreaElement>
                                    ) =>
                                        updateProductForm({
                                            payload: e.target.value,
                                            action: 'description',
                                        })
                                    }
                                    cols={90}
                                    rows={5}
                                    fontFamily="Rubik"
                                    bg="white"
                                    width="100%"
                                    mt="8px"
                                    color="primary"
                                    border="none"
                                    fontSize="14px"
                                    placeholder="Add to-do description"
                                    className="placeholder-light"
                                    value={productForm.description}
                                ></TextArea>
                            </Box>
                        </Box>
                        <Box
                            position="relative"
                            display="flex"
                            alignItems="center"
                            gridColumnGap="12px"
                            ref={dueDateCalendarRef}
                            onClick={() =>
                                setIsCalendar({
                                    isDueDate: true,
                                    isApproversDueDate: false,
                                })
                            }
                        >
                            <CalendarIco />

                            <Input
                                autoComplete="false"
                                id="product_form_due_date"
                                onClick={() =>
                                    setIsCalendar({
                                        isDueDate: true,
                                        isApproversDueDate: false,
                                    })
                                }
                                bg="white"
                                value={
                                    productForm.due_date
                                        ? getFormattedDate(productForm.due_date)
                                        : ''
                                }
                                color="primary"
                                border="none"
                                fontSize="16px"
                                placeholder="Add Due Date"
                                onChange={() => debugLog()}
                                className="placeholder-light"
                            ></Input>
                            <VCalendar
                                ref={dueDateCalendarRef}
                                disableCallback={() =>
                                    setIsCalendar({
                                        isDueDate: false,
                                        isApproversDueDate: false,
                                    })
                                }
                                isCalendarVisible={isCalendar.isDueDate}
                                setDate={setDate}
                                date={date}
                                top="28px"
                                left="32px"
                                maxDate={
                                    new Date(
                                        new Date(
                                            collection?.due_date
                                        ).setUTCHours(23, 59, 59, 999)
                                    )
                                }
                            ></VCalendar>
                        </Box>
                        <Box
                            mt="16px"
                            display="grid"
                            py="2px"
                            width="35%"
                            gridTemplateColumns="1fr 1fr"
                            justifyContent="space-between"
                        >
                            <Box
                                display="flex"
                                position="relative"
                                alignItems="center"
                                gridColumnGap="12px"
                            >
                                <TeamIco />
                                {/* <Input autoComplete='false' required bg='white' onClick={()=>setIsTeamDropDownActive(true)} onChange={(e:ChangeEvent<HTMLInputElement>)=>handleChange(e.target.value,"TEAM")} color='primary' border='none' fontSize='16px' placeholder='Add Team (Optional)' className="placeholder-light dropdown__focus"></Input> */}
                                <Box
                                    fontFamily="Rubik"
                                    color="primary"
                                    border="none"
                                    fontSize="16px"
                                    className="placeholder-light"
                                    id="dropdown__focus"
                                >
                                    {team && (
                                        <Box
                                            display="flex"
                                            position="relative"
                                            gridColumnGap="12px"
                                            alignItems="center"
                                        >
                                            <Text
                                                my="0px"
                                                fontSize="16px"
                                                fontFamily="Rubik"
                                            >
                                                {team.name}
                                            </Text>
                                            <StackedImage
                                                spacing={14}
                                                size="24px"
                                                fontSize="12px"
                                                list={team.team_members.map(
                                                    (member: any) => ({
                                                        src: member.user
                                                            .other_data
                                                            .profile_pic
                                                            ? getImageUrl(
                                                                  member.user
                                                                      .other_data
                                                                      .profile_pic
                                                              )
                                                            : '' || '',
                                                        name: member.user.name,
                                                    })
                                                )}
                                            />
                                        </Box>
                                    )}
                                    {!team && (
                                        <Text
                                            my="0px"
                                            color="textSecondaryDarkBlue"
                                            fontSize="16px"
                                            fontFamily="Rubik"
                                        >
                                            Add Team
                                        </Text>
                                    )}
                                </Box>
                            </Box>
                        </Box>
                        <Box
                            display="flex"
                            position="relative"
                            alignItems="center"
                            gridColumnGap="12px"
                            mt="16px"
                        >
                            <CircleCheck />
                            <Text
                                mt="16px"
                                color="primary"
                                fontSize="16px"
                                my="0px"
                                fontFamily="Rubik"
                            >
                                Product Approver
                            </Text>
                        </Box>
                        <Box
                            mt="16px"
                            ml="16px"
                            mb="16px"
                            display="grid"
                            py="2px"
                            width="35%"
                            gridTemplateColumns="1fr 1fr"
                            justifyContent="space-between"
                        >
                            <Box
                                position="relative"
                                display="flex"
                                alignItems="center"
                                gridColumnGap="12px"
                            >
                                <PersonIco />
                                {!isManagerSelected && (
                                    <Input
                                        autoComplete="false"
                                        id="product_form_approver_email"
                                        bg="white"
                                        onChange={(
                                            e: ChangeEvent<HTMLInputElement>
                                        ) => {
                                            if (!isManagerDropdown)
                                                setIsManagerDropdown(true)
                                            setQuery(e.target.value)
                                            getManagersDebounce(e.target.value)
                                            setEmail(e.target.value)
                                        }}
                                        color="primary"
                                        border="none"
                                        fontSize="16px"
                                        placeholder="Add Approver"
                                        className="placeholder-light"
                                        value={email}
                                    ></Input>
                                )}
                                {isManagerSelected && manager && (
                                    <Box
                                        display="flex"
                                        gridColumnGap="12px"
                                        alignItems="center"
                                    >
                                        <ProfileImg
                                            size="20px"
                                            name={manager.user.name}
                                            src={getImageUrl(
                                                manager.user.other_data
                                                    ?.profile_pic
                                            )}
                                        />
                                        <Text my="0px" color="primary">
                                            {manager.user.name}
                                        </Text>
                                        <Button
                                            border="none"
                                            borderRadius="50%"
                                            bg="transparent"
                                            onClick={() => {
                                                setQuery('')
                                                setManager(null)
                                                setIsManagerSelected(false)
                                                updateProductForm({
                                                    action: 'approver_id',
                                                    payload: 0,
                                                })
                                            }}
                                            // bg="#778CA2"
                                            display="flex"
                                            justifyContent="center"
                                            alignItems="center"
                                            // width="24px"
                                            // height="24px"
                                            // ml="8px"
                                            type="button"
                                        >
                                            <FilledCloseIco />
                                        </Button>
                                    </Box>
                                )}
                                {isManagerDropdown && (
                                    <Users
                                        users={managers}
                                        onClickUser={(manager: ManagerUser) => {
                                            updateProductForm({
                                                payload: manager.user.id,
                                                action: 'approver_id',
                                            })
                                            setIsManagerDropdown(false)
                                            setManager(manager)
                                            setIsManagerSelected(true)
                                        }}
                                    />
                                )}
                                {/* <PredectiveOutput
                                    left="32px"
                                    top="24px"
                                    showRecentSearches={false}
                                    text={query}
                                    onClick={(user: any) => {
                                        updateProductForm({
                                            payload: user.id,
                                            action: 'approver_id',
                                        })
                                        setQuery(user.email)
                                    }}
                                    dataList={managers}
                                    objectKey="email"
                                ></PredectiveOutput> */}
                            </Box>
                            <Box
                                position="relative"
                                ref={approverCalendarRef}
                                display="flex"
                                alignItems="center"
                                gridColumnGap="12px"
                                onClick={() =>
                                    setIsCalendar({
                                        isDueDate: false,
                                        isApproversDueDate: true,
                                    })
                                }
                            >
                                <CalendarIco />
                                <Input
                                    autoComplete="false"
                                    id="product_form_approver_due_date"
                                    onClick={() =>
                                        setIsCalendar({
                                            isDueDate: false,
                                            isApproversDueDate: true,
                                        })
                                    }
                                    bg="white"
                                    value={
                                        productForm.approver_due_date
                                            ? getFormattedDate(
                                                  productForm.approver_due_date
                                              )
                                            : ''
                                    }
                                    color="primary"
                                    border="none"
                                    fontSize="16px"
                                    placeholder="Approver Due Date"
                                    onChange={() => debugLog()}
                                    className="placeholder-light"
                                ></Input>

                                <VCalendar
                                    ref={approverCalendarRef}
                                    disableCallback={() =>
                                        setIsCalendar({
                                            isDueDate: false,
                                            isApproversDueDate: false,
                                        })
                                    }
                                    isCalendarVisible={
                                        isCalendar.isApproversDueDate
                                    }
                                    setDate={setApproverDueDate}
                                    date={approverDueDate}
                                    top="-350px"
                                    left="32px"
                                    maxDate={
                                        productForm.due_date.length <= 0
                                            ? new Date(Date.now() - 86400000)
                                            : new Date(productForm.due_date)
                                    }
                                ></VCalendar>
                            </Box>
                        </Box>
                    </Box>
                    {/* Line */}

                    {/* Actions */}
                    <Box
                        border="solid"
                        py="24px"
                        px="32px"
                        display="flex"
                        gridColumnGap="16px"
                        alignSelf="flex-end"
                        width="100%"
                        borderWidth={1}
                        borderColor="gray250"
                        borderBottom="none"
                        borderLeft="none"
                        borderRight="none"
                    >
                        <ColoredButton
                            variant="primary"
                            type="button"
                            onClick={
                                location.pathname.includes('edit-product')
                                    ? updateProduct
                                    : createProduct
                            }
                        >
                            {location.pathname.includes('edit-product')
                                ? 'Update'
                                : 'Save'}
                        </ColoredButton>
                        <ColoredButton
                            variant="secondary"
                            type="button"
                            onClick={() => navigate(-1)}
                        >
                            Cancel
                        </ColoredButton>
                    </Box>
                </Box>
            </form>
        </>
    )
}

export const Users = ({
    users,
    onClickUser,
}: {
    users: ManagerUser[]
    onClickUser: (user: ManagerUser) => void
}) => {
    getImageUrl
    return users.length ? (
        <Box
            width={'max-content'}
            boxShadow="0px 2px 12px rgba(153, 155, 168, 0.25)"
            display="flex"
            maxHeight="250px"
            flexDirection="column"
            position="absolute"
            py="8px"
            zIndex={10}
            overflowX="hidden"
            bg="white"
            // px="12px"
            left="32px"
            top="24px"
            borderRadius="4px"
        >
            <Box
                px="12px"
                overflowY={users.length > 5 ? 'scroll' : 'visible'}
                width="100%"
            >
                {users.map((user) => {
                    return (
                        <Box
                            onClick={(e) => {
                                e.preventDefault()
                                e.stopPropagation()
                                onClickUser(user)
                            }}
                            className="cursor-pointer"
                            display="flex"
                            alignItems="center"
                            gridColumnGap="12px"
                        >
                            <ProfileImg
                                fontSize="14px"
                                size="24px"
                                src={getImageUrl(
                                    user.user?.other_data?.profile_pic
                                )}
                                name={user?.user?.name}
                            />
                            <Text my="8px"> {user?.user?.name}</Text>
                        </Box>
                    )
                })}
            </Box>
        </Box>
    ) : (
        <Box
            width={'max-content'}
            boxShadow="0px 2px 12px rgba(153, 155, 168, 0.25)"
            display="flex"
            maxHeight="250px"
            flexDirection="column"
            position="absolute"
            py="8px"
            zIndex={10}
            overflowX="hidden"
            bg="white"
            px="12px"
            left="32px"
            top="24px"
            borderRadius="4px"
        >
            <Text my="0px" fontSize="14px" color="textSecondary">
                No results found
            </Text>
        </Box>
    )
}
