import type { FetchCollectionByIdQuery } from 'src/generated/graphql'

export const SortObjects: Record<'due_date' | 'name' | 'created_at', object> = {
    due_date: {
        due_date: 'asc',
    },
    name: {
        name: 'asc',
    },
    created_at: {
        created_at: 'desc',
    },
}

export const calculateStatusCounts = (
    products:
        | NonNullable<FetchCollectionByIdQuery['collections_by_pk']>['products']
        | undefined
        | null
) => {
    const initialStatusCounts = {
        new: 0,
        inProgress: 0,
        inReview: 0,
        requestedForResubmission: 0,
        complete: 0,
    }
    if (!products) return initialStatusCounts
    const statuses = {
        new: 'new',
        inProgress: 'inProgress',
        inReview: 'inReview',
        requestedForResubmission: 'requestedForResubmission',
        complete: 'complete',
    }

    return products.reduce(
        (
            acc: {
                new: number
                inProgress: number
                inReview: number
                requestedForResubmission: number
                complete: number
            },
            product
        ) => {
            product.product_variants.forEach((variant) => {
                if (variant.status === statuses.new) acc.new += 1
                else if (variant.status === statuses.inProgress)
                    acc.inProgress += 1
                else if (variant.status === statuses.inReview) acc.inReview += 1
                else if (variant.status === statuses.requestedForResubmission)
                    acc.requestedForResubmission += 1
                else if (variant.status === statuses.complete) acc.complete += 1
            })
            return acc
        },
        initialStatusCounts
    )
}
