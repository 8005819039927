import React, { createRef } from 'react'
import {
    Box,
    Text,
    Image as Img,
    Button,
    Input,
    Link,
} from 'src/components/utility'
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material'
import { getLocalImageUrl } from 'src/helpers/get-image-url'
import { useConfiguratorContext } from './configurator.context'
import { DeleteIco, StrokedArrow } from 'src/components/svg-icons'
import {
    Download,
    UploadFile,
} from 'src/components/svg-icons/3d-renderer/actions'
import { createMaterial } from './helper/create-material'

export const MapConfigurator = () => {
    const { maps, setMaps, config, setConfig, highlights } =
        useConfiguratorContext()
    const inputRefs = maps.map((map) => createRef())
    const [mapsStatus, setMapsStatus] = React.useState<
        { id: number; isOpen: boolean }[]
    >(
        maps.map((map) => {
            return { id: map.id, isOpen: false }
        })
    )
    function openFileDialogueBox(ref: React.RefObject<HTMLInputElement>) {
        if (ref && ref.current) {
            ref.current.click()
        }
    }

    function updateMaterial(
        path: string,
        type:
            | 'normalMap'
            | 'map'
            | 'displacementMap'
            | 'metalnessMap'
            | 'roughnessMap'
            | 'aoMap'
    ) {
        highlights.map((highlight) => {
            const mesh = highlight.mesh
            if (mesh.material && highlight.isActive) {
                mesh.material[type] = createMaterial(path, {
                    offset: {
                        u: highlight?.mesh?.material[type]?.offset?.x || 0,
                        v: highlight?.mesh?.material[type]?.offset?.y || 0,
                    },
                    scale: {
                        u: highlight?.mesh?.material[type]?.repeat?.x || 0,
                        v: highlight?.mesh?.material[type]?.repeat?.y || 0,
                    },
                }).upscale
            }
        })
    }

    return (
        <Box style={{ zIndex: 10 }}>
            {maps.map((map, key) => {
                return (
                    <>
                        <Accordion
                            style={{
                                marginBottom: '0px',
                                marginTop: '0px',
                                boxShadow: 'none',
                                // padding: '24px 0px',
                            }}
                            onChange={() => {
                                setMapsStatus(
                                    mapsStatus.map((mapStatus) => {
                                        return mapStatus.id === map.id
                                            ? {
                                                  ...mapStatus,
                                                  isOpen: !mapStatus.isOpen,
                                              }
                                            : { ...mapStatus, isOpen: false }
                                    })
                                )
                            }}
                            expanded={mapsStatus[key].isOpen}
                        >
                            <AccordionSummary
                                expandIcon={
                                    <Box className="vertical">
                                        <StrokedArrow />
                                    </Box>
                                }
                            >
                                <Box>
                                    <Text fontSize="12px">{map.type}</Text>
                                </Box>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Box>
                                    <Box>
                                        <Img
                                            className="img-clear"
                                            width="100%"
                                            height="100px"
                                            src={
                                                map.file
                                                    ? typeof map.file !==
                                                      'string'
                                                        ? getLocalImageUrl(
                                                              map.file
                                                          )
                                                        : map.file
                                                    : 'https://i0.wp.com/sumac.com.hk/wp-content/uploads/2022/11/placeholder.png?ssl=1'
                                            }
                                        />
                                        <Box
                                            display="flex"
                                            alignItems="center"
                                            gridColumnGap="20px"
                                        >
                                            <Button
                                                p="0px"
                                                bg="transparent"
                                                border="none"
                                                onClick={() => {
                                                    inputRefs[key] &&
                                                        openFileDialogueBox(
                                                            inputRefs[
                                                                key
                                                            ] as React.RefObject<HTMLInputElement>
                                                        )
                                                }}
                                            >
                                                <UploadFile />
                                            </Button>
                                            <Input
                                                autoComplete="false"
                                                type="file"
                                                multiple
                                                onChange={(
                                                    e: React.ChangeEvent<HTMLInputElement>
                                                ) => {
                                                    if (
                                                        e.target.files &&
                                                        e.target.files.length >
                                                            0
                                                    ) {
                                                        const file =
                                                            e.target.files[0]
                                                        updateMaterial(
                                                            getLocalImageUrl(
                                                                file
                                                            ) || '',
                                                            map.code
                                                        )
                                                        setMaps((maps) =>
                                                            maps.map(
                                                                (foundMap) => {
                                                                    return foundMap.id ===
                                                                        map.id
                                                                        ? {
                                                                              ...foundMap,
                                                                              file: file,
                                                                          }
                                                                        : foundMap
                                                                }
                                                            )
                                                        )
                                                    }
                                                }}
                                                onClick={(e: any) => {
                                                    e.target.value = null
                                                }}
                                                display="none"
                                                ref={inputRefs[key]}
                                                bg="none"
                                            />
                                            <Link
                                                href={
                                                    map.file
                                                        ? typeof map.file !==
                                                          'string'
                                                            ? map.file
                                                            : map.file
                                                        : ''
                                                }
                                                download={map.type + '.png'}
                                                bg="white"
                                                // border="none"
                                                style={{
                                                    border: 'none',
                                                    borderRadius: '4px',
                                                    padding: '8px',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    display: 'flex',
                                                }}
                                                className="download-link"
                                            >
                                                <Download />
                                            </Link>
                                            <Button
                                                p="0px"
                                                bg="transparent"
                                                border="none"
                                            >
                                                <DeleteIco />
                                            </Button>
                                        </Box>
                                    </Box>
                                    {map.sliderType && (
                                        <Box
                                            width="100%"
                                            className="intensity-slider-container"
                                            mt="10px"
                                        >
                                            <Box
                                                display="flex"
                                                alignItems="center"
                                                justifyContent="space-between"
                                            >
                                                <Text my="0px" fontSize="12px">
                                                    Intensity %
                                                </Text>
                                                <Input
                                                    type="number"
                                                    value={
                                                        config[
                                                            map.sliderType ===
                                                            'ROUGH_NESS'
                                                                ? 'roughnessIntensity'
                                                                : 'metalnessIntensity'
                                                        ]
                                                    }
                                                    bg="transparent"
                                                    onChange={(e) => {
                                                        if (
                                                            map.sliderType ===
                                                            'ROUGH_NESS'
                                                        ) {
                                                            return setConfig({
                                                                ...config,
                                                                roughnessIntensity:
                                                                    Number(
                                                                        e.target
                                                                            .value
                                                                    ),
                                                            })
                                                        } else if (
                                                            map.sliderType ===
                                                            'METAL_NESS'
                                                        ) {
                                                            return setConfig({
                                                                ...config,
                                                                metalnessIntensity:
                                                                    Number(
                                                                        e.target
                                                                            .value
                                                                    ),
                                                            })
                                                        }
                                                    }}
                                                    border="solid"
                                                    borderWidth={1}
                                                    borderColor="secondaryLighterBlue"
                                                    borderRadius="4px"
                                                    width="71px"
                                                    height="32px"
                                                    max="100"
                                                />
                                            </Box>
                                            <Input
                                                mt="12px"
                                                className="intensity-slider"
                                                width="100%"
                                                value={
                                                    config[
                                                        map.sliderType ===
                                                        'ROUGH_NESS'
                                                            ? 'roughnessIntensity'
                                                            : 'metalnessIntensity'
                                                    ]
                                                }
                                                onChange={(e) => {
                                                    if (
                                                        map.sliderType ===
                                                        'ROUGH_NESS'
                                                    ) {
                                                        setConfig({
                                                            ...config,
                                                            roughnessIntensity:
                                                                Number(
                                                                    e.target
                                                                        .value
                                                                ),
                                                        })
                                                    } else if (
                                                        map.sliderType ===
                                                        'METAL_NESS'
                                                    ) {
                                                        setConfig({
                                                            ...config,
                                                            metalnessIntensity:
                                                                Number(
                                                                    e.target
                                                                        .value
                                                                ),
                                                        })
                                                    }
                                                }}
                                                type="range"
                                                min="0"
                                                step="0.1"
                                                max="100"
                                                bg="trasparent"
                                            ></Input>
                                        </Box>
                                    )}
                                </Box>
                            </AccordionDetails>
                        </Accordion>
                    </>
                )
            })}
        </Box>
    )
}
