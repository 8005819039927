import { format, formatDistance, isToday, subDays } from 'date-fns'
import React, { useState } from 'react'
import { ProfileImg } from 'src/components/profile-img/profile-img'
import { Box, Button, Text } from 'src/components/utility'
import { commentParser } from 'src/helpers'
import { Dots } from '../svg-icons'
import CommentMenuDropdown from './comment-menu-dropdown'

function CommentCard({
    id,
    commenter,
    comment,
    createdAt,
    onDelete,
    isAllowedToDelete,
}: {
    id: number
    commenter: { name: string; img: string }
    comment: string
    createdAt: Date
    onDelete: () => void
    isAllowedToDelete: boolean
}) {
    const [isCommentMenuVisible, setIsCommentMenuVisible] = useState(false)

    const formattedCreatedAt = (createdAt: string) => {
        const commentDate = new Date(createdAt)
        if (isToday(commentDate)) {
            let distance = formatDistance(subDays(commentDate, 0), new Date(), {
                addSuffix: true,
            })
            if (distance.includes('less than a minute ago')) {
                return 'Just now'
            }
            distance = distance
                .replace('minutes', 'mins')
                .replace('hours', 'hrs')
            return distance.includes('min') || distance.includes('hr')
                ? distance
                : format(commentDate, 'hh:mm a')
        } else {
            return format(commentDate, 'MM/dd/yy, hh:mm a')
        }
    }

    return (
        <Box
            borderRadius="4px"
            border="1px solid #B8B8B8"
            px="8px"
            py="12px"
            display="flex"
            flexDirection="column"
            gridGap="8px"
            bg="white"
            id={`comment-card-${id}`}
        >
            <Box display="flex" justifyContent="space-between">
                <Box display="flex" gridGap="8px" alignItems="center">
                    <ProfileImg
                        size="20px"
                        name={commenter.name}
                        src={commenter.img}
                    />
                    <Text my="0px" fontSize="14px" color="primary">
                        {commenter.name}
                    </Text>
                </Box>
                <Box display="flex" gridGap="8px" alignItems="center">
                    <Text my="0px" color="textSecondary" fontSize="14px">
                        {formattedCreatedAt(createdAt.toString())}
                    </Text>
                    {isAllowedToDelete && (
                        <Box position="relative">
                            <Button
                                onClick={() =>
                                    setIsCommentMenuVisible(
                                        !isCommentMenuVisible
                                    )
                                }
                                bg="transparent"
                                border="none"
                                fontStyle="Rubik"
                                fontSize="12px"
                                color="links"
                                position="relative"
                                width="max-content"
                                id={`comment-menu-button-${id}`}
                            >
                                <Dots active={isCommentMenuVisible} />
                            </Button>
                            {isCommentMenuVisible && (
                                <CommentMenuDropdown
                                    active={isCommentMenuVisible}
                                    setIsDropdownActive={
                                        setIsCommentMenuVisible
                                    }
                                    onDelete={onDelete}
                                    commentId={id}
                                />
                            )}
                        </Box>
                    )}
                </Box>
            </Box>
            <Text my="0px" color="primary" style={{ wordBreak: 'break-word' }}>
                {commentParser(comment).parsed}
            </Text>
        </Box>
    )
}

export default CommentCard
