import type { User } from 'src/services/graphql/query/@types/collections'
import create from 'zustand'

type Status = 'inactive' | 'connecting' | 'active' | 'error'
const status: Status = 'inactive'

export type Activity = {
    id: number
    comment: string
    entity_name: string
    entity_id: string
    entity_info: string
    user_id: string
    created_at: string
    user: User
}

type Store = {
    activities: any[]
    status: Status
    addActivities: (newActivities: any[]) => void
    addActivity: (newActivity: any) => void
    setActivities: (activities: any[]) => void
    clearActivities: () => void
    setStatus: (status: Status) => void
}

export const useActivitiesStore = create<Store>((set: Function) => ({
    activities: [],
    status,
    addActivities: (newActivities) => {
        set((state: Store) => ({
            activities: [...newActivities, ...state.activities],
        }))
    },
    addActivity: (newActivity) => {
        set((state: Store) => ({
            activities: [...newActivity, ...state.activities],
        }))
    },
    setActivities: (activities) => set({ activities }),
    clearActivities: () => set({ activities: [] }),
    setStatus: (status) => set({ status }),
}))
