import React from 'react'

function LinkIco() {
    return (
        <svg
            width="25"
            height="24"
            viewBox="0 0 25 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M11.4222 16H8.02222C6.91555 16 5.97222 15.61 5.19222 14.83C4.41222 14.05 4.02222 13.1067 4.02222 12C4.02222 10.8933 4.41222 9.95 5.19222 9.17C5.97222 8.39 6.91555 8 8.02222 8H11.4222V9.2H8.02222C7.24444 9.2 6.58333 9.47177 6.03888 10.0153C5.49444 10.5588 5.22222 11.2188 5.22222 11.9953C5.22222 12.7718 5.49444 13.4333 6.03888 13.98C6.58333 14.5267 7.24444 14.8 8.02222 14.8H11.4222V16ZM8.92222 12.6V11.4H15.1222V12.6H8.92222ZM12.6222 16V14.8H16.0222C16.8 14.8 17.4611 14.5282 18.0056 13.9847C18.55 13.4412 18.8222 12.7812 18.8222 12.0047C18.8222 11.2282 18.55 10.5667 18.0056 10.02C17.4611 9.47333 16.8 9.2 16.0222 9.2H12.6222V8H16.0222C17.1289 8 18.0722 8.39 18.8522 9.17C19.6322 9.95 20.0222 10.8933 20.0222 12C20.0222 13.1067 19.6322 14.05 18.8522 14.83C18.0722 15.61 17.1289 16 16.0222 16H12.6222Z"
                fill="#778CA2"
            />
        </svg>
    )
}

export default LinkIco
