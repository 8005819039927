import React, { useState } from 'react'
import RecentActivitiesDropdown from 'src/components-v2/recent-activities-dropdown/recent-activities-dropdown'
import type { RecentActivityFilters } from 'src/components-v2/recent-activities-dropdown/recent-activities.types'
import { RecentActivityIco } from 'src/components/svg-icons'
import { Box, Button, Text } from 'src/components/utility'
import { useActivitiesStore } from 'src/store/hooks/useActivitiesStore'
import { useCommentsStore } from 'src/store/hooks/useCommentsStore'

function CollectionActivities({ collection }: { collection: any }) {
    const [showRecentActivity, setShowRecentActivity] = useState(false)
    const [activeFilter, setActiveFilter] =
        useState<RecentActivityFilters>('default')

    const { activities, status } = useActivitiesStore()
    const { sendMessage } = useCommentsStore()

    const collectionActivities =
        collection &&
        activities.filter(
            (activity: any) =>
                activity.entity_id === String(collection.id) &&
                activity.entity_name === 'collections'
        )

    // useEffect(() => {
    //     sendMessage(
    //         `{
    //         "type": "activities",
    //         "payload": {
    //             "operation": "GET",
    //             "variables": {
    //             "eId": "${collection.id}",
    //             "eName": "collections"
    //             }
    //         }
    //         }`
    //     )
    // }, [])

    return (
        <Box position="relative">
            {collectionActivities?.length > 0 && (
                <Box
                    bg="primary"
                    width="18px"
                    height="18px"
                    borderRadius="50%"
                    position="absolute"
                    top="-14px"
                    right="0"
                    color="white"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                >
                    <Text fontSize="10px" my="0px">
                        {collectionActivities.length}
                    </Text>
                </Box>
            )}
            <Button
                onClick={() => setShowRecentActivity(!showRecentActivity)}
                bg="transparent"
                border="none"
                fontStyle="Rubik"
                fontSize="12px"
                color="links"
                position="relative"
                width="max-content"
                id="recent-activity-button"
            >
                <RecentActivityIco active={showRecentActivity} />
            </Button>
            {showRecentActivity && (
                <RecentActivitiesDropdown
                    entityTitle={collection.name}
                    recentActivities={collectionActivities}
                    setShowRecentActivity={setShowRecentActivity}
                    activeFilter={activeFilter}
                    setActiveFilter={setActiveFilter}
                    isLoading={status === 'connecting'}
                />
            )}
        </Box>
    )
}

export default CollectionActivities
