import * as React from 'react'
import Artwork from './artwork/artwork'
import Color from './color'
import Fabric from './fabric/fabric'
import './panel.styles.css'
import Graphic from './graphic/graphic'

export interface IPanelFunctionsProps {
    type: 'artwork' | 'fabric' | 'color' | 'graphic'
}

export default function PanelFunctions(props: IPanelFunctionsProps) {
    const { type } = props
    switch (type) {
        case 'artwork':
            return <Artwork />
        case 'color':
            return <Color />
        case 'fabric':
            return <Fabric />
        case 'graphic':
            return <Graphic />
        default:
            return <></>
    }
}
