import type { InternalRefetchQueriesInclude } from '@apollo/client'
import { useMutation } from '@apollo/client'
import { useState } from 'react'
import {
    UpdateProductVariant,
    UpdateProductVariantDueDate,
    UpdateProductVariantName,
    UpdateProductVariantStatus,
} from 'src/services/graphql/entities/product/product.mutations'
import { debounce } from 'src/helpers'
import { DeleteProduct } from 'src/services'

/**
 *
 * --- IMPORTANT ---
 *
 * Remove all the update product functions once the 'product_variant' -> 'product' mapper function is added to the backend.
 * It is an anti-pattern that is implemented now and is discouraged to continue with it.
 * If you think the mapper is implemented and F.E is still updating the product table, stop everything you doing right now and fix it. (Not a big deal but a message to myself)
 *
 */

function useUpdateProduct(queries: string[] = []) {
    const [updateProductVariantMutation] = useMutation(UpdateProductVariant)
    const [updateProductVariantStatusMutation] = useMutation(
        UpdateProductVariantStatus
    )
    const [updateProductVariantNameMutation] = useMutation(
        UpdateProductVariantName
    )
    const [updateProductVariantDueDateMutation] = useMutation(
        UpdateProductVariantDueDate
    )

    const [timeOutRef, setTimeOutRef] =
        useState<ReturnType<typeof setTimeout>>()

    function updateProductVariantHandler({
        productId,
        payload,
        onComplete,
        refetchQueries,
    }: {
        productId: number
        payload: object
        onComplete?: () => void
        refetchQueries?: InternalRefetchQueriesInclude
    }) {
        updateProductVariantMutation({
            variables: {
                id: productId,
                object: payload,
            },
            refetchQueries,
            onCompleted: () => {
                if (onComplete) onComplete()
            },
        })
    }

    const debouncedUpdateProductVariantHandler = debounce(
        updateProductVariantHandler,
        1500,
        setTimeOutRef,
        timeOutRef
    )

    function updateProductVariantStatus({
        id,
        status,
        onCompleted,
    }: {
        id: number
        status: string
        onCompleted?: () => void
    }) {
        updateProductVariantStatusMutation({
            variables: {
                id,
                status,
            },
            optimisticResponse: {
                update_product_variants_by_pk: {
                    id,
                    status,
                    __typename: 'product_variants',
                },
            },
            refetchQueries: [
                'GetProducts',
                'getDashboardProduct',
                'GetProductsForCollection',
                'GetProductVariantsByProductId',
                'getProduct',
                'fetchCollectionById',
                ...queries,
            ],
            onCompleted,
        })
    }

    function updateProductVariantName({
        id,
        name,
    }: {
        id: number
        name: string
    }) {
        updateProductVariantNameMutation({
            variables: {
                id,
                name,
            },
            optimisticResponse: {
                update_product_variants_by_pk: {
                    id,
                    name,
                    __typename: 'product_variants',
                },
            },
            refetchQueries: [
                'GetProducts',
                'getDashboardProduct',
                'GetProductsForCollection',
                'GetProductVariantsByProductId',
                'getProduct',
                'fetchCollectionById',
                ...queries,
            ],
        })
    }

    function updateProductVariantDueDate({
        id,
        dueDate,
        onCompleted,
    }: {
        id: number
        dueDate: string
        onCompleted?: () => void
    }) {
        updateProductVariantDueDateMutation({
            variables: {
                id,
                due_date: dueDate,
            },
            optimisticResponse: {
                update_product_variants_by_pk: {
                    id,
                    due_date: dueDate,
                    __typename: 'product_variants',
                },
            },
            refetchQueries: [
                'GetProducts',
                'getDashboardProduct',
                'GetProductsForCollection',
                'GetProductVariantsByProductId',
                'getProduct',
                ...queries,
            ],
            onCompleted,
        })
    }

    return {
        updateProductVariantHandler,
        debouncedUpdateProductVariantHandler,
        updateProductVariantStatus,
        updateProductVariantDueDate,
        updateProductVariantName,
    }
}

export function useDeleteProduct() {
    const [deleteProductMutation, _] = useMutation(DeleteProduct)

    function deleteProduct(
        productId: number,
        refetchQueries?: InternalRefetchQueriesInclude,
        onComplete?: () => void
    ) {
        deleteProductMutation({
            variables: {
                id: productId,
                deleted_at: new Date().toISOString(),
            },
            refetchQueries,
            onCompleted: () => {
                if (onComplete) onComplete()
            },
        })
    }
    return { deleteProduct }
}

export default useUpdateProduct
