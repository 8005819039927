import type { SetStateAction } from 'react'
import { useRef } from 'react'
import { uuid } from 'src/helpers'
import { FILE_TYPES, SUPPORT_FILE_TYPES } from 'src/helpers/enums'
import { createRedirectionUrl } from 'src/helpers/create-redirection-url'
import { Box, Button, Input, Text } from 'src/components/utility'
import { FileDisplay } from 'src/components/file-display/file-display'

export function DragDropArea({
    setFileModal,
    updateFileCb,
    fileModal,
    height = '150px',
    dontAddScroll = false,
    multiple = true,
}: {
    setFileModal?: React.Dispatch<
        SetStateAction<{
            isFileModal: boolean
            files: { name: string; path: string; blob: Blob; id: string }[]
        }>
    >
    updateFileCb?: (x: {
        isFileModal: boolean
        files: { name: string; path: string; blob: Blob; id: string }[]
    }) => void
    multiple?: boolean
    fileModal: {
        isFileModal: boolean
        files: { name: string; path: string; blob: Blob; id: string }[]
    }
    height?: string
    dontAddScroll?: boolean
}) {
    const inputRef = useRef<HTMLInputElement | null>(null)
    function checkFileType(name: string) {
        const nameSplit = name.split('.')
        const fileType = nameSplit[nameSplit.length - 1].toLowerCase()
        if (
            SUPPORT_FILE_TYPES.includes(fileType) &&
            window.location.pathname === '/support'
        ) {
            return true
        } else if (FILE_TYPES.includes(fileType)) {
            return true
        }
        return false
    }

    function onFileDragged(event: React.DragEvent<HTMLDivElement>) {
        event.preventDefault()
        const newFiles: any = []
        for (let i = 0; i < event.dataTransfer.files.length; i++) {
            if (!checkFileType(event.dataTransfer.files[i].name)) {
                continue
            }
            const objURL = URL.createObjectURL(event.dataTransfer.files[i])
            newFiles.push({
                name: event.dataTransfer.files[i].name,
                path: objURL,
                blob: event.dataTransfer.files[i],
                id: uuid(),
            })
        }
        setFileModal &&
            setFileModal((fileModal) => ({
                ...fileModal,
                files: [...fileModal.files, ...newFiles],
            }))
        updateFileCb &&
            updateFileCb({
                ...fileModal,
                files: [...fileModal.files, ...newFiles],
            })
    }
    function onFileInput(files: any) {
        const keys = Object.keys(files)
        keys.forEach((key: any) => {
            if (!checkFileType(files[key].name)) {
                const nameSplit = files[key].name.split('.')
                const fileType = nameSplit[nameSplit.length - 1]
                return
            }
            if (files[key]) {
                const objURL = URL.createObjectURL(files[key])
                setFileModal &&
                    setFileModal((fileModal) => ({
                        ...fileModal,
                        files: [
                            ...fileModal.files,
                            {
                                name: files[key].name,
                                path: objURL,
                                blob: files[key],
                                id: uuid(),
                            },
                        ],
                    }))
                updateFileCb &&
                    updateFileCb({
                        ...fileModal,
                        files: [
                            ...fileModal.files,
                            {
                                name: files[key].name,
                                path: objURL,
                                blob: files[key],
                                id: uuid(),
                            },
                        ],
                    })
            }
        })
    }
    function openFileDialogueBox() {
        if (inputRef && inputRef.current) {
            inputRef.current.click()
        }
    }
    function onDelete(id: string) {
        setFileModal &&
            setFileModal((fileModal) => ({
                ...fileModal,
                files: fileModal.files.filter((file) => file.id !== id),
            }))
        updateFileCb &&
            updateFileCb({
                ...fileModal,
                files: fileModal.files.filter((file) => file.id !== id),
            })
    }

    return (
        <Box
            width="100%"
            height={height}
            overflowY={
                !dontAddScroll && fileModal.files.length > 4
                    ? 'auto'
                    : 'visible'
            }
            mb={
                fileModal.files.length > 0 && fileModal.files.length <= 4
                    ? '24px'
                    : '0px'
            }
            onDragOver={(event) => {
                event.preventDefault()
            }}
            onDrop={(event: React.DragEvent<HTMLDivElement>) =>
                onFileDragged(event)
            }
        >
            <Box
                height={fileModal.files.length > 0 ? '55px' : '100%'}
                position="relative"
                display="flex"
                justifyContent="center"
                alignItems="center"
                className="dashed__border_light"
            >
                <Input
                    autoComplete="false"
                    multiple={multiple}
                    type="file"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        e.stopPropagation()
                        e.target.files && onFileInput(e?.target?.files)
                    }}
                    onClick={(e: any) => {
                        e.target.value = null
                    }}
                    display="none"
                    ref={inputRef}
                    bg="none"
                />
                <Text
                    fontFamily="Rubik"
                    fontSize="16px"
                    color="textSecondaryDarkBlue"
                >
                    Drag files here
                </Text>
                <Text
                    fontFamily="Rubik"
                    mx="12px"
                    fontSize="12px"
                    color="textSecondaryDarkBlue"
                >
                    or
                </Text>
                <Button
                    p="0px"
                    type="button"
                    bg="transparent"
                    border="none"
                    color="links"
                    fontFamily="Rubik"
                    onClick={openFileDialogueBox}
                    fontWeight={500}
                >
                    Choose files to upload
                </Button>
            </Box>

            <Box
                mt="16px"
                display="grid"
                gridRowGap="8px"
                gridTemplateColumns="1fr 1fr 1fr 1fr"
                gridTemplateRows="auto"
                flexWrap="wrap"
                overflowY="auto"
            >
                {fileModal.files.map((files, key: number) => {
                    return (
                        <FileDisplay
                            redirectionUrl={createRedirectionUrl({
                                path: files.name,
                                gid: files.path.replace('/', ''),
                                ref: 'self',
                            })}
                            // redirectionUrl={(files.name.includes('xlsx') || files.name.includes('xlx')) ? `/excel-viewer?path=${files.path.replace('/', '')}` : PdfFileTypes.includes(files.name.split('.')[files.name.split('.').length - 1]) ? `/pdf-viewer/${files.path}` : '/file-preview'}
                            redirect={false}
                            onDelete={() => onDelete(files.id)}
                            key={key}
                            url={files.path}
                            name={files.name}
                            isDeletePermitted
                            openFileInNewTab={true}
                        ></FileDisplay>
                    )
                })}
            </Box>
        </Box>
    )
}
