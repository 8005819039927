import { Button } from 'src/components/utility'
import { DropdownButtonWrapper, Dropdown } from 'src/components'
import type { SetStateAction } from 'react'

export const DropDownActions = ({
    active,
    onDropDownClick,
    setActiveGroup,
    activeGroup,
    setIsDropdownActive,
}: {
    active: Boolean
    activeGroup: string
    onDropDownClick: (value: string) => void
    setActiveGroup: (group: 'collections' | 'products' | 'todos') => void
    setIsDropdownActive: React.Dispatch<SetStateAction<boolean>>
}) => {
    return (
        <Dropdown
            active={active}
            setDropDownDisabled={setIsDropdownActive}
            top="36px"
        >
            <>
                <DropdownButtonWrapper
                    onClick={(x) => {
                        setActiveGroup('collections')
                        onDropDownClick(x)
                    }}
                    value="Collections"
                    width="100%"
                >
                    <Button
                        fontFamily="Rubik"
                        color="primary"
                        bg="transparent"
                        border="none"
                        fontSize="14px"
                        opacity={activeGroup === 'collections' ? '0.5' : '1'}
                    >
                        Collections
                    </Button>
                </DropdownButtonWrapper>
                <DropdownButtonWrapper
                    onClick={(x) => {
                        setActiveGroup('products')
                        onDropDownClick(x)
                    }}
                    value="Products"
                    width="100%"
                >
                    <Button
                        height="100%"
                        fontFamily="Rubik"
                        color="primary"
                        bg="transparent"
                        border="none"
                        fontSize="14px"
                        opacity={activeGroup === 'products' ? '0.5' : '1'}
                    >
                        Products
                    </Button>
                </DropdownButtonWrapper>
                <DropdownButtonWrapper
                    onClick={(x) => {
                        setActiveGroup('todos')
                        onDropDownClick(x)
                    }}
                    value="Todos"
                    width="100%"
                >
                    <Button
                        fontFamily="Rubik"
                        color="primary"
                        bg="transparent"
                        border="none"
                        fontSize="14px"
                        opacity={activeGroup === 'todos' ? '0.5' : '1'}
                    >
                        Todos
                    </Button>
                </DropdownButtonWrapper>
            </>
        </Dropdown>
    )
}
