import * as React from 'react'

export const SendIco = ({ color }: { color?: string }) => (
    <svg width={25} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M5.408 4a1.333 1.333 0 0 0-1.334 1.333v4a1.333 1.333 0 0 0 1.334 1.334h.014L13.408 12l-7.986 1.335-.014-.002a1.333 1.333 0 0 0-1.333 1.333v4a1.333 1.333 0 0 0 1.994 1.157h.001l13.598-7.208.001-.003a.667.667 0 0 0-.001-1.227L6.07 4.177A1.333 1.333 0 0 0 5.408 4Z"
            fill={color || '#C2CFE0'}
        />
    </svg>
)
