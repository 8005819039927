import * as React from 'react'

export const TagsIco = () => (
    <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="m11.271 4-.19.17-6.664 6.665-.417.436.417.435L9.87 17.16l.435.416.436-.416 6.665-6.665.17-.19V4h-6.305Zm.511 1.212h4.582v4.582l-6.059 6.06-4.582-4.583 6.06-6.06Zm6.4.606V7.03h.606v4.942l-5.756 5.718-.757-.758-.853.853 1.174 1.192.436.417.416-.416 6.382-6.306.17-.19V5.819h-1.818Zm-3.635.606a.607.607 0 0 0 0 1.212.607.607 0 0 0 0-1.212Z"
            fill="#778CA2"
        />
    </svg>
)
