import { useEffect, useRef } from 'react'
import { useCommentsStore } from 'src/store/hooks/useCommentsStore'
import { getLocalStorage } from '../localstorage'
import { CONFIG_VARS } from 'src/config/constants.config'
import { useActivitiesStore } from 'src/store/hooks/useActivitiesStore'

export const useCommentsAndActivitesWebSocket = () => {
    const {
        addComments,
        addAnnotationComments,
        addMentionedComments,
        addAllComments,
        addAllComment,
        addAnnotationComment,
        addMentionedComment,
        setStatus: setCommentsStatus,
        addComment,
        clearComments,
        setSendMessage,
        removeComment,
        setIsRecentCommentsPresent,
        setIsLoading,
    } = useCommentsStore()
    const {
        addActivities,
        addActivity,
        setStatus: setActivitesStatus,
    } = useActivitiesStore()
    const token = getLocalStorage('authToken')
    const tokenRef = useRef<string | null>(null)
    const socketRef = useRef<WebSocket | null>(null)
    const reconnectInterval = 2000
    let reconnectAttemptsRef = useRef(0)
    const maxReconnectAttempts = 10

    useEffect(() => {
        if (!!token) {
            tokenRef.current = token
        } else {
            tokenRef.current = null
        }
    }, [token])

    useEffect(() => {
        const connect = () => {
            if (
                socketRef.current &&
                socketRef.current.readyState === WebSocket.OPEN
            ) {
                return
            }
            setCommentsStatus('connecting')
            setActivitesStatus('connecting')
            socketRef.current = new WebSocket(
                `${CONFIG_VARS.SOCKET_ENDPOINT}/api/v1/socket`
            )
            socketRef.current.onopen = function () {
                reconnectAttemptsRef.current = 0
                socketRef.current?.send(`{
                    "type":"connection_init",
                    "payload":{
                        "headers":{
                            "authorization": "Bearer ${tokenRef.current}"
                        }
                    }
                }`)
                setSendMessage(sendMessage)
            }
            socketRef.current.onmessage = function (event: MessageEvent) {
                const data = JSON.parse(event.data) as any
                if (data.type === 'ping') return
                else if (data.type === 'connection_ack') {
                    setCommentsStatus('active')
                    setActivitesStatus('active')
                    socketRef?.current?.send(
                        `{
                            "type": "activities",
                            "payload": {
                                "operation": "GET",
                                "variables": {
                                "eId": "1",
                                "eName": "collections"
                                }
                            }
                        }`
                    )
                } else if (
                    data.type === 'comments' &&
                    Array.isArray(data.data)
                ) {
                    addComments({
                        entity_id: data.entity_id,
                        entity_name: data.entity_name,
                        comments: data.data,
                    })
                } else if (data.type === 'comments') {
                    if (data.comments?.length === 1) {
                        addComment({
                            entity_id: data.entity_id,
                            entity_name: data.entity_name,
                            comments: data.comments,
                        })
                    }
                    if (data.dashboard_comments?.annotations?.length === 1) {
                        addAnnotationComment(
                            data.dashboard_comments?.annotations[0]
                        )
                    }
                    if (data.dashboard_comments?.mentions?.length === 1) {
                        addMentionedComment(data.dashboard_comments.mentions[0])
                    }
                    if (data.dashboard_comments.all.length === 1) {
                        addAllComment(data.dashboard_comments.all[0])
                    }
                } else if (data.type === 'dashboard_comments') {
                    setIsLoading(false)
                    setIsRecentCommentsPresent(true)
                    if (data.data.all && data.data.all.length > 0) {
                        addAllComments(data.data.all)
                    }
                    if (
                        data.data.annotations &&
                        data.data.annotations.length > 0
                    ) {
                        addAnnotationComments(data.data.annotations)
                    }
                    if (data.data.mentions && data.data.mentions.length > 0) {
                        addMentionedComments(data.data.mentions)
                    }
                } else if (data.type === 'activities') {
                    if (Array.isArray(data.activities)) {
                        addActivities(data.activities)
                    } else {
                        addActivity([data])
                    }
                } else if (data.type === 'insertion') {
                    if (data.data.added_entity === 'comments') {
                        addComment({
                            entity_id: data.data.entity_id,
                            entity_name: data.data.entity_name,
                            comments: data.data.comments,
                        })
                    } else if (data.data.entity_name === 'dashboard_comments') {
                        if (data.data.dashboard_comments.annotations) {
                            addAnnotationComment(
                                data.data.dashboard_comments.annotations[0]
                            )
                        }
                        if (data.data.dashboard_comments.mentions) {
                            addMentionedComment(
                                data.data.dashboard_comments.mentions[0]
                            )
                        }
                        if (data.data.dashboard_comments.all) {
                            addAllComment(data.data.dashboard_comments.all[0])
                        }
                    }
                } else if (data.type === 'deletion') {
                    if (data.data.deleted_entity === 'comments') {
                        removeComment({
                            commentId: data.data.deleted_entity_id,
                            entity_id: data.data.entity_id,
                            entity_name: data.data.entity_name,
                        })
                    }
                }
            }
            socketRef.current.onerror = function (error: Event) {
                console.log('SOCKET ERROR', { error })
                setCommentsStatus('error')
                setActivitesStatus('error')
            }
            socketRef.current.onclose = function () {
                clearComments()
                setCommentsStatus('inactive')
                setActivitesStatus('inactive')
                // Try to reconnect after some time interval in case of unexpected disconnect.
                if (
                    tokenRef.current &&
                    reconnectAttemptsRef.current < maxReconnectAttempts
                ) {
                    setTimeout(connect, reconnectInterval)
                    reconnectAttemptsRef.current++
                }
            }
        }

        const handleVisibilityChange = () => {
            if (document.visibilityState === 'visible' && tokenRef.current) {
                // If the WebSocket is not open, reconnect
                if (
                    !socketRef.current ||
                    socketRef.current.readyState !== WebSocket.OPEN
                ) {
                    connect()
                }
            }
        }
        document.addEventListener('visibilitychange', handleVisibilityChange)

        if (tokenRef.current) {
            connect()
        } else {
            socketRef.current?.close()
        }

        return () => {
            if (socketRef.current) {
                socketRef.current.close()
            }
        }
    }, [token])

    // Define sendMessage function
    function sendMessage(message: string) {
        if (
            socketRef.current &&
            socketRef.current.readyState === WebSocket.OPEN
        ) {
            socketRef.current.send(message)
        } else {
            console.error('WebSocket is not open. Message not sent.')
        }
    }

    return null
}
