import React, { useState, useEffect } from 'react'
import { Box, Text, Button } from '../utility'
import { ColoredButton } from 'src/components/buttons/colored-button'
import { CloseIco, StrokedArrowLeft } from 'src/components/svg-icons'
import useStore from 'src/store/hooks/useStore'
import { Loader } from '../loader/loader'

export const Modal = ({
    isModal,
    children,
    title,
    primaryButtonTitle,
    secondaryButtonTitle,
    disablePrimaryButton = false,
    closeModal,
    onClose,
    onClick,
    optionalActionOnClick,
    disableActions = false,
    isLoading = false,
    width,
    maxWidth,
    optionalActionText,
    buttonVariant = 'primary',
    hidePrimaryButton = false,
    hideSecondaryButton = false,
    backAction = undefined,
    hideClose = false,
    defaultWidth = 'auto',
    bg = 'backgroundColor',
    preventDefault = false,
}: {
    children: React.ReactNode
    isModal: boolean
    primaryButtonTitle: string
    secondaryButtonTitle: string
    disablePrimaryButton?: boolean
    closeModal?: () => void
    title: string
    disableActions?: boolean
    onClose?: () => void
    onClick: () => void
    isLoading?: boolean
    width?: string
    maxWidth?: string
    optionalActionText?: string
    optionalActionOnClick?: () => void
    buttonVariant?: string
    hidePrimaryButton?: boolean
    hideSecondaryButton?: boolean
    backAction?: () => void
    hideClose?: boolean
    defaultWidth?: string
    bg?: string
    preventDefault?: boolean
}) => {
    const [isVisible, setIsVisible] = useState<string>('none')
    const [isOpen, setIsOpen] = useState<string>('')
    const { setIsModal } = useStore()

    function handleClose() {
        if (onClose) onClose()
        setIsOpen('close-modal')
        setTimeout(() => {
            setIsVisible('none')
            closeModal
                ? closeModal()
                : setIsModal({ isModalOpen: false, name: '', id: '' })
        }, 100)
    }

    useEffect(() => {
        if (isModal) {
            setIsOpen('')
            setIsVisible('flex')
        } else {
            handleClose()
        }
    }, [isModal])

    return (
        <Box
            position="fixed"
            top="0"
            left="0"
            width="100vw"
            height="100vh"
            display={isVisible}
            bg="#02214370"
            justifyContent="center"
            alignItems="center"
            zIndex={15}
            overflowY="auto"
            className={isOpen}
            borderRadius={'4px'}
            onClick={(e) => {
                if (preventDefault) {
                    e.preventDefault()
                }
            }}
        >
            <Box
                minWidth={width ? width : '600px'}
                maxWidth={maxWidth ? maxWidth : 'unset'}
                bg="white"
                width={defaultWidth}
                borderRadius="4px"
            >
                <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    borderBottom="solid"
                    borderColor="gray250"
                    borderWidth={1}
                >
                    <Box display="flex" alignItems="center">
                        {backAction && (
                            <Button
                                p="0px"
                                ml="24px"
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                                border="none"
                                bg="transparent"
                                onClick={backAction}
                            >
                                <StrokedArrowLeft />
                            </Button>
                        )}
                        <Text
                            fontSize="20px"
                            ml={backAction ? '16px' : '24px'}
                            fontFamily="Rubik"
                            color="primary"
                        >
                            {title}
                        </Text>
                    </Box>
                    <Box display="flex" alignItems="center">
                        {optionalActionText ? (
                            <Button
                                height="20px"
                                bg="transparent"
                                border="none"
                                fontSize="14px"
                                color="links"
                                position="relative"
                                mr={'24px'}
                                onClick={() => {
                                    optionalActionOnClick &&
                                        optionalActionOnClick()
                                }}
                            >
                                {optionalActionText}
                            </Button>
                        ) : null}
                        {!hideClose && (
                            <Button
                                mr="24px"
                                bg="transparent"
                                border="none"
                                onClick={(e) => {
                                    e.preventDefault()
                                    handleClose()
                                }}
                            >
                                <CloseIco color="#022143" />
                            </Button>
                        )}
                    </Box>
                </Box>
                <Box
                    display="flex"
                    flexDirection="column"
                    width="100%"
                    gridRowGap="8px"
                    justifyContent="start"
                    alignItems="start"
                    borderBottom="solid"
                    borderColor="gray250"
                    borderWidth={1}
                    borderRadius="4px"
                    // pb="64px"
                    bg={bg}
                >
                    {children}
                </Box>
                {!disableActions && (
                    <Box
                        pl="24px"
                        bg={bg}
                        py="12px"
                        display="flex"
                        gridColumnGap="16px"
                    >
                        {!hidePrimaryButton && (
                            <ColoredButton
                                disabled={disablePrimaryButton}
                                type="button"
                                id="modal-submit-btn"
                                onClick={
                                    !disablePrimaryButton ? onClick : () => {}
                                }
                                variant={
                                    buttonVariant ? buttonVariant : 'primary'
                                }
                            >
                                <Box
                                    display="flex"
                                    gridColumnGap={'8px'}
                                    alignItems="center"
                                    justifyContent="center"
                                >
                                    {isLoading && <Loader />}{' '}
                                    {primaryButtonTitle}
                                </Box>
                            </ColoredButton>
                        )}
                        {!hideSecondaryButton && (
                            <ColoredButton
                                type="button"
                                onClick={handleClose}
                                variant="secondary"
                                id="modal-cancel-btn"
                            >
                                {secondaryButtonTitle}
                            </ColoredButton>
                        )}
                    </Box>
                )}
            </Box>
        </Box>
    )
}
