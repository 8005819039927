import React, { useEffect, useState } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { Box } from 'src/components/utility'
import CollectionHeader from './collection-header'
import { Slider } from 'src/components/slider/slider'
import { EntityEmptyState } from 'src/components/collections/entity-empty-state'
import { useQuery } from '@apollo/client'
import type { DueDateType, ManagerType } from '../collections/collections.types'
import CollectionSortingAndFilter from './collection-sorting-and-filter'
import CollectionSliderElement from './collection-slider-element'
import HeaderLoader from 'src/components-v2/header-loader/header-loader'
import ProductVariantFilterContainer from 'src/components-v2/product-variants/product-list-variant-filter-container/product-variant-filter-container'
import { SortObjects, calculateStatusCounts } from './collection.helper'
import { GetCollection } from 'src/services/graphql/entities/collection/get-collection'
import type {
    FetchCollectionByIdQuery,
    FetchCollectionByIdQueryVariables,
    Status_Enum,
} from 'src/generated/graphql'
import { CardLoader } from 'src/components/collections/card-loader'
import { ENTITIES } from 'src/helpers/enums'

function Collection() {
    const { id: collectionId } = useParams()
    const navigate = useNavigate()
    const [searchParams] = useSearchParams()
    const [dueDate, setDueDate] = useState<DueDateType>(null)
    const [managers, setManagers] = useState<Array<ManagerType>>([])
    const [resourcesCount, setResourcesCount] = useState<
        Record<Status_Enum, number>
    >({
        new: 0,
        inProgress: 0,
        inReview: 0,
        requestedForResubmission: 0,
        complete: 0,
    })
    const { sort: activeSort = 'created_at', tab: currentTab = 'all' } =
        Object.fromEntries(searchParams.entries())

    if (!collectionId || isNaN(Number(collectionId))) {
        navigate('/404')
        return null
    }

    const {
        data: collectionData,
        loading: isCollectionLoading,
        refetch: refetchCollection,
    } = useQuery<FetchCollectionByIdQuery, FetchCollectionByIdQueryVariables>(
        GetCollection,
        {
            fetchPolicy: 'cache-and-network',
            variables: {
                id: Number(collectionId),
                order_by:
                    SortObjects[
                        activeSort as 'due_date' | 'name' | 'created_at'
                    ],
                due_date: dueDate
                    ? Array.isArray(dueDate)
                        ? {
                              _gte: dueDate[0]?.toISOString(),
                              _lt: dueDate[1]?.toISOString(),
                          }
                        : {
                              _eq: new Date(
                                  new Date(dueDate).setUTCHours(0, 0, 0, 0) - 1
                              ).toISOString(),
                          }
                    : undefined,
                assignee_ids:
                    managers.length > 0
                        ? { _in: managers.map((manager) => manager.id) }
                        : {},
            },
            onCompleted: (data) => {
                //If there is no collection, navigate to 404
                if (!data.collections_by_pk) {
                    navigate('/404', { replace: true })
                    return
                }
            },
        }
    )

    const collection = collectionData?.collections_by_pk

    const products = collection?.products

    // Filter products based on the current tab and variant statuses. If the product has atleast one variant with the status of the current tab, then the product will be displayed.
    const currentTabProducts = products?.filter((product) => {
        if (currentTab === 'all') {
            return true
        } else {
            return product.product_variants.some(
                (variant) => variant.status === currentTab
            )
        }
    })

    // Get total product variants inside all the products
    const totalProducts =
        products?.reduce((acc: number, product) => {
            return acc + product.product_variants.length
        }, 0) || 0

    useEffect(() => {
        setResourcesCount(calculateStatusCounts(products))
    }, [products])

    return (
        <Box
            height="calc(100vh - 60px)"
            display="grid"
            gridTemplateRows="auto 1fr"
            bg="white"
        >
            {collection ? (
                <CollectionHeader
                    key={collection?.id}
                    collection={collection}
                    refetchCollection={refetchCollection}
                />
            ) : isCollectionLoading ? (
                <HeaderLoader />
            ) : null}
            <Box
                bg="#F8FAFB"
                display="grid"
                height="100%"
                overflow="hidden"
                gridTemplateRows="33px 1fr"
                gridGap="20px"
                borderTop="1px solid #A7B9D1"
                style={{ borderRadius: '12px 12px 0px 0px' }}
                py="20px"
                px="24px"
            >
                <CollectionSortingAndFilter
                    collectionId={collection?.id}
                    collectionName={collection?.name || ''}
                    setDueDate={setDueDate}
                    setManagers={setManagers}
                    teamId={collection?.team?.id}
                    dueDate={dueDate}
                    managers={managers}
                    totalProducts={totalProducts}
                />
                <Box
                    maxHeight="100%"
                    overflow="hidden"
                    display="grid"
                    gridTemplateRows="33.5px 1fr"
                >
                    <Slider
                        element={
                            <CollectionSliderElement
                                currentTab={currentTab}
                                resourcesCount={resourcesCount}
                                totalProducts={totalProducts}
                            />
                        }
                    />
                    <Box overflowY="auto" className="scrollbar_none">
                        <Box
                            display="flex"
                            flexDirection="column"
                            gridGap="8px"
                            mt="8px"
                        >
                            {currentTabProducts ? (
                                currentTabProducts.length > 0 ? (
                                    currentTabProducts.map((product) => {
                                        return (
                                            <ProductVariantFilterContainer
                                                key={product.id}
                                                product={product}
                                                refetch={refetchCollection}
                                                status={currentTab}
                                            />
                                        )
                                    })
                                ) : (
                                    <EntityEmptyState
                                        entityName={ENTITIES.products}
                                    />
                                )
                            ) : isCollectionLoading ? (
                                <CardLoader />
                            ) : null}
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default Collection
