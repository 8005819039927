import * as React from 'react'
import { useConfiguratorContext } from './configurator.context'
import { Box, Text } from 'src/components/utility'
import { DivisionLine } from 'src/components/division-line/division-line'
import {
    ArtWorkIco,
    Color,
    Graphic,
    Material,
} from 'src/components/svg-icons/3d-renderer/actions'
import { uuid } from 'src/helpers'
import PanelFunctions from './panel-functions/panel-functions'

export interface IPanelProps {}

export default function Panel(props: IPanelProps) {
    const { highlights, advancedMode, setAdvancedMode } =
        useConfiguratorContext()

    const [activeFunctions, setActiveFunctions] = React.useState([
        {
            id: uuid(),
            icon: ArtWorkIco,
            name: 'Artwork',
            subText: 'Base Map',
            isActive: true,
        },
        {
            id: uuid(),
            icon: Material,
            name: 'Fabric',
            subText: 'All Maps',
            isActive: false,
        },
        {
            id: uuid(),
            icon: Color,
            name: 'Color',
            subText: '',
            isActive: false,
        },
        {
            id: uuid(),
            icon: Graphic,
            name: 'Graphic',
            subText: '',
            isActive: false,
        },
    ])
    return (
        <Box
            border="solid"
            borderRadius="4px"
            borderColor="gray250"
            borderWidth={1}
            py="12px"
        >
            <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                px="12px"
            >
                <Text>
                    {
                        highlights.filter((highlight) => highlight.isActive)
                            .length
                    }{' '}
                    Mesh
                </Text>
                <Box display="flex" alignItems="center" gridColumnGap="8px">
                    <Text fontSize="12px">Advanced</Text>
                    <Box
                        border="solid"
                        borderRadius="40px"
                        width="38px"
                        py="4px"
                        px="4px"
                        className="cursor-pointer transtion"
                        onClick={() => setAdvancedMode(!advancedMode)}
                        borderColor={
                            advancedMode
                                ? 'textInReview'
                                : 'textSecondaryDarkBlue'
                        }
                        position="relative"
                        display="flex"
                        alignItems="center"
                        justifyContent={advancedMode ? 'end' : 'start'}
                    >
                        <Box
                            borderRadius="50%"
                            width="8px"
                            className="transtion"
                            height="8px"
                            bg={
                                advancedMode
                                    ? 'textInReview'
                                    : 'textSecondaryDarkBlue'
                            }
                        ></Box>
                    </Box>
                </Box>
            </Box>
            <DivisionLine />
            <Box
                display="flex"
                alignItems="center"
                justifyContent="start"
                gridColumnGap="28px"
                px="16px"
                py="4px"
            >
                {activeFunctions.map((activeFunction, key: number) => {
                    return (
                        <Box
                            bg={
                                activeFunction.isActive
                                    ? 'primary'
                                    : 'transparent'
                            }
                            px="10px"
                            py="16px"
                            width="max-content"
                            borderRadius="4px"
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            flexDirection="column"
                            gridRowGap={'8px'}
                            className="cursor-pointer"
                            onClick={() => {
                                setActiveFunctions(() => {
                                    return activeFunctions.map((fn) =>
                                        fn.id === activeFunction.id
                                            ? {
                                                  ...fn,
                                                  isActive: true,
                                              }
                                            : {
                                                  ...fn,
                                                  isActive: false,
                                              }
                                    )
                                })
                            }}
                        >
                            <activeFunction.icon
                                fill={
                                    activeFunction.isActive
                                        ? 'white'
                                        : '#778CA2'
                                }
                            />
                            <Box>
                                <Text
                                    color={
                                        activeFunction.isActive
                                            ? 'white'
                                            : 'primary'
                                    }
                                    fontSize="12px"
                                    my="0px"
                                >
                                    {activeFunction.name}
                                </Text>
                                {advancedMode && (
                                    <Text
                                        color={
                                            activeFunction.isActive
                                                ? 'white'
                                                : 'primary'
                                        }
                                        fontSize="10px"
                                        my="0px"
                                        mt="4px"
                                    >
                                        {activeFunction.subText}
                                    </Text>
                                )}
                            </Box>
                        </Box>
                    )
                })}
            </Box>
            <DivisionLine />
            <Box mt="24px">
                <PanelFunctions
                    type={
                        (activeFunctions
                            .find((fn) => fn.isActive)
                            ?.name?.toLowerCase() as
                            | 'artwork'
                            | 'fabric'
                            | 'color') || 'artwork'
                    }
                />
            </Box>
        </Box>
    )
}
