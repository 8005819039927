import * as React from 'react'
import { Box, Button, Input, Text } from '../utility'
import { Pan, RenderImageIco } from '../svg-icons/3d-renderer/actions'
import { CommentAnnotationLG } from '../svg-icons/3d-renderer/comment-annotation'
import { useSearchParams } from 'react-router-dom'
import { useConfiguratorContext } from './configurator/configurator.context'
import { ColoredButton } from '../buttons/colored-button'
import { CrossSm } from '../svg-icons'
import RadioInput from 'src/components-v2/radio-button/radio-button'

export function ReviewerOptions({
    onChange,
    onImageCapture,
}: {
    onChange: (mode: 'pan' | 'annotation' | 'renderImage') => void
    onImageCapture: () => void
}) {
    const { operation, setOperation } = useConfiguratorContext()
    const [searchParams, setSearchParams] = useSearchParams()
    return (
        <Box
            position="absolute"
            top="124px"
            borderRadius="4px"
            gridRowGap="24px"
            px="4px"
            py="12px"
            bg="white"
            display="flex"
            flexDirection="column"
            gridGap="16px"
        >
            <Button
                border="none"
                display="flex"
                justifyContent="center"
                alignItems="center"
                bg="white"
                onClick={() => {
                    setOperation('pan')
                    searchParams.set('mode', 'pan')
                    setSearchParams(searchParams)
                    onChange('pan')
                }}
            >
                <Pan fill={operation === 'pan' ? '#022143' : '#fff'} />
            </Button>
            <Button
                border="none"
                display="flex"
                justifyContent="center"
                alignItems="center"
                bg="white"
                onClick={() => {
                    setOperation('annotation')
                    searchParams.set('mode', 'annotation')
                    setSearchParams(searchParams, { replace: true })
                    onChange('annotation')
                }}
            >
                <CommentAnnotationLG
                    stroke={operation === 'annotation' ? '#fff' : '#778CA2'}
                    fill={operation === 'annotation' ? '#022143' : '#fff'}
                />
            </Button>

            <Box position="relative">
                <Button
                    border="none"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    bg={'white'}
                    onClick={() => {
                        setOperation('renderImage')
                        searchParams.set('mode', 'renderImage')
                        setSearchParams(searchParams, { replace: true })
                        onChange('renderImage')
                    }}
                >
                    <RenderImageIco
                        color={operation === 'renderImage' ? '#fff' : '#778CA2'}
                        fill={operation === 'renderImage' ? '#022143' : '#fff'}
                    />
                </Button>
                {operation === 'renderImage' && (
                    <Box
                        position="absolute"
                        top="0"
                        left="60px"
                        width="max-content"
                        bg="white"
                        border="1px solid #B8B8B8"
                        p="8px"
                        borderRadius="4px"
                        display="flex"
                        flexDirection="column"
                        gridRowGap="0px"
                        px="12px"
                        overflowY="auto"
                        height={'220px'}
                        justifyContent="space-evenly"
                        zIndex={15}
                    >
                        <Dimensions />
                        <Text my="0px" fontSize="12px" color="textSecondary">
                            TIP: Place the model in the best direction to get
                            accurate render.
                        </Text>
                        <Box width="100%">
                            <ColoredButton
                                height="max-content"
                                width="100%"
                                variant="primary"
                                onClick={() => {
                                    onImageCapture()
                                    setOperation('pan')
                                }}
                            >
                                Capture
                            </ColoredButton>
                        </Box>
                    </Box>
                )}
            </Box>
        </Box>
    )
}

function Dimensions() {
    const { imageRenderDimensions, setImageRenderDimensions } =
        useConfiguratorContext()
    const [options, setOptions] = React.useState({
        low: true,
        medium: false,
        high: false,
    })
    const [selectedLabel, setSelectedLabel] = React.useState('2K (2048 × 1080)')

    function updateLabelAndResolution(item: string) {
        setSelectedLabel(item)
        switch (item) {
            case '2K (2048 × 1080)':
                return setImageRenderDimensions({ height: 1080, width: 2046 })
            case '4K (4096 × 2160)':
                return setImageRenderDimensions({ height: 2160, width: 4096 })
            case '8K (7680 × 4320)':
                return setImageRenderDimensions({ height: 4320, width: 7680 })
        }
    }

    return (
        <Box>
            <Box width="80%">
                <Text color="textTags" fontSize="12px" my="0px">
                    Choose Resolution
                </Text>

                <Box mt="12px" display="flex" gridColumnGap="8px">
                    <RadioInput
                        isChecked={options.low}
                        lable="Low"
                        onChange={(isChecked) => {
                            setSelectedLabel('2K (2048 × 1080)')
                            updateLabelAndResolution('2K (2048 × 1080)')
                            setOptions({
                                high: false,
                                medium: false,
                                low: isChecked,
                            })
                        }}
                    />
                    <RadioInput
                        isChecked={options.medium}
                        lable="Medium"
                        onChange={(isChecked) => {
                            setSelectedLabel('4K (4096 × 2160)')
                            updateLabelAndResolution('4K (4096 × 2160)')
                            setOptions({
                                low: false,
                                high: false,
                                medium: isChecked,
                            })
                        }}
                    />
                    <RadioInput
                        isChecked={options.high}
                        lable="High"
                        onChange={(isChecked) => {
                            setSelectedLabel('8K (7680 × 4320)')
                            updateLabelAndResolution('8K (7680 × 4320)')
                            setOptions({
                                low: false,
                                medium: false,
                                high: isChecked,
                            })
                        }}
                    />
                </Box>
            </Box>
            <Box display="flex" gridColumnGap="6px" mt="12px">
                <Text fontSize="12px" my="0px" color="textTags">
                    Resolution :
                </Text>
                <Text fontSize="12px" my="0px">
                    {selectedLabel}
                </Text>
            </Box>
            {false && (
                <Box
                    px="12px"
                    mt="12px"
                    display="flex"
                    gridColumnGap="8px"
                    alignItems="center"
                    justifyContent="center"
                    disabled={selectedLabel !== 'Custom'}
                >
                    <Box>
                        <Text
                            color={
                                selectedLabel !== 'Custom'
                                    ? 'textSecondaryDarkBlue'
                                    : 'primary'
                            }
                            fontSize="14px"
                            my="0px"
                            mb="4px"
                        >
                            Width
                        </Text>
                        <Input
                            bg="transparent"
                            border="solid"
                            borderWidth={1}
                            borderRadius="4px"
                            borderColor="secondaryLighterBlue"
                            height="32px"
                            type="number"
                            value={imageRenderDimensions.width}
                            disabled={selectedLabel !== 'Custom'}
                            onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                            ) => {
                                setImageRenderDimensions({
                                    ...imageRenderDimensions,
                                    width: Number(e.target.value),
                                })
                            }}
                        />
                    </Box>
                    <Box mt="16px">
                        <CrossSm />
                    </Box>
                    <Box>
                        <Text
                            color={
                                selectedLabel !== 'Custom'
                                    ? 'textSecondaryDarkBlue'
                                    : 'primary'
                            }
                            fontSize="14px"
                            my="0px"
                            mb="4px"
                        >
                            Height
                        </Text>
                        <Input
                            bg="transparent"
                            border="solid"
                            borderWidth={1}
                            borderRadius="4px"
                            borderColor="secondaryLighterBlue"
                            height="32px"
                            type="number"
                            value={imageRenderDimensions.height}
                            disabled={selectedLabel !== 'Custom'}
                            onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                            ) => {
                                setImageRenderDimensions({
                                    ...imageRenderDimensions,
                                    height: Number(e.target.value),
                                })
                            }}
                        />
                    </Box>
                </Box>
            )}
        </Box>
    )
}
