export const Logo = () => (
    <svg width={228} height={56} fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M77.725 46.499 67.246 12.204h6.669l8.573 28.198 8.717-28.198h6.668L87.394 46.5h-9.669ZM107.653 46.499V12.204h6.477V46.5h-6.477ZM126.986 46.499V12.204h14.48c.333 0 .778.016 1.334.048.571.016 1.079.063 1.524.143 2.048.317 3.723.992 5.025 2.024a9.11 9.11 0 0 1 2.905 3.906c.62 1.556.929 3.294.929 5.215 0 2.874-.714 5.335-2.143 7.383-1.429 2.032-3.668 3.287-6.716 3.763l-2.858.19h-8.002V46.5h-6.478Zm19.338 0-6.763-13.956 6.668-1.286 7.431 15.242h-7.336Zm-12.86-17.672h7.716c.334 0 .699-.016 1.096-.047a5.607 5.607 0 0 0 1.095-.19c.873-.239 1.548-.636 2.025-1.192a4.763 4.763 0 0 0 .976-1.881 7.506 7.506 0 0 0 .286-1.977c0-.619-.095-1.27-.286-1.953a4.56 4.56 0 0 0-.976-1.881c-.477-.572-1.152-.976-2.025-1.215a5.614 5.614 0 0 0-1.095-.19 13.794 13.794 0 0 0-1.096-.048h-7.716v10.574ZM172.565 46.499V18.253h-10.812v-6.049h28.103v6.05h-10.813v28.245h-6.478ZM213.355 47.213c-2.763 0-5.192-.556-7.288-1.667-2.096-1.127-3.731-2.707-4.906-4.74-1.175-2.032-1.762-4.421-1.762-7.168V12.252l6.573-.048V33.59c0 1.128.19 2.152.571 3.073.381.92.905 1.715 1.572 2.381a7.331 7.331 0 0 0 2.358 1.548c.905.35 1.865.524 2.882.524 1.047 0 2.016-.182 2.905-.547a7 7 0 0 0 2.358-1.549 6.94 6.94 0 0 0 1.548-2.381c.381-.92.571-1.937.571-3.049V12.204h6.573v21.434c0 2.747-.587 5.136-1.762 7.169-1.175 2.032-2.81 3.612-4.906 4.739-2.096 1.111-4.525 1.667-7.287 1.667Z"
            fill="#EAFC40"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="m22.457 27.454 23.05-13.22-20.149-11.21L2.882 15.868v25.598l19.575 10.97V27.454ZM.968 13.642 24.398.253a1.921 1.921 0 0 1 1.888-.01l22.108 12.299c1.306.726 1.319 2.601.022 3.345L25.339 29.124v24.952c0 1.468-1.58 2.394-2.86 1.676L.982 43.705A1.921 1.921 0 0 1 0 42.028V15.31c0-.69.37-1.326.968-1.668Z"
            fill="#EAFC40"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.27 20.095a1.44 1.44 0 0 1 1.977-.495l11.451 6.871a1.441 1.441 0 0 1-1.483 2.471l-11.45-6.87a1.44 1.44 0 0 1-.495-1.977ZM31.898 32.396v18.957l10.917-5.88a11.528 11.528 0 0 0 6.061-10.149v-12.61l-16.978 9.682ZM48.885 19.39l-19.87 11.33v22.24c0 1.455 1.553 2.382 2.833 1.692l12.333-6.642a14.41 14.41 0 0 0 7.577-12.687V21.06c0-1.474-1.592-2.4-2.873-1.669Z"
            fill="#EAFC40"
        />
    </svg>
)
