import { useNavigate } from 'react-router-dom'
import { uuid } from '.'
import { useUser } from './hooks/user-hook'
import { getLocalStorage } from 'src/helpers'

export const NotificationParser = ({
    comment,
    getUrl,
}: {
    comment: string
    getUrl?: (url: string, type?: string) => void
}) => {
    const { role } = useUser()
    const navigate = useNavigate()
    const isCommentNotification = comment && comment.includes('commented')

    function createNavigator(tagString: string, name: string) {
        const tagSplit = tagString.match(/[a-zA-Z]+|[0-9]+/g)
        if (!tagSplit) return () => {}
        else if (tagSplit[0].toLowerCase().includes('collections')) {
            getUrl && getUrl(`/collections/${tagSplit[1]}`)
            return () =>
                navigate(`/collections/${tagSplit[1]}`, {
                    state: {
                        from: 'notification',
                        isCommentNotification,
                    },
                })
        } else if (tagSplit[0].toLowerCase().includes('products')) {
            getUrl && getUrl(`/collections/product/${tagSplit[1]}`)
            return () =>
                navigate(`/collections/product/${tagSplit[1]}`, {
                    state: {
                        from: 'notification',
                        isCommentNotification,
                    },
                })
        } else if (tagSplit[0].toLowerCase().includes('team')) {
            getUrl && getUrl(`/team/${tagSplit[1]}`)
            return () =>
                navigate(`/team/${tagSplit[1]}`, {
                    state: {
                        from: 'notification',
                    },
                })
        } else if (tagSplit[0].toLowerCase().includes('user')) {
            if (role.toLowerCase() === 'designer') return () => {}
            getUrl && getUrl(`/user/${tagSplit[1]}`)
            return () =>
                navigate(`/user/${tagSplit[1]}`, {
                    state: {
                        from: 'notification',
                    },
                })
        } else if (tagSplit[0].toLowerCase().includes('todos')) {
            getUrl && getUrl(`/todo/${tagSplit[1]}`)
            return () =>
                navigate(`/todo/${tagSplit[1]}`, {
                    state: {
                        isCommentNotification,
                    },
                })
            // !!FIXME: Make changes to either the string backend sends or the parser to return 'download' instead of 'downloadhttps'
        } else if (tagSplit[0].toLowerCase().includes('downloadhttps')) {
            getUrl &&
                getUrl(
                    tagString.replace('DOWNLOAD', '') +
                        '?token=' +
                        getLocalStorage('authToken'),
                    'DOWNLOAD'
                )
            return () => {
                window.open(
                    tagString.replace('DOWNLOAD', '') +
                        '?token=' +
                        getLocalStorage('authToken'),
                    '_blank'
                )
            }
        }
    }
    let parsedComment: any[] = []
    let pattern: string[] = []
    let flag = false

    comment?.split('').forEach((letter) => {
        if (letter === '<') {
            flag = true
        }
        if (flag && !letter.includes('>')) {
            pattern.push(letter)
        } else if (flag && letter.includes('>')) {
            flag = false
            const raw = pattern.join('').replace('<@', '').replace('>', '')
            const navigateFunction = createNavigator(
                raw.split('&')[0],
                raw.split('&')[1]
            )
            parsedComment.push(
                <a
                    style={{ textDecoration: 'none', wordBreak: 'break-all' }}
                    download={true}
                    onClick={(e: React.MouseEvent<HTMLSpanElement>) => {
                        e.preventDefault()
                        e.stopPropagation()
                        navigateFunction && navigateFunction()
                    }}
                    id={`tag-${uuid()}`}
                    target={
                        raw.split('&')[0].toLowerCase().includes('DOWNLOAD')
                            ? '_blank'
                            : '_self'
                    }
                    data-user-id={raw.split('&')[0].replace('USER', '')}
                    className={
                        role.toLowerCase() === 'designer' &&
                        raw.includes('USER')
                            ? ''
                            : 'span-link'
                    }
                >
                    {raw.split('&')[1]}
                </a>
            )
            pattern = []
        } else {
            parsedComment.push(letter)
        }
    })
    return <>{parsedComment}</>
}
