export function SupportIco() {
    return (
        <svg
            width={18}
            height={18}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M9 .875C4.52.875.875 4.52.875 9S4.52 17.125 9 17.125 17.125 13.48 17.125 9 13.48.875 9 .875zm0 1.25A6.883 6.883 0 0115.875 9 6.883 6.883 0 019 15.875 6.883 6.883 0 012.125 9 6.883 6.883 0 019 2.125zM6.187 6.5a.937.937 0 100 1.875.937.937 0 000-1.875zm5.625 0a.937.937 0 100 1.875.937.937 0 000-1.875zm-7.187 4.375S5.628 14 9 14s4.375-3.125 4.375-3.125h-8.75z"
                fill="#C2CFE0"
            />
        </svg>
    )
}
