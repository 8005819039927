export function QuickTourIco() {
    return (
        <svg
            width={19}
            height={14}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M11.493.165c-.356.003-.71.02-1.085.12-.374.101-.748.262-1.145.523C8.771.519 8.293.28 7.837.225 7.269.155 6.742.193 6.129.205c-.725.015-1.2.387-1.968.683a8.39 8.39 0 01-3.154.583.643.643 0 00-.643.643v5.14c0 .248.14.474.361.585l1.186.583 4.218 4.7.02.02c.482.478 1.133.596 1.708.563a3.39 3.39 0 001.547-.462c.949-.572 3.536-2.37 3.536-2.37l.06-.04.04-.04c.306-.305.46-.676.563-1.065l2.47-1.246 1.849-.623a.634.634 0 00.442-.604V2.114a.643.643 0 00-.643-.643 8.254 8.254 0 01-3.154-.603C13.798.567 13.33.2 12.598.185c-.392-.007-.748-.022-1.105-.02zm.02 1.286c.289-.003.623.012 1.045.02.068.002.685.266 1.547.603.728.286 1.74.54 2.973.622v4.098l-1.487.503h-.04l-.04.02-1.949.984a2.28 2.28 0 00-.482-.803l-.04-.06-2.732-3.356-.402-.502-.502.402-1.728 1.406c-.58.402-1.05.287-1.527.06-.138-.065-.13-.085-.24-.16L8.84 2.857l.04-.04c.872-.864 1.407-1.163 1.869-1.286.23-.063.474-.078.763-.08zm-4.48.02a4.69 4.69 0 01.643.02c.168.02.36.163.522.22-.065.061-.113.096-.18.162l-.04.04-3.516 2.913-.543.442.483.502s.464.502 1.185.844c.72.341 1.833.535 2.832-.161l.04-.04 1.246-1.025 2.37 2.913.02.04.02.02c.425.42.415 1.08 0 1.507-.004.005.006.015 0 .02l-.1.06-.843-1.145-1.045.764.824 1.105c-.334.226-.485.344-.844.582l-.864-1.165-1.045.763.824 1.105c-.073.046-.223.146-.281.181a2.316 2.316 0 01-.944.281c-.33.018-.58-.057-.703-.16l-.02-.02-4.3-4.761-.08-.1-1.045-.523V2.696c1.228-.08 2.248-.324 2.974-.602.858-.33 1.471-.6 1.546-.603l.864-.02z"
                fill="#C2CFE0"
            />
        </svg>
    )
}
