import { Line } from 'rc-progress'

import { libraryDefaultKeys } from 'src/screens/library/library.constants'
import { ExtensionIcoLG } from 'src/components/file-display/file-display'
import { Tick } from 'src/components/svg-icons'
import { Box, Button, Image, Text } from 'src/components/utility'
import CircleCross from 'src/components/svg-icons/circle-cross-ico'

export const FilePreview = ({
    url,
    name,
    fileId,
    onDelete,
    onSelect,
    onClick,
    isDownloadable,
    width,
    height,
    bg = '#ffffff',
    redirectionUrl = '/file-preview',
    showLoader = false,
    percentage = '0',
    selected = false,
    librarySelect = false,
    thumbnail,
    params = [],
    redirect = true,
    minWidth,
    isDeletePermitted = false,
    onDragStart,
}: {
    url: string
    name: string
    fileId?: string | number | undefined
    onDelete?: () => void
    onSelect?: (bool: boolean) => void
    onClick?: () => void
    isDownloadable?: boolean
    width?: string
    height?: string
    bg?: string
    openFileInNewTab?: boolean
    redirectionUrl?: string
    showLoader?: boolean
    percentage?: string
    selected?: boolean
    librarySelect?: boolean
    thumbnail?: string
    params?: { key: string; value: string }[]
    redirect?: boolean
    isUrlRequired?: boolean
    minWidth?: string
    isDeletePermitted?: boolean
    onDragStart?: (e: React.DragEvent<HTMLDivElement>) => void
}) => {
    const type = name?.split('.')[name.split('.').length - 1].toLowerCase()
    const fileName =
        name.split('.')[0].length > 7
            ? name.split('.')[0].slice(0, 7) + '...' + type
            : name

    let _newCallBackUrl: string = window.location.pathname
    const _urlParams = window.location.search
    _newCallBackUrl = `${_newCallBackUrl}${_urlParams}`

    const openInNewTab = (url: string) => {
        const paramsList = [
            {
                key: 'url',
                value: encodeURIComponent(url),
            },
            {
                key: 'name',
                value: encodeURIComponent(name),
            },
        ]
        let paramsString = [...paramsList, ...params]
            .map(({ key, value }, index) => {
                if (index === 0) {
                    return `?${key}=${value}`
                } else {
                    return `&${key}=${value}`
                }
            })
            .join('')
        let _url = `${redirectionUrl}${paramsString}`

        if (fileId) {
            _url += `&fileId=${fileId}&${libraryDefaultKeys.showFileDetails}=${fileId}`
        }

        _url += `&newTab=1`
        window.open(
            window.location.origin +
                `${_url}` +
                `&callback_url=${_newCallBackUrl}`,
            '_blank'
        )
    }

    return (
        <>
            <Box
                border="solid"
                bg={bg}
                alignItems="center"
                borderWidth={selected ? 2 : 1}
                borderColor={'#E8ECEF'}
                borderRadius="4px"
                position="relative"
                id={`file_display_${name.split('.')[0]}`}
                width={width ? width : '120px'}
                height={height ? height : '120px'}
                onClick={onClick && onClick}
                minWidth={minWidth && minWidth}
                draggable={!!onDragStart}
                onDragStart={(e: React.DragEvent<HTMLDivElement>) => {
                    onDragStart && onDragStart(e)
                }}
            >
                {isDeletePermitted && (
                    <Button
                        onClick={(e) => {
                            e.stopPropagation()
                            onDelete && onDelete()
                        }}
                        bg="transparent"
                        border="none"
                        fontStyle="Rubik"
                        fontSize="12px"
                        color="links"
                        position="absolute"
                        right="-12px"
                        top="-12px"
                        width="max-content"
                        id="collection-menu-button"
                    >
                        <CircleCross />
                    </Button>
                )}
                {selected && !librarySelect && (
                    <Box
                        width="24px"
                        height="24px"
                        display="flex"
                        alignItems="center"
                        bg="links"
                        position="absolute"
                        justifyContent="center"
                        borderRadius="50%"
                        top="4px"
                        right="4px"
                    >
                        <Tick color="#fff" isDone={true}></Tick>
                    </Box>
                )}
                {type === 'jpeg' || type === 'jpg' || type === 'png' ? (
                    <Image
                        width="100%"
                        height="auto"
                        style={{
                            height: 'calc(100% - 28px)',
                            objectFit: 'cover',
                        }}
                        loading="lazy"
                        src={thumbnail || url}
                    />
                ) : (
                    <Box
                        width="100%"
                        height="100%"
                        maxHeight="calc(100% - 28px)"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                    >
                        {
                            <ExtensionIcoLG
                                type={name
                                    ?.split('.')
                                    [name.split('.').length - 1].toLowerCase()}
                            />
                        }
                    </Box>
                )}

                {showLoader && (
                    <Box
                        zIndex={5}
                        bottom="24px"
                        position="absolute"
                        width="100%"
                    >
                        <Line
                            percent={Number(percentage)}
                            strokeWidth={2}
                            trailWidth={2}
                            style={{ width: '100%' }}
                            strokeColor="#39A407"
                            trailColor="#EBECEF10"
                        />
                    </Box>
                )}
                <Box
                    border="solid"
                    bg="white"
                    borderWidth={1}
                    borderColor="#E8ECEF"
                    p="8px"
                    gridColumnGap="4px"
                    bottom="0px"
                    display="flex"
                    alignItems="center"
                    height="28px"
                    width="100%"
                    position="absolute"
                    zIndex={1}
                    className={redirect ? 'cursor-pointer' : ''}
                    onClick={() => redirect && openInNewTab(url)}
                >
                    {!showLoader && (
                        <Text
                            className="overflow__elipses"
                            fontSize="8px"
                            my="0px"
                            fontFamily="Rubik"
                            color="textSecondaryDarkBlue"
                            ml="8px"
                        >
                            {fileName}
                        </Text>
                    )}

                    {showLoader && (
                        <>
                            <Text
                                className="overflow__elipses"
                                fontSize="8px"
                                my="0px"
                                fontFamily="Rubik"
                                color="textSecondaryDarkBlue"
                                ml="8px"
                            >
                                {Number(percentage) === 100
                                    ? 'Uploaded'
                                    : fileName}
                            </Text>
                        </>
                    )}
                </Box>
            </Box>
        </>
    )
}
