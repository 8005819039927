import { gql } from '@apollo/client'

export const GetProducts = gql`
    query GetProducts(
        $limit: Int = 100
        $offset: Int = 0
        $status: status_enum_comparison_exp = {}
        $order_by: [products_order_by!] = { name: asc }
        $due_date: timestamptz_comparison_exp = {}
        $manager_ids: bigint_comparison_exp = {}
    ) {
        all: products_aggregate(
            where: { due_date: $due_date, approver_id: $manager_ids }
        ) {
            aggregate {
                count
            }
        }
        new: products_aggregate(
            where: {
                status: { _eq: new }
                due_date: $due_date
                approver_id: $manager_ids
            }
        ) {
            aggregate {
                count
            }
        }
        inProgress: products_aggregate(
            where: {
                status: { _eq: inProgress }
                due_date: $due_date
                approver_id: $manager_ids
            }
        ) {
            aggregate {
                count
            }
        }
        inReview: products_aggregate(
            where: {
                status: { _eq: inReview }
                due_date: $due_date
                approver_id: $manager_ids
            }
        ) {
            aggregate {
                count
            }
        }
        requestedForResubmission: products_aggregate(
            where: {
                status: { _eq: requestedForResubmission }
                due_date: $due_date
                approver_id: $manager_ids
            }
        ) {
            aggregate {
                count
            }
        }
        completed: products_aggregate(
            where: {
                status: { _eq: complete }
                due_date: $due_date
                approver_id: $manager_ids
            }
        ) {
            aggregate {
                count
            }
        }
        products(
            limit: $limit
            offset: $offset
            where: {
                status: $status
                due_date: $due_date
                approver_id: $manager_ids
                collection_id: { _is_null: false }
            }
            order_by: $order_by
        ) {
            id
            name
            product_variants {
                id
                name
                is_primary
                other_data
                due_date
                is_delayed
                status
                team {
                    id
                    name
                    team_members {
                        user {
                            other_data
                            name
                            id
                        }
                    }
                }
                collection {
                    id
                    name
                }
                totalTodos: todos_aggregate {
                    aggregate {
                        count
                    }
                }
                completedTodos: todos_aggregate(
                    where: { status: { _eq: complete } }
                ) {
                    aggregate {
                        count
                    }
                }
            }
        }
    }
`

export const GetProductByTeamId = gql`
    query getProductByTeamId(
        $teamId: bigint
        $order_by: [products_order_by!] = { id: desc }
    ) {
        products(where: { team_id: { _eq: $teamId } }, order_by: $order_by) {
            id
            created_at
            name
            team_id
            is_delayed
            description
            due_date
            approver_due_date
            tags
            status
            other_data
            approver_id
            product_approver {
                id
                name
                email
                other_data
            }
            collection {
                id
                name
                manager_id
            }
            todos_aggregate {
                aggregate {
                    count
                }
            }
            product_creator {
                name
                files(where: { type: { _eq: "profile_pic" } }) {
                    path
                }
            }
            team {
                id
                name
                team_members {
                    user {
                        other_data
                        name
                        id
                    }
                }
            }
        }
    }
`
