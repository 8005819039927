import type { InternalRefetchQueriesInclude } from '@apollo/client'
import { useLazyQuery, useMutation } from '@apollo/client'
import axios from 'axios'
import { useEffect, useState } from 'react'
import { useLocation, useSearchParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { getLocalStorage, useUser } from 'src/helpers'
import type { statuses } from 'src/helpers/enums'
import { PerPage } from 'src/helpers/enums'
import { dropdownNames } from 'src/screens/people/people.constants'
import {
    CancelInvitation,
    DeleteCollaborator,
    DeleteTeam,
    GET_ORGANIZATION_MEMBNERS,
    GetTeamDetailsById,
    GetTeamMemberByTeamId,
    InsertCollaborator,
    InviteUser,
    RemoveUserFromOrganisation,
    ResendInvitation,
    UpdateOrgannisationMemberRole,
} from 'src/services'
import {
    COLLABORATOR_COUNT,
    GET_TEAMS_BY_USER_ID,
    TEAM_COUNT,
} from 'src/services/api/endpoints'
import { UpdateInvitedUserRole } from 'src/services/graphql/mutations/invite-users'
import { GetInvitationsByEmail } from 'src/services/graphql/query/get-invitations'
import {
    GetAnalyticsOfTeams,
    GetAnalyticsOfUsers,
    GetCollectionsByTeamId,
    GetProductByTeamId,
    GetTeamByUserId,
    GetTodosByUserId,
} from 'src/services/graphql/query/organisations-queries'
import type {
    TGqlCollections,
    TGqlTeamAnalytics,
    TGqlTeams,
    TGqlTodos,
    TGqlUserAnalytics,
} from '../@types/team.gql.typs'
import { UpdateTeam } from 'src/services/graphql/mutations/organisations'
import useStore from 'src/store/hooks/useStore'
import type { GetDashboardProductQuery } from 'src/generated/graphql'

type statusKeys = '_eq' | '_is_null'
type orderByKeys = 'created_at' | 'due_date' | 'name'
export function useDeleteTeam() {
    const [isTeamDeleted, setIsTeamDeleted] = useState(false)
    const [deleteTeam] = useMutation(DeleteTeam)

    async function handleTeamDelete(teamId: string) {
        await deleteTeam({
            variables: {
                id: teamId,
                deleted_at: new Date(),
            },
            onCompleted: (data) => {
                setIsTeamDeleted(true)
            },
            onError: (error) => {
                setIsTeamDeleted(false)
            },
        })
        return
    }
    return { isTeamDeleted, handleTeamDelete }
}

export function useDeleteCollaborator() {
    const [deleteUser] = useMutation(RemoveUserFromOrganisation)
    const [reactivateUserMutation] = useMutation(RemoveUserFromOrganisation)

    function removeUser(userId: string, name: string) {
        deleteUser({
            variables: {
                id: userId,
                deleted_at: new Date(),
            },
            refetchQueries: ['GetTodoPercentagesForUser'],
            onCompleted: (data) => {
                if (data.update_organisation_members.affected_rows > 0) {
                    toast(`Successfully Archived ${name} from organization `, {
                        className: 'custom-toaster toaster-success',
                    })
                } else {
                    toast(
                        'An error occurred while archiving the user from the organization',
                        { className: 'custom-toaster toaster-error' }
                    )
                }
            },
        })
    }
    function reactivateUser(userId: string) {
        reactivateUserMutation({
            variables: {
                id: userId,
                deleted_at: null,
            },
            refetchQueries: ['GetTodoPercentagesForUser'],
            onCompleted: (data) => {
                if (data.update_organisation_members.affected_rows > 0) {
                    toast(
                        `Successfully Reactivated ${name} from organization `,
                        {
                            className: 'custom-toaster toaster-success',
                        }
                    )
                } else {
                    toast(
                        'An error occurred while reactivating the user from the organization',
                        { className: 'custom-toaster toaster-error' }
                    )
                }
            },
        })
    }

    return { removeUser, reactivateUser }
}

export function useFetchOrganistionMembers() {
    const [isLoaded, setIsLoaded] = useState(false)
    const [searchParams, _] = useSearchParams()
    async function fetchOrganistionMembers({
        contributorsCurrentPage,
        searchString,
        role,
        status = 'all',
        sort = 'az',
    }: {
        contributorsCurrentPage: number
        searchString: string
        role?: string
        status?: string
        sort?: 'az' | 'za' | 'hl' | 'lh'
    }) {
        setIsLoaded(true)
        try {
            const queryParams: any = {
                limit: PerPage,
                offset: contributorsCurrentPage,
                status,
            }
            if (role && role !== dropdownNames.any) {
                queryParams.role = role.toLowerCase()
            }
            if (
                (sort && (sort === 'az' || sort === 'za')) ||
                ['az', 'za'].includes(searchParams.get('sort') || '')
            ) {
                queryParams.name = sort || searchParams.get('sort')
            }
            if (
                (sort && (sort === 'lh' || sort === 'hl')) ||
                ['hl', 'lh'].includes(searchParams.get('sort') || '')
            ) {
                queryParams.sort_by_completion_rate =
                    sort || searchParams.get('sort')
            }

            if (searchParams.get('sort') === 'role') {
                queryParams.role = searchParams.get('role')
            }

            if (searchString) {
                queryParams.search = searchString
            }

            const source = axios.CancelToken.source()
            const { data } = await axios.get(GET_ORGANIZATION_MEMBNERS, {
                cancelToken: source.token,
                headers: {
                    'x-auth-token': getLocalStorage('authToken'),
                },
                params: queryParams,
            })

            if (data && data.success) {
                return { data: data.data, count: data.count, source }
            }
        } catch (error) {
            console.error(error)
        }
        setIsLoaded(false)
    }
    return { fetchOrganistionMembers, isLoaded }
}

export function useAddCollaborators() {
    const [InsertCollaboratorMutation] = useMutation(InsertCollaborator)
    async function addCollaborator(
        users: ISuggestion[],
        teamId: string,
        refetchQueries?: InternalRefetchQueriesInclude
    ) {
        return new Promise((resolve, reject) => {
            InsertCollaboratorMutation({
                variables: {
                    objects: users.map((user) => ({
                        user_id: user.user_id,
                        team_id: teamId,
                    })),
                },
                refetchQueries,
                onError: (e) => {
                    console.log(e)
                    if (e.message.includes('unexpected null value')) {
                        toast(`Email-ID does not exist`, {
                            className: 'custom-toaster toaster-error',
                        })
                    } else {
                        toast(`Error occurred while adding collaborators`, {
                            className: 'custom-toaster toaster-error',
                        })
                    }
                    reject(e)
                },
                onCompleted: (data) => {
                    toast(
                        `Successfully added ${users.length} collaborator${
                            users.length > 1 ? 's' : ''
                        }`,
                        { className: 'custom-toaster toaster-success' }
                    )
                    resolve(data)
                },
            })
        })
    }
    return { addCollaborator }
}

export function useFetchCollaboratorByTeamId() {
    const [getMembersByTeamId, membersById] = useLazyQuery<{
        team_members: ITeamMembers[]
    }>(GetTeamMemberByTeamId)
    const [members, setMembers] = useState<ITeamMembers[]>([])
    function fetchTeammembers(teamId: string) {
        if (!teamId) return
        if (teamId && teamId !== '') {
            getMembersByTeamId({
                fetchPolicy: 'no-cache',
                variables: {
                    teamId: teamId,
                },
                onCompleted: (data) => {
                    setMembers(data.team_members)
                },
            })
        }
    }

    useEffect(() => {
        if (!membersById?.data) return
        setMembers(membersById.data.team_members)
    }, [membersById])

    return {
        membersById: members,
        fetchTeammembers,
    }
}

export function useTeam() {
    const [fetchTeamById, teamById] = useLazyQuery<{
        teams_by_pk: {
            name: string
            id: string
            team_members: {
                user: {
                    id: string
                    email: string
                    name: string
                    other_data: {
                        profile_pic: string
                        thumbnail: string
                    }
                    organisation_members: {
                        role: string
                    }[]
                }
            }[]
        }
    }>(GetTeamDetailsById)
    function fetchTeam(teamId: string) {
        if (teamId === 'undefined') return
        if (!teamId && teamId === '') return
        if (teamId && teamId !== '') {
            fetchTeamById({
                fetchPolicy: 'no-cache',
                variables: {
                    id: teamId,
                },
            })
        }
    }
    return {
        team: teamById.data?.teams_by_pk,
        fetchTeam,
    }
}

export function useTeamsByMemberId() {
    const [teams, setTeams] = useState<{
        body: { data: ITeams[]; count: number }
    }>({
        body: {
            data: [],
            count: 0,
        },
    })

    async function fetchTeamsByMemberId(memberId: string) {
        try {
            const data = await axios.get<{
                body: { data: ITeams[]; count: number }
            }>(`${GET_TEAMS_BY_USER_ID}/${memberId}`, {
                headers: {
                    'x-auth-token': getLocalStorage('authToken'),
                },
            })

            if (data && data.data && data.data.body) {
                setTeams(data.data)
            }
        } catch (e) {
            console.log(e)
        }
    }
    return { fetchTeamsByMemberId, teams }
}

export function useGetInvitationsByEmail() {
    const [getInvitationsByEmail, invitation] = useLazyQuery<{
        invitations: {
            email: string
            id: string
            role: string
        }[]
    }>(GetInvitationsByEmail)
    function fetchInvitationsByEmail(emails: string[]) {
        getInvitationsByEmail({
            variables: {
                emails,
            },
        })
    }
    return {
        fetchInvitationsByEmail,
        invitations: invitation.data?.invitations || [],
    }
}

export function useInviteUsers() {
    const [sendInvitations] = useMutation(InviteUser)
    async function inviteUser(
        email: {
            email: string
            role?: string
        }[],
        role: string
    ) {
        return new Promise((resolve, reject) => {
            sendInvitations({
                variables: {
                    objects: email,
                },
                onError: (e) => {
                    if (e.message.includes('unexpected null value')) {
                        toast(`Email-ID does not exist`, {
                            className: 'custom-toaster toaster-error',
                        })
                    } else if (e.message.includes('permission has failed')) {
                        toast(
                            `Sorry, you currently don't have the necessary permissions to invite a user`,
                            {
                                className: 'custom-toaster toaster-error',
                            }
                        )
                    } else {
                        toast(`Error occurred while adding collaborators`, {
                            className: 'custom-toaster toaster-error',
                        })
                    }
                    reject(e)
                },
                onCompleted: (data) => {
                    toast(
                        `Sent invitation to ${
                            email.length > 1 ? email.length : email[0].email
                        } user${email.length > 1 ? 's' : ''}`,
                        {
                            className: 'custom-toaster toaster-success',
                        }
                    )
                    resolve(data)
                },
            })
        })
    }
    return { inviteUser }
}

export function useUpdateInvitedUserRole() {
    const [updateInvitedUserRoleMutation] = useMutation(UpdateInvitedUserRole)
    async function updateInvitedUserRole(
        id: string,
        role: string,
        name: string
    ) {
        return new Promise((resolve, reject) => {
            updateInvitedUserRoleMutation({
                variables: {
                    _eq: id,
                    role: role,
                },
                refetchQueries: ['getInvitationsByEmail'],
                onError: (e) => {
                    console.log(e)
                    if (e.message.includes('unexpected null value')) {
                        toast(`Email-ID does not exist`, {
                            className: 'custom-toaster toaster-error',
                        })
                    } else {
                        toast(`Error occurred while adding collaborators`, {
                            className: 'custom-toaster toaster-error',
                        })
                    }
                    reject(e)
                },
                onCompleted: (data) => {
                    toast(`Successfully updated ${name}`, {
                        className: 'custom-toaster toaster-success',
                    })
                    resolve(data)
                },
            })
        })
    }
    return { updateInvitedUserRole }
}

export function useGetCollectionsByTeamId() {
    const [getCollectionsByTeamId, collectionsByTeamId] = useLazyQuery<{
        collections: TGqlCollections[]
    }>(GetCollectionsByTeamId)
    function fetchCollectionsByTeamId(
        teamId: string,
        {
            status = {},
            order_by = {},
        }: {
            status?: {
                [key in statusKeys]?:
                    | (typeof statuses)[keyof typeof statuses]
                    | boolean
            }
            order_by?: { [key in orderByKeys]?: 'asc' | 'desc' }
        } = {}
    ) {
        getCollectionsByTeamId({
            variables: {
                teamId: teamId,
                status,
                order_by,
            },
        })
    }
    return {
        collectionsByTeamId: collectionsByTeamId.data?.collections || [],
        fetchCollectionsByTeamId,
    }
}
export function useGetProductsByTeamId() {
    const [getProductsByTeamId, productsByTeamId] = useLazyQuery<{
        new: {
            aggregate: {
                count: number
            }
        }
        inProgress: {
            aggregate: {
                count: number
            }
        }
        completed: {
            aggregate: {
                count: number
            }
        }
        products: NonNullable<GetDashboardProductQuery>['products']
    }>(GetProductByTeamId)
    function fetchProductsByTeamId(
        teamId: string,
        type?: 'team' | 'all',
        payload: {
            status?: {
                [key in statusKeys]?:
                    | (typeof statuses)[keyof typeof statuses]
                    | boolean
            }
            order_by?: {
                [key in orderByKeys]?: 'asc' | 'desc'
            }
        } = {
            status: {
                _is_null: false,
            },
            order_by: {
                name: 'asc',
            },
        }
    ) {
        getProductsByTeamId({
            fetchPolicy: 'cache-and-network',
            variables: {
                team_id:
                    !type || type === 'team'
                        ? { _eq: teamId }
                        : { _is_null: false },
                _and: { status: payload.status },
                order_by: payload.order_by,
            },
        })
    }
    return {
        new: productsByTeamId.data?.new?.aggregate?.count || 0,
        inProgress: productsByTeamId.data?.inProgress?.aggregate?.count || 0,
        completed: productsByTeamId.data?.completed?.aggregate?.count || 0,
        productsByTeamId: productsByTeamId?.data?.products || [],
        fetchProductsByTeamId,
    }
}
export function useGetTeamsByUserId() {
    const [getTeamsByUserId, teamsByUserId] = useLazyQuery<{
        teams: TGqlTeams[]
    }>(GetTeamByUserId)
    function fetchTeamsByUserId(userId: string) {
        getTeamsByUserId({
            variables: {
                userId: userId,
            },
        })
    }
    return {
        teamsByUserId: teamsByUserId.data?.teams,
        fetchTeamsByUserId,
    }
}

export function useGetTodosByUserId() {
    const [getTodosByUserId, todosByUserId] = useLazyQuery<{
        todos: TGqlTodos[]
    }>(GetTodosByUserId)
    const { organization_id } = useUser()
    function fetchTodosByUserId(
        userId: string,
        order_by: {
            [key in orderByKeys]?: 'asc' | 'desc'
        } = {
            created_at: 'asc',
        },
        status: {
            [key in statusKeys]?:
                | (typeof statuses)[keyof typeof statuses]
                | boolean
        } = {
            _is_null: false,
        }
    ) {
        getTodosByUserId({
            fetchPolicy: 'cache-and-network',
            variables: {
                userId: userId,
                organisation_id: organization_id,
                order_by,
                status,
            },
        })
    }
    return {
        todosByUserId: todosByUserId.data?.todos || [],
        fetchTodosByUserId,
    }
}

export function useFetchAnalytics(type: 'user' | 'team') {
    const { drawerTabs } = useStore()
    const location = useLocation()
    const [getAnalyticsOfTeams, analyticsOfTeams] =
        useLazyQuery<TGqlTeamAnalytics>(GetAnalyticsOfTeams)
    const [getAnalyticsOfUsers, analyticsOfUser] =
        useLazyQuery<TGqlUserAnalytics>(GetAnalyticsOfUsers)

    function fetchAnalytics(id: string) {
        if (
            type === 'user' &&
            (drawerTabs[0]?.type === 'individualCollaborator' ||
                location.pathname.includes('/user'))
        ) {
            getAnalyticsOfUsers({
                variables: {
                    userId: id,
                },
            })
        } else {
            if (
                drawerTabs[0]?.type === 'teams' ||
                location.pathname.includes('/team')
            ) {
                getAnalyticsOfTeams({
                    variables: {
                        teamId: id,
                    },
                })
            }
        }
    }
    return {
        fetchAnalytics,
        analyticsOfTeams: {
            ...analyticsOfTeams.data?.team[0],
            totalCollections:
                analyticsOfTeams.data?.totalCollections?.aggregate.count || 0,
            totalCompletedCollections:
                analyticsOfTeams.data?.totalCompletedCollections?.aggregate
                    .count || 0,
            totalCompletedCollectionsOnTime:
                analyticsOfTeams.data?.collectionsCompletedOnTime?.aggregate
                    .count || 0,
            totalCompletedCollectionsDelayed:
                analyticsOfTeams.data?.collectionsCompletedDelayed?.aggregate
                    .count || 0,
        },
        analyticsOfUser: {
            user: analyticsOfUser.data?.organisation_members[0]?.user,
            totalTodos: analyticsOfUser.data?.totalTodos?.aggregate.count || 0,
            totalCompletedTodos:
                analyticsOfUser.data?.totalCompletedTodos?.aggregate.count || 0,
            totalDelayedTodos:
                analyticsOfUser.data?.totalCompletedTodosDelayed?.aggregate
                    .count || 0,
            totalCompletedTodosOnTime:
                analyticsOfUser.data?.totalCompletedTodosOnTime?.aggregate
                    .count || 0,
        },
    }
}

export function useUpdateRole() {
    const [updateRoleMutation] = useMutation(UpdateOrgannisationMemberRole)
    async function updateRole(id: string, role: string, name: string) {
        return new Promise((resolve, reject) => {
            updateRoleMutation({
                variables: {
                    user_id: { _eq: id },
                    role: role,
                },
                refetchQueries: ['getTeamMembersByTeamId'],
                onError: (e) => {
                    console.log(e)
                    if (e.message.includes('unexpected null value')) {
                        toast(`User does not exist`, {
                            className: 'custom-toaster toaster-error',
                        })
                    } else {
                        toast(`Error occurred while adding collaborators`, {
                            className: 'custom-toaster toaster-error',
                        })
                    }
                    reject(e)
                },
                onCompleted: (data) => {
                    toast(`Successfully updated ${name}'s role`, {
                        className: 'custom-toaster toaster-success',
                    })
                    resolve(data)
                },
            })
        })
    }
    return { updateRole }
}

export function useRemoveCollaborator() {
    const [DeleteCollaboratorMutation] = useMutation(DeleteCollaborator)

    function removeCollaborator(
        user_id: string,
        team_id: string,
        onCompleted?: () => void
    ) {
        console.log({ user_id, team_id })
        DeleteCollaboratorMutation({
            variables: {
                user_id: {
                    _eq: user_id,
                },
                team_id: team_id,
            },
            onCompleted: (data) => {
                onCompleted && onCompleted()
            },
            onError: (e) => {
                console.log(e)
            },
            refetchQueries: [
                'getTeamMembersByTeamId',
                'GetTeamMembersByTeamId',
            ],
        })
    }
    return { removeCollaborator }
}

export function useInvite() {
    const [cancelInvitationMutation] = useMutation(CancelInvitation)
    const [resendInvitationMutation] = useMutation(ResendInvitation)
    function cancelInvite(email: string) {
        cancelInvitationMutation({
            variables: {
                email: {
                    _eq: email,
                },
            },
            onCompleted: (data) => {
                if (data.delete_invitations.affected_rows >= 0) {
                    toast('Invitation cancelled successfully', {
                        className: 'custom-toaster toaster-success',
                    })
                } else {
                    toast('Error occurred while cancelling invitation', {
                        className: 'custom-toaster toaster-error',
                    })
                }
            },
        })
    }
    function resendInvitation(email: string) {
        resendInvitationMutation({
            variables: {
                email: {
                    _eq: email,
                },
                updated_at: new Date().toISOString(),
            },
            onCompleted: (data) => {
                if (data.update_invitations.affected_rows >= 0) {
                    toast('Invitation Resent Successfully', {
                        className: 'custom-toaster toaster-success',
                    })
                } else {
                    toast('Error occurred while resending invitation', {
                        className: 'custom-toaster toaster-error',
                    })
                }
            },
        })
    }
    return { resendInvitation, cancelInvite }
}

export function useUpdateTeam() {
    const [updateTeamMutation] = useMutation(UpdateTeam)

    function updateTeam(
        payload: {
            name?: string
            deleted_at?: Date | null
            update_at?: Date | null
        },
        id: string,
        refetchQueries?: InternalRefetchQueriesInclude,
        onCompleted?: () => void
    ) {
        updateTeamMutation({
            variables: {
                _set: payload,
                _eq: id,
            },
            refetchQueries,
            onCompleted: () => {
                toast.success('Team Name Updated')
                onCompleted && onCompleted()
            },
        })
    }
    return { updateTeam }
}

export function useFetchEntityCount() {
    const { setTeamsListCount, setContributorsCount } = useStore()
    async function fetchData(url: string, type: 'TEAM' | 'COLLABORATOR') {
        try {
            const data = await axios.get<{
                body: {
                    totalUserCount?: number
                    count?: number
                }
            }>(url, {
                headers: {
                    'x-auth-token': getLocalStorage('authToken'),
                },
            })
            return { data, type }
        } catch (e) {
            console.log(e)
        }
    }
    async function getEntityCount(
        type: 'all' | 'collaborator' | 'teams' = 'all'
    ) {
        if (type === 'all') {
            const data = await Promise.all([
                fetchData(TEAM_COUNT, 'TEAM'),
                fetchData(COLLABORATOR_COUNT, 'COLLABORATOR'),
            ])
            setTeamsListCount({
                teamListTotalCount:
                    data.find((d) => d?.type === 'TEAM')?.data.data.body
                        .count || 0,
            })
            setContributorsCount({
                contributorsTotalCount:
                    data.find((d) => d?.type === 'COLLABORATOR')?.data.data
                        ?.body?.totalUserCount || 0,
            })
        }
        if (type === 'collaborator') {
            const data = await fetchData(COLLABORATOR_COUNT, 'COLLABORATOR')
            setContributorsCount({
                contributorsTotalCount:
                    data?.data.data?.body?.totalUserCount || 0,
            })
        }
        if (type === 'teams') {
            const data = await fetchData(TEAM_COUNT, 'TEAM')
            setTeamsListCount({
                teamListTotalCount: data?.data.data.body.count || 0,
            })
        }
    }
    return { getEntityCount }
}
