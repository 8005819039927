import * as React from 'react'

export const RecentActivityEmptyStateIco = () => (
    <svg width={40} height={40} fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M35.983 26.353a.835.835 0 0 1 .918.192.835.835 0 0 1 .15.927 19.424 19.424 0 0 1-10.24 9.797c-9.924 4.076-21.273-.664-25.35-10.587-4.075-9.923.665-21.272 10.588-25.348a.835.835 0 0 1 1.077 1.127 17.737 17.737 0 0 0-.316 14.288C16.556 25.8 26.93 30.1 35.983 26.353ZM1.68 19.302c-.002 9.81 7.95 17.764 17.76 17.766a17.83 17.83 0 0 0 15.106-8.452c-1.73.493-3.52.743-5.318.743-10.733 0-19.433-8.7-19.434-19.432a19.147 19.147 0 0 1 1.002-6.138A17.762 17.762 0 0 0 1.68 19.302Zm38.102-6.82a.927.927 0 0 0-.643-.2h-3.532l3.298-3.683.31-.342.2-.242c.055-.078.1-.162.133-.251a.834.834 0 0 0 .059-.3.626.626 0 0 0-.31-.602 1.863 1.863 0 0 0-.876-.167H34.28a.902.902 0 0 0-.61.184.618.618 0 0 0-.217.492c0 .284.095.457.284.518.272.077.553.111.835.1h2.673c-.112.156-.257.34-.435.552l-.693.785-.902 1.002-1.077 1.194c-.373.418-.618.696-.735.835a.785.785 0 0 0 .075 1.077c.22.166.494.246.769.226h4.868c.236.03.474-.03.668-.167A.635.635 0 0 0 40 13a.668.668 0 0 0-.217-.518Zm-16.794.226a.927.927 0 0 1 .643.2.668.668 0 0 1 .218.518.635.635 0 0 1-.218.493.952.952 0 0 1-.651.192h-4.877c-.275.02-.548-.06-.768-.226a.785.785 0 0 1-.075-1.077c.116-.139.361-.417.734-.835l1.078-1.194.927-1.002.684-.769c.179-.211.323-.395.435-.55h-2.673a2.68 2.68 0 0 1-.835-.101c-.189-.061-.284-.234-.284-.518a.618.618 0 0 1 .218-.492.902.902 0 0 1 .61-.184h4.1c.3-.016.602.041.876.167a.626.626 0 0 1 .31.601c-.002.103-.021.205-.06.3a1.11 1.11 0 0 1-.133.251l-.159.2-.308.343-3.3 3.683h3.508Zm7.516 4.309a.735.735 0 0 1 .518.159c.115.104.18.253.175.409a.51.51 0 0 1-.167.392.76.76 0 0 1-.517.15h-3.875a.902.902 0 0 1-.61-.175.626.626 0 0 1-.058-.835c.094-.122.292-.35.593-.685l.835-.952.718-.835.551-.618a4.95 4.95 0 0 0 .35-.442H26.88c-.225.01-.45-.015-.668-.076-.15-.055-.226-.194-.226-.417a.493.493 0 0 1 .176-.393.726.726 0 0 1 .484-.15h3.307c.241-.013.482.033.702.134.168.1.263.29.242.484a.669.669 0 0 1-.05.242.878.878 0 0 1-.109.2l-.159.193-.25.275-2.63 2.94h2.805Z"
            fill="#778CA2"
        />
    </svg>
)
