import { useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import ImageAnnotator from 'src/components-v2/image-annotator/image-annotator'
import { Pan } from 'src/components/svg-icons/3d-renderer/actions'
import { CommentAnnotationLG } from 'src/components/svg-icons/3d-renderer/comment-annotation'
import { Box, Button } from 'src/components/utility'
import { ImageZoomer } from 'src/screens/library/sub-screens/zoomer/zoomer'

function ImagePreview({
    url,
    entityId,
    fileId,
    isLibraryFile,
}: {
    url: string
    entityId: number
    fileId: number
    isLibraryFile: boolean
}) {
    const [searchParams] = useSearchParams()
    const [mode, setMode] = useState<'pan' | 'annotate'>(
        searchParams.get('mode') === 'annotation' ||
            searchParams.get('mode') === 'annotate'
            ? 'annotate'
            : 'pan' || 'pan'
    )
    const [zoomFunctions, setZoomFunctions] = useState<{
        zoomIn: () => void
        zoomOut: () => void
    }>({
        zoomIn: () => {},
        zoomOut: () => {},
    })

    return (
        <Box
            width={'100%'}
            textAlign="center"
            height="100%"
            position={'relative'}
        >
            {mode === 'pan' ? (
                <ImageZoomer
                    url={url}
                    height="100%"
                    showToolbar={false}
                    setZoomFunctions={setZoomFunctions}
                    enableFullScreenMode={false}
                />
            ) : (
                <ImageAnnotator
                    imageUrl={url}
                    entityName="sub_todo_sections"
                    entityId={entityId || 0}
                    fileId={fileId}
                    isLibraryFile={isLibraryFile}
                    key={url}
                    setZoomFunctions={setZoomFunctions}
                />
            )}
            <Box
                position="absolute"
                bg="transparent"
                right="16px"
                bottom="16px"
                display="flex"
                gridGap="8px"
                alignItems="center"
            >
                <Button
                    onClick={(e) => {
                        e.stopPropagation()
                        setMode('pan')
                    }}
                    bg={mode === 'pan' ? '#022143' : '#fff'}
                    border="1px solid #C2CFE0"
                    p="0px"
                    borderRadius="4px"
                    fontStyle="Rubik"
                    fontSize="12px"
                    color="#027AC3"
                    position="relative"
                    width="max-content"
                >
                    <Pan fill={mode === 'pan' ? '#022143' : '#fff'} />
                </Button>
                <Button
                    onClick={(e) => {
                        e.stopPropagation()
                        setMode('annotate')
                    }}
                    bg={mode === 'annotate' ? '#022143' : '#fff'}
                    border="1px solid #C2CFE0"
                    p="0px"
                    borderRadius="4px"
                    fontStyle="Rubik"
                    fontSize="12px"
                    color="#027AC3"
                    position="relative"
                    width="max-content"
                >
                    <CommentAnnotationLG
                        stroke={mode === 'annotate' ? '#fff' : '#778CA2'}
                        fill={mode === 'annotate' ? '#022143' : '#fff'}
                    />
                </Button>
                <Button
                    onClick={(e) => {
                        e.stopPropagation()
                        zoomFunctions?.zoomOut()
                    }}
                    bg="white"
                    border="1px solid #C2CFE0"
                    borderRadius="4px"
                    fontStyle="Rubik"
                    position="relative"
                    width="36px"
                    height="36px"
                    color="#778CA2"
                    fontSize="24px"
                >
                    -
                </Button>
                <Button
                    onClick={(e) => {
                        e.stopPropagation()
                        zoomFunctions?.zoomIn()
                    }}
                    bg="white"
                    border="1px solid #C2CFE0"
                    borderRadius="4px"
                    fontStyle="Rubik"
                    position="relative"
                    width="36px"
                    height="36px"
                    color="#778CA2"
                    fontSize="24px"
                >
                    +
                </Button>
            </Box>
        </Box>
    )
}

export default ImagePreview
