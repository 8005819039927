import { useState, useRef } from 'react'
import { IconDropdownButton } from 'src/components/buttons/icon-dropdown-button'
import { Box } from 'src/components/utility'
import { DropDownActions } from './dropdown-action'
import { getFormattedDate } from 'src/helpers'

export const DueDateDropdown = ({
    dueDate,
    setDueDate,
    rightOffset,
}: {
    dueDate: [Date | null, Date | null] | Date | null
    setDueDate: React.Dispatch<
        React.SetStateAction<[Date | null, Date | null] | Date | null>
    >
    rightOffset?: string
}) => {
    const [isDropDownActive, setIsDropDownActive] = useState(false)
    const dropdownButton = useRef<any>(null)

    const getFormattedDueDateTitle = () => {
        if (dueDate) {
            if (Array.isArray(dueDate)) {
                return `${dueDate[0] ? getFormattedDate(dueDate[0]) : ''} - ${
                    dueDate[1] ? getFormattedDate(dueDate[1]) : ''
                }`
            } else {
                return getFormattedDate(dueDate)
            }
        }
    }

    return (
        <Box
            id="duedate-dropdown"
            display="flex"
            justifyContent="center"
            alignItems="center"
            gridColumnGap="29px"
            position="relative"
        >
            <Box
                ref={dropdownButton}
                onClick={() => setIsDropDownActive(!isDropDownActive)}
            >
                <IconDropdownButton isActive={!!dueDate}>
                    {!!dueDate
                        ? 'Due on ' + getFormattedDueDateTitle()
                        : 'Choose Date Range'}
                </IconDropdownButton>
            </Box>

            {isDropDownActive && (
                <DropDownActions
                    setIsDropdownActive={setIsDropDownActive}
                    setDate={setDueDate}
                    active={isDropDownActive}
                    date={dueDate}
                    rightOffset={rightOffset}
                    selectRange={true}
                />
            )}
        </Box>
    )
}
