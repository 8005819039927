export function LogoutIco() {
    return (
        <svg
            width={18}
            height={18}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#prefix__clip0_1310_11128)" fill="#C2CFE0">
                <path d="M8.97 16.476H2.243a.748.748 0 01-.748-.748V2.273c0-.413.335-.748.748-.748H8.97a.747.747 0 100-1.495H2.243A2.245 2.245 0 000 2.273v13.455a2.245 2.245 0 002.243 2.243H8.97a.747.747 0 100-1.495z" />
                <path d="M17.777 8.468l-4.545-4.485a.747.747 0 10-1.05 1.064l3.249 3.206H6.728a.747.747 0 100 1.495h8.703l-3.248 3.205a.747.747 0 101.05 1.064l4.544-4.485a.749.749 0 000-1.064z" />
            </g>
            <defs>
                <clipPath id="prefix__clip0_1310_11128">
                    <path fill="#fff" d="M0 0h18v18H0z" />
                </clipPath>
            </defs>
        </svg>
    )
}
