import Calendar from 'react-calendar'
import type { SetStateAction } from 'react'
import { useRef } from 'react'
import { forwardRef } from 'react'
import { useEffect } from 'react'
import React from 'react'
import 'src/styles/v-calendar.styles.css'
import { Box } from '../utility'

export const VCalendar = forwardRef(
    (
        {
            top,
            left,
            setDate,
            date,
            disableCallback,
            isCalendarVisible,
            maxDate,
            minDate = new Date(new Date().setUTCHours(23, 59, 59, 999)),
            updateDate,
            selectRange = false,
            maxHeight = 'auto',
        }: {
            top: string
            left: string
            setDate?: React.Dispatch<SetStateAction<any>>
            date: [Date | null, Date | null] | Date | null
            disableCallback: () => void
            isCalendarVisible: boolean
            maxDate?: Date
            minDate?: Date
            updateDate?: (date: any) => void
            selectRange?: boolean
            maxHeight?: string
        },
        ref: HTMLDivElement | any
    ) => {
        const calendarRef = useRef<any | null>(null)
        function disableDropdown(event: Event | any) {
            if (
                ref !== null &&
                ref.current !== null &&
                calendarRef !== null &&
                calendarRef.current !== null
            ) {
                const isClickInsideElement = ref.current.contains(event.target)
                const isCalendarClicked =
                    typeof event?.target.className === 'string'
                        ? event?.target?.className?.includes('react-calendar')
                        : null
                if (!isClickInsideElement && !isCalendarClicked) {
                    if (event.target && event.target.tagName !== 'ABBR') {
                        disableCallback()
                    }
                }
            }
        }

        const dateChangeHandler = (date: any) => {
            if (setDate) {
                setDate(
                    selectRange
                        ? date
                        : new Date(new Date(date).setUTCHours(24, 59, 59, 999))
                )
            }
            if (updateDate) {
                updateDate(
                    selectRange
                        ? date
                        : new Date(new Date(date).setUTCHours(24, 59, 59, 999))
                )
            }
            disableCallback()
        }

        useEffect(() => {
            document.addEventListener('click', disableDropdown)
            return () => document.removeEventListener('click', disableDropdown)
        }, [])
        if (isCalendarVisible) {
            return (
                <Box
                    position="absolute"
                    top={top}
                    left={left}
                    zIndex={2}
                    onClick={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                    }}
                    maxHeight={maxHeight}
                    overflow={'auto'}
                    boxShadow="rgba(153, 155, 168, 0.25) 0px 2px 12px"
                >
                    <Calendar
                        minDate={minDate}
                        maxDate={maxDate}
                        inputRef={calendarRef}
                        onChange={dateChangeHandler}
                        value={date}
                        returnValue={selectRange ? 'range' : 'start'}
                        selectRange={selectRange}
                        showNavigation
                    />
                </Box>
            )
        }
        return <></>
    }
)
