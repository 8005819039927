import { Tab, Tabs } from '@mui/material'
import * as React from 'react'
import { Box } from 'src/components/utility'

interface ICommentSliderProps {
    currentTab: string
    setCurrentTab: (tab: string) => void
}

export default function CommentSlider(props: ICommentSliderProps) {
    const { currentTab, setCurrentTab } = props
    return (
        <Box>
            <Tabs
                value={currentTab}
                variant="scrollable"
                scrollButtons={false}
                onChange={(_event: any, newValue: any) => {
                    setCurrentTab(newValue)
                }}
                aria-label="collection-tabs"
                classes={{
                    root: 'collection-slider-root',
                }}
            >
                <Tab label={`All`} value={'all'} />
                <Tab label={`Mentions`} value={'mentions'} />
                <Tab label={`Annotations`} value={'annotations'} />
            </Tabs>
        </Box>
    )
}
