import { useApolloClient } from '@apollo/client'
import axios from 'axios'
import { useEffect, useRef, useState } from 'react'
import { toast } from 'react-toastify'
import { ExtensionIcoSM } from 'src/components/file-display/file-display-2'
import { Modal } from 'src/components/modals/modal'
import { Box, Input, Text } from 'src/components/utility'
import { getLocalStorage } from 'src/helpers'
import { getFileNameWithoutExtension } from 'src/helpers/get-filename-without-extension'
import { isStringEmpty } from 'src/helpers/is-string-empty'
import { RENAME_FILE, RENAME_LIBRARY_FILE } from 'src/services/api/endpoints'

function RenameFileModal({
    isOpen,
    onClose,
    fileName,
    fileId,
    isLibraryFile,
}: {
    isOpen: boolean
    onClose: () => void
    fileName: string
    fileId: number
    isLibraryFile: boolean
}) {
    const [updatedFileName, setUpdatedFileName] = useState(
        getFileNameWithoutExtension(fileName)
    )
    const [isUpdating, setIsUpdating] = useState(false)
    const inputRef = useRef<HTMLInputElement | null>(null)
    const client = useApolloClient()

    async function renameFileHandler(updatedName: string, extension: string) {
        if (!isStringEmpty(updatedName)) {
            let response
            try {
                setIsUpdating(true)
                if (isLibraryFile) {
                    response = await axios.post(
                        RENAME_LIBRARY_FILE + '/' + fileId,
                        {
                            name: updatedName + '.' + extension,
                        },
                        {
                            headers: {
                                'x-auth-token': getLocalStorage('authToken'),
                            },
                        }
                    )
                } else {
                    response = await axios.post(
                        RENAME_FILE + '/' + fileId,
                        {
                            name: updatedName + '.' + extension,
                        },
                        {
                            headers: {
                                'x-auth-token': getLocalStorage('authToken'),
                            },
                        }
                    )
                }
                if (response.status === 200) {
                    toast('File renamed successfully', {
                        className: 'custom-toaster toaster-success',
                    })
                    client.refetchQueries({
                        include: 'active',
                    })
                    onClose()
                }
            } catch {
                toast('Failed to rename the file', {
                    className: 'custom-toaster toaster-error',
                })
            } finally {
                setIsUpdating(false)
            }
        } else {
            toast('Please enter the name', {
                className: 'custom-toaster toaster-error',
            })
        }
    }
    const extension = fileName
        .split('.')
        [fileName.split('.').length - 1].toLowerCase()

    // Focus on input field when modal loads
    useEffect(() => {
        if (inputRef.current) {
            setTimeout(() => {
                inputRef!.current!.focus()
            }, 0)
        }
    }, [inputRef.current])

    return (
        <Modal
            disablePrimaryButton={fileName === updatedFileName}
            isModal={isOpen}
            onClose={onClose}
            onClick={() => renameFileHandler(updatedFileName, extension)}
            primaryButtonTitle="Save"
            secondaryButtonTitle="Cancel"
            isLoading={isUpdating}
            title="Rename File"
            maxWidth="300px"
            bg="white"
        >
            <Box
                px="24px"
                height="100%"
                mb="40px"
                mt="28px"
                width="60%"
                display="flex"
                alignItems="center"
                gridGap="12px"
            >
                <Box width={'max-content'}>
                    <ExtensionIcoSM type={extension} />
                </Box>

                <Input
                    ref={inputRef}
                    autoComplete="false"
                    id="collection_form_name"
                    value={updatedFileName}
                    bg="white"
                    onChange={(e) => setUpdatedFileName(e.target.value)}
                    color="primary"
                    border="none"
                    fontSize={'16px'}
                    py="4px"
                    px={'4px'}
                    placeholder="File Name"
                    className="placeholder-light"
                    minWidth="100px"
                    width={`${Math.max(100, updatedFileName.length * 8)}px`}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            renameFileHandler(updatedFileName, extension)
                        }
                    }}
                />

                <Text my="0px" color="textSecondary">
                    .{extension}
                </Text>
            </Box>
        </Modal>
    )
}

export default RenameFileModal
