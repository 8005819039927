import * as React from 'react'
import { DivisionLine, Modal } from 'src/components'
import { ColoredButton } from 'src/components/buttons/colored-button'
import { CustomDropdown } from 'src/components/custom-dropdown/custom-dropdown'
import { Slider } from 'src/components/slider/slider'
import { Box, Button, Input, Text } from 'src/components/utility'
import AddCollaboratorSlider from '../add-collaborator/add-collaborator-slider'
import { isEmailValid } from '../../function'
import { CrossSm } from 'src/components/svg-icons'
import { UserWarningIco } from 'src/components/svg-icons/user-wraning'
import CollaboratorCard from '../add-collaborator/collaborator-card'
import {
    useGetInvitationsByEmail,
    useInviteUsers,
    useUpdateInvitedUserRole,
} from '../../hooks'
import { toast } from 'react-toastify'
import { useLazyQuery } from '@apollo/client'
import { CheckInvitationExist } from 'src/services/graphql/query/get-invitations'
import { roles } from 'src/helpers'

export interface IInviteCollaboratorProps {
    isModalOpen: boolean
    onClose: (isOpen: boolean) => void
}

export default function InviteCollaborator(props: IInviteCollaboratorProps) {
    const [selectedRole, setSelectedRole] = React.useState('Admin')
    const [users, setUsers] = React.useState<
        {
            id: string
            email: string
            role: string
        }[]
    >([])
    const [countOfRolesSelected, setCountOfRolesSelected] = React.useState({
        admin: 0,
        manager: 0,
        designer: 0,
    })
    const { fetchInvitationsByEmail, invitations } = useGetInvitationsByEmail()
    const [getInvitation, invitationExists] = useLazyQuery<{
        invitations: Array<{ email: string }>
        users: Array<{ email: string }>
    }>(CheckInvitationExist)
    const { updateInvitedUserRole } = useUpdateInvitedUserRole()
    const { inviteUser } = useInviteUsers()

    const [currentTab, setCurrentTab] = React.useState('all')
    const [query, setQuery] = React.useState('')
    async function handleOnInvite() {
        if (users.length <= 0) return
        const emails = users.map((u) => ({
            email: u.email,
            role: u.role.toLowerCase(),
        }))
        try {
            await inviteUser(emails, selectedRole)
            fetchInvitationsByEmail(emails.map((email) => email.email))
            setUsers([])
            setQuery('')
        } catch (e) {}
    }

    function addTag() {
        if (users.filter((u) => u.email === query.toLowerCase()).length === 0) {
            if (!isEmailValid(query)) {
                return toast('Invalid email address', {
                    className: 'custom-toaster toaster-error',
                })
            }
            getInvitation({
                variables: {
                    email: query.toLowerCase(),
                },
                onCompleted: (data) => {
                    if (data.invitations.length > 0) {
                        toast('User already invited to the organization ', {
                            className: 'custom-toaster toaster-error',
                        })
                    } else if (data.users.length > 0) {
                        toast('User already added to the organization ', {
                            className: 'custom-toaster toaster-error',
                        })
                    } else {
                        setCountOfRolesSelected({
                            ...countOfRolesSelected,
                            [selectedRole.toLowerCase()]:
                                countOfRolesSelected[
                                    selectedRole.toLowerCase() as keyof typeof countOfRolesSelected
                                ] + 1,
                        })
                        setUsers([
                            ...users,
                            {
                                id: users.length.toString(),
                                email: query.toLowerCase(),
                                role: selectedRole,
                            },
                        ])
                        setQuery('')
                    }
                },
            })
        } else {
            toast('User already added', {
                className: 'custom-toaster toaster-error',
            })
        }
    }

    return (
        <Modal
            title="Invite Collaborator to Virtu"
            isModal={props.isModalOpen}
            onClick={() => {}}
            onClose={() => {
                setUsers([])
                props.onClose(false)
            }}
            disableActions
            primaryButtonTitle=""
            secondaryButtonTitle=""
            bg="white"
        >
            <Box maxHeight="" bg="white" px="24px" width="100%" py="16px">
                <Text color="primary">Invite</Text>
                <Box
                    display="flex"
                    justifyContent="space-around"
                    gridColumnGap="10px"
                    alignItems="center"
                    height="100%"
                >
                    <Box
                        width="100%"
                        bg="#F2F4F660"
                        border="solid"
                        borderWidth={1}
                        borderColor="#203C5A30"
                        borderRadius="4px"
                        display="flex"
                        alignItems="center"
                        flexWrap="wrap"
                        gridRowGap="4px"
                        maxWidth="440px"
                        px="4px"
                        py="4px"
                    >
                        <Box width="100%" display="flex" position="relative">
                            <Input
                                bg="transparent"
                                border="none"
                                placeholder={'Email Address'}
                                fontSize="14px"
                                width="100%"
                                value={query}
                                onKeyUp={(e) => {
                                    if (e.key === 'Enter') {
                                        addTag()
                                    }
                                }}
                                onKeyDown={(e) => {
                                    if (
                                        e.key === 'Backspace' &&
                                        query.length <= 0
                                    ) {
                                        setCountOfRolesSelected({
                                            ...countOfRolesSelected,
                                            [users[
                                                users.length - 1
                                            ]?.role?.toLowerCase()]:
                                                countOfRolesSelected[
                                                    users[
                                                        users.length - 1
                                                    ]?.role?.toLowerCase() as keyof typeof countOfRolesSelected
                                                ] - 1,
                                        })
                                        setUsers(
                                            users.slice(0, users.length - 1)
                                        )
                                    }
                                }}
                                onChange={(e) => setQuery(e.target.value)}
                            ></Input>
                            <CustomDropdown
                                items={['Admin', 'Manager', 'Designer']}
                                label=""
                                height="100%"
                                onItemClicked={(selectedRole) =>
                                    setSelectedRole(selectedRole)
                                }
                                selectedText={selectedRole}
                            />
                        </Box>
                    </Box>
                    <ColoredButton
                        variant="primary"
                        onClick={() => handleOnInvite()}
                        disabled={users.length <= 0}
                    >
                        Send Invite
                    </ColoredButton>
                </Box>
                <Box
                    mt="12px"
                    maxWidth="560px"
                    display="flex"
                    flexDirection="row"
                    flexWrap="wrap"
                    gridRowGap="4px"
                    maxHeight={window.innerHeight >= 800 ? '100px' : '50px'}
                    overflowX={'auto'}
                >
                    {users.map((user) => (
                        <TagComponent
                            key={user.id}
                            user={user}
                            onDelete={() => {
                                setUsers(users.filter((u) => u.id !== user.id))
                            }}
                        />
                    ))}
                </Box>
                <Box
                    mt="12px"
                    display="flex"
                    gridColumnGap="4px"
                    alignItems="center"
                >
                    <Box
                        width="24px"
                        height="24px"
                        borderRadius="50%"
                        bg="panelBackground"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                    >
                        <UserWarningIco />
                    </Box>
                    <Text color="warning" fontSize="12px" my="0px">
                        Be careful when sharing with guests outside of virtu.com
                    </Text>
                </Box>
                <Box my="24px">
                    <DivisionLine />
                </Box>
                <Slider
                    element={
                        <AddCollaboratorSlider
                            currentTab={currentTab}
                            onTabChange={(tab) => {
                                setCurrentTab(tab.tab)
                            }}
                            tabList={[
                                {
                                    name: 'All',
                                    value: 'all',
                                    noOfItems: invitations.length || 0,
                                },
                                {
                                    name: 'Admin',
                                    value: 'admin',
                                    noOfItems:
                                        invitations?.filter(
                                            (u) =>
                                                u.role.toLowerCase() ===
                                                roles.admin
                                        ).length || 0,
                                },
                                {
                                    name: 'Manager',
                                    value: 'manager',
                                    noOfItems:
                                        invitations?.filter(
                                            (u) =>
                                                u.role.toLowerCase() ===
                                                roles.manager
                                        ).length || 0,
                                },
                                {
                                    name: 'Designer',
                                    value: 'designer',
                                    noOfItems:
                                        invitations?.filter(
                                            (u) =>
                                                u.role.toLowerCase() ===
                                                roles.designer
                                        ).length || 0,
                                },
                            ]}
                        />
                    }
                />
                <Box
                    mt="12px"
                    display="flex"
                    flexDirection="column"
                    gridRowGap={'4px'}
                    minHeight={window.innerHeight < 730 ? '200px' : '300px'}
                    height={window.innerHeight < 730 ? '200px' : '300px'}
                    maxHeight={window.innerHeight < 730 ? '200px' : '300px'}
                    overflowX="auto"
                >
                    {invitations.length > 0 &&
                        invitations
                            .filter((u) =>
                                currentTab !== 'all'
                                    ? u.role.toLowerCase() === currentTab
                                    : u.role
                            )
                            .map((user) => {
                                return (
                                    <CollaboratorCard
                                        onRoleChange={(role) =>
                                            updateInvitedUserRole(
                                                user.id,
                                                role!.toLowerCase(),
                                                user.email
                                            )
                                        }
                                        collaborator={{
                                            email: user.email,
                                            id: user.id,
                                            name: '',
                                            role: user.role,
                                            other_data: {
                                                profile_pic: '',
                                                thumbnail: '',
                                            },
                                        }}
                                    />
                                )
                            })}
                </Box>
            </Box>
        </Modal>
    )
}

function TagComponent(props: {
    user: {
        id: string
        email: string
        role: string
    }
    onDelete: (id: number) => void
}) {
    return (
        <Box display="inline-block" mr={'8px'}>
            <Box
                px="8px"
                width="max-content"
                borderRadius="20px"
                py="4px"
                display="flex"
                fontFamily="Rubik"
                fontSize="12px"
                color="textTags"
                alignItems="center"
                justifyContent="space-between"
                bg={'#FFE395'}
            >
                {props.user.email} | {props.user.role}
                <Button
                    onClick={() => props.onDelete(Number(props.user.id))}
                    bg="transparent"
                    display="flex"
                    type="button"
                    justifyContent="center"
                    alignItems="center"
                    border="none"
                >
                    <CrossSm />
                </Button>
            </Box>
        </Box>
    )
}
