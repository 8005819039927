import React, { useState } from 'react'
import { DueDateDropdown } from 'src/components/collections/actions/due-date.tsx/due-date-dropdown'
import { ManagerDropdown } from 'src/components/collections/actions/manager/manager-dropdown'
import { SortDropdown } from 'src/components/collections/actions/sort/sort-dropdown'
import { Box, Button, Text } from 'src/components/utility'
import type { DueDateType, ManagerType } from '../collections/collections.types'
import CreateProductModal from 'src/components-v2/modals/create-product-modal'

function CollectionSortingAndFilter({
    totalProducts,
    teamId,
    setDueDate,
    dueDate,
    setManagers,
    managers,
    collectionId,
    collectionName,
}: {
    totalProducts: number
    teamId: number
    setDueDate: React.Dispatch<React.SetStateAction<DueDateType>>
    dueDate: DueDateType
    setManagers: React.Dispatch<React.SetStateAction<ManagerType[]>>
    managers: Array<ManagerType>
    collectionId: number
    collectionName: string
}) {
    const resetFilters = () => {
        setManagers([])
        setDueDate(null)
    }

    const [showCreateProductModal, setShowCreateProductModal] = useState(false)

    return (
        <Box display="flex" alignItems="center" justifyContent="space-between">
            <Box display="flex" gridGap="16px" alignItems="center">
                <Text my="0px" color="primary" fontSize="16px">
                    {totalProducts} Product
                    {totalProducts > 1 ? 's' : ''}
                </Text>
                <Button
                    height="100%"
                    bg="transparent"
                    fontStyle="Rubik"
                    color="links"
                    position="relative"
                    onClick={() => setShowCreateProductModal(true)}
                    border="none"
                >
                    + Create New
                </Button>
            </Box>
            <Box display="flex" gridGap="16px" alignItems="center">
                {(managers.length > 0 || dueDate) && (
                    <Button
                        height="20px"
                        bg="transparent"
                        border="none"
                        fontStyle="Rubik"
                        fontSize="12px"
                        color="links"
                        position="relative"
                        width="max-content"
                        onClick={resetFilters}
                    >
                        Reset Filters
                    </Button>
                )}
                <SortDropdown />
                <DueDateDropdown setDueDate={setDueDate} dueDate={dueDate} />
                <ManagerDropdown
                    setManagers={setManagers}
                    managers={managers}
                />
            </Box>
            {showCreateProductModal && (
                <CreateProductModal
                    isOpen={showCreateProductModal}
                    onClose={() => setShowCreateProductModal(false)}
                    initialParentCollection={{
                        id: collectionId,
                        name: collectionName,
                        team_id: teamId,
                    }}
                />
            )}
        </Box>
    )
}

export default CollectionSortingAndFilter
