export function LogoIco() {
    return (
        <svg
            width={89}
            height={24}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M32.711 18.735L28.821 6h2.475l3.184 10.47L37.717 6h2.476l-3.891 12.735h-3.59zM43.824 18.735V6h2.406v12.735h-2.406zM51.004 18.735V6h5.376c.124 0 .29.006.496.018.212.006.4.023.566.053.76.118 1.382.368 1.866.752.489.383.849.866 1.078 1.45.23.578.345 1.223.345 1.937 0 1.067-.265 1.98-.796 2.741-.53.755-1.361 1.22-2.493 1.397l-1.062.071H53.41v4.316h-2.405zm7.18 0l-2.511-5.182 2.476-.478 2.76 5.66h-2.724zm-4.775-6.562h2.865c.124 0 .26-.006.407-.018.148-.012.283-.035.407-.07.324-.089.575-.236.752-.443.176-.212.297-.445.362-.698.07-.26.106-.504.106-.734a2.7 2.7 0 00-.106-.726 1.69 1.69 0 00-.362-.698c-.177-.212-.428-.363-.752-.451a2.085 2.085 0 00-.407-.07 5.131 5.131 0 00-.407-.019H53.41v3.927zM67.929 18.735V8.246h-4.015V6h10.435v2.246h-4.015v10.489H67.93zM83.075 19c-1.026 0-1.928-.206-2.706-.619a4.551 4.551 0 01-1.822-1.76c-.436-.754-.654-1.642-.654-2.662V6.018L80.333 6v7.942c0 .418.071.799.213 1.14.141.342.336.637.583.885.254.247.546.439.876.575.336.13.693.194 1.07.194.39 0 .749-.068 1.079-.203.336-.136.628-.327.876-.575.247-.248.439-.543.574-.884.142-.342.213-.72.213-1.132V6h2.44v7.96c0 1.02-.218 1.907-.654 2.661a4.551 4.551 0 01-1.822 1.76c-.778.413-1.68.62-2.706.62z"
                fill="#022143"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.624 11.766L19.503 6.1l-8.635-4.804L1.235 6.8v10.971l8.39 4.702V11.766zM20.14 6.454zM.415 5.847L10.457.108a.823.823 0 01.808-.004l9.475 5.271a.823.823 0 01.01 1.434l-9.89 5.673v10.693a.823.823 0 01-1.226.719L.42 18.73A.824.824 0 010 18.011V6.562c0-.296.158-.569.415-.715z"
                fill="#022143"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.258 8.612a.618.618 0 01.848-.211l4.907 2.944a.618.618 0 01-.635 1.06L5.47 9.46a.618.618 0 01-.212-.848zM13.67 13.884v8.124l4.68-2.52a4.94 4.94 0 002.597-4.35V9.735l-7.276 4.15zm7.28-5.574l-8.514 4.856v9.532a.823.823 0 001.213.725l5.286-2.847a6.176 6.176 0 003.247-5.437V9.025a.823.823 0 00-1.231-.715z"
                fill="#022143"
            />
        </svg>
    )
}
