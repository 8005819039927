import { formatDistance, format, subDays } from 'date-fns'
import { getImageUrl } from 'src/helpers'
import { NotificationParser } from 'src/helpers/notification-parser'
import { DivisionLine } from '../division-line/division-line'
import { ProfileImg } from '../profile-img/profile-img'
import { Box, Text } from '../utility'
import { useEffect, useState } from 'react'
import useIntersectionObserver from 'src/helpers/hooks/use-intersection-observer'

export function ActivityFeed({
    activity,
    fontSize = '12px',
    fetchMore,
}: {
    activity: Activity[]
    fontSize?: FontSize
    fetchMore?: () => void
}) {
    const [offset, setOffset] = useState(1)

    const { lastElementRef, isIntersecting } = useIntersectionObserver({
        isLoading: false,
    })

    useEffect(() => {
        if (isIntersecting) {
            setOffset((prev) => prev * 2)
            fetchMore && fetchMore()
        }
    }, [isIntersecting])

    return (
        <>
            {activity.map((feed, key: number) => {
                const distance = formatDistance(
                    subDays(new Date(feed.created_at), 0),
                    new Date(),
                    { addSuffix: true }
                )
                const _time = format(new Date(feed.created_at), 'hh:mm a')

                return (
                    <>
                        <Box
                            key={key}
                            ref={lastElementRef}
                            display="flex"
                            gridColumnGap="12px"
                        >
                            <ProfileImg
                                src={
                                    feed.user &&
                                    feed.user.other_data &&
                                    feed.user?.other_data?.profile_pic
                                        ? getImageUrl(
                                              feed.user.other_data.profile_pic
                                          )
                                        : ''
                                }
                                name={feed.user.name}
                                size="24px"
                            />

                            <Box
                                display="flex"
                                alignItems="center"
                                justifyContent="start"
                            >
                                <Text
                                    my="0px"
                                    fontSize={fontSize || '12px'}
                                    lineHeight="1.5"
                                    color="primary"
                                >
                                    <NotificationParser
                                        comment={feed.display_string}
                                    />
                                    <span
                                        style={{
                                            color: '#817E7E',
                                            fontSize: '12px',
                                            marginLeft: '4px',
                                        }}
                                    >
                                        {' '}
                                        {distance}
                                    </span>
                                </Text>
                            </Box>
                        </Box>
                        <DivisionLine color="#E8ECEF" width={1} />
                    </>
                )
            })}
        </>
    )
}
