import * as React from 'react'

export const CalendarIco = ({ color }: { color?: string }) => (
    <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7 5a1 1 0 0 1 2 0v1h6V5a1 1 0 1 1 2 0v1h2a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h2V5ZM5.6 9.4V7.6h12.8v1.8H5.6Zm0 1.6v7.4h12.8V11H5.6Z"
            fill={color || '#778CA2'}
        />
    </svg>
)
