import * as React from 'react'

export const CloudUpload = () => (
    <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M12.4 6c-1.59 0-2.97.743-3.956 1.838-.082-.01-.155-.038-.244-.038-1.32 0-2.4 1.08-2.4 2.4-1.038.633-1.8 1.702-1.8 3 0 1.98 1.62 3.6 3.6 3.6H10a.6.6 0 1 0 0-1.2H7.6a2.393 2.393 0 0 1-2.4-2.4c0-.996.605-1.847 1.462-2.212l.413-.17-.056-.45C7.007 10.27 7 10.215 7 10.2a1.19 1.19 0 0 1 1.481-1.162l.375.093.225-.3A4.205 4.205 0 0 1 12.4 7.2a4.202 4.202 0 0 1 4.069 3.169l.112.469.506-.02c.127-.006.155-.018.113-.018 1.331 0 2.4 1.069 2.4 2.4s-1.069 2.4-2.4 2.4h-2.4a.6.6 0 1 0 0 1.2h2.4c1.98 0 3.6-1.62 3.6-3.6 0-1.894-1.493-3.41-3.356-3.544C16.71 7.545 14.756 6 12.4 6Zm.707 5.507a1 1 0 0 0-1.414 0l-1.01 1.01a.4.4 0 0 0 .283.683h.834v4.2a.6.6 0 1 0 1.2 0v-4.2h.834a.4.4 0 0 0 .283-.683l-1.01-1.01Z"
            fill="#778CA2"
        />
    </svg>
)
