import * as React from 'react'

export const BrandIco = () => (
    <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M12.768 4a.749.749 0 0 0-.501.192L4.47 12.075a.68.68 0 0 0-.01.95l.01.011 6.494 6.494.011.01a.68.68 0 0 0 .95-.01l7.883-7.797a.749.749 0 0 0 .192-.501V5a1 1 0 0 0-1-1h-6.232Zm.29 1.367h5.575v5.576l-7.178 7.134-5.532-5.532 7.134-7.178Zm3.524 1.367a.685.685 0 0 0-.684.684.685.685 0 0 0 1.367 0 .685.685 0 0 0-.683-.684Z"
            fill="#778CA2"
        />
    </svg>
)
