import type { StatusTypes } from 'src/components-v2/todo/todo.types'
import { useState } from 'react'
import useUpdateProduct from 'src/components-v2/cards/hooks/useUpdateProduct'
import { Box, Button } from 'src/components/utility'
import { productAndtodoStatus } from 'src/components/collections/collections.helper'
import ProductStatusDropdown from './product-status-dropdown'
import useStore from 'src/store/hooks/useStore'
import { useUser } from 'src/helpers'

function ProductStatus({
    productStatus,
    productId,
    approverId,
}: {
    productStatus: StatusTypes | null | undefined
    productId: number
    approverId: number
}) {
    const [isStatusDropdownActive, setIsStatusDropdownActive] = useState(false)
    const { updateProductVariantStatus } = useUpdateProduct()
    const { updateGlobalSearchResults } = useStore()
    const { userId } = useUser()

    if (!productStatus) return null

    const isProductApprover = Number(userId) === approverId

    return (
        <Box position="relative">
            <Button
                onClick={() => {
                    setIsStatusDropdownActive(!isStatusDropdownActive)
                }}
                bg="transparent"
                border="none"
                fontStyle="Rubik"
                fontSize="12px"
                position="relative"
                width="max-content"
                id="status-dropdown-btn"
                display="flex"
                style={{ alignItems: 'center', gap: '4px' }}
            >
                {productAndtodoStatus(
                    productStatus,
                    isProductApprover,
                    false,
                    true
                )}
            </Button>
            {isStatusDropdownActive ? (
                <ProductStatusDropdown
                    active={isStatusDropdownActive}
                    setIsDropdownActive={setIsStatusDropdownActive}
                    onUpdate={(status) =>
                        updateProductVariantStatus({
                            id: productId,
                            status,
                            onCompleted: () => {
                                updateGlobalSearchResults({
                                    type: 'products',
                                    updatedKey: 'status',
                                    value: status,
                                    id: String(productId),
                                })
                            },
                        })
                    }
                />
            ) : null}
        </Box>
    )
}

export default ProductStatus
