import { useQuery } from '@apollo/client'
import HardBreak from '@tiptap/extension-hard-break'
import Mention from '@tiptap/extension-mention'
import Placeholder from '@tiptap/extension-placeholder'
import type { Editor } from '@tiptap/react'
import { useEditor } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import axios from 'axios'
import { toast } from 'react-toastify'
import suggestions from 'src/components/comments/suggestions'
import { debugLog, getLocalStorage } from 'src/helpers'
import { ADD_COMMENT, DELETE_COMMENT } from 'src/services/api/endpoints'
import { GetOrgMembers } from 'src/services/graphql/query/get-users'
import { useCommentsStore } from 'src/store/hooks/useCommentsStore'

function useCommentEditor({
    team,
    entityId,
    entityName,
}: {
    team: any
    entityName: string
    entityId: number
}) {
    const { data: orgMembersData } = useQuery(GetOrgMembers)
    const users = orgMembersData?.organisation_members || []
    const { removeComment } = useCommentsStore()

    const extensions = [
        StarterKit,
        Placeholder.configure({
            placeholder: 'Write a comment...',
        }),
        HardBreak.extend({
            addKeyboardShortcuts() {
                return {
                    Enter: () => {
                        onComment(this.editor as Editor)
                        return true
                    },
                }
            },
        }),
        Mention.configure({
            HTMLAttributes: {
                class: 'mention',
            },
            suggestion: {
                items: ({ query }: { query: string }) => {
                    if (!query && team) {
                        return team?.team_members
                            ? [
                                  {
                                      user: {
                                          name: 'Team',
                                          id: team.id,
                                          email: '',
                                      },
                                  },
                                  ...team.team_members,
                              ]
                            : [
                                  {
                                      user: {
                                          name: 'Team',
                                          id: team.id,
                                          email: '',
                                      },
                                  },
                              ]
                    }
                    const _members = users ? users : []
                    const members = !!team
                        ? [
                              {
                                  user: {
                                      name: 'Team',
                                      id: team.id,
                                      email: '',
                                  },
                              },
                              ..._members,
                          ]
                        : _members

                    return members.filter((member: any) =>
                        member.user.name
                            .toLowerCase()
                            .includes(query.toLowerCase())
                    )
                },
                ...suggestions,
            },
        }),
    ]

    const editor = useEditor(
        {
            extensions,
            autofocus: true,
        },
        [team?.id, users]
    )

    async function onComment(editor: Editor | null) {
        if (!editor) return
        if (editor?.getText() && editor?.getText().length <= 0) {
            toast('Please enter the comment. ', {
                className: 'custom-toaster toaster-error',
            })
            return
        }
        const json = editor?.getJSON()
        const commentArr: any[] = []
        if (json) {
            json.content?.forEach((content) => {
                content.content?.forEach((item) => {
                    if (item.type === 'mention') {
                        commentArr.push({
                            type: 'mention',
                            name:
                                item.attrs?.label === 'Team'
                                    ? team?.name || item.attrs?.label
                                    : item.attrs?.label,
                            entity_id:
                                item.attrs?.label === 'Team' && team?.id
                                    ? team?.id
                                    : item.attrs?.id,
                            entity_name:
                                item.attrs?.label === 'Team'
                                    ? 'teams'
                                    : 'users',
                        })
                    } else if (item.type === 'hardBreak') {
                        commentArr.push({ type: 'text', text: '\n' })
                    } else {
                        if (item.text && item.text?.trim().length !== 0) {
                            commentArr.push(item)
                        }
                    }
                })
            })
            if (commentArr.length > 0) {
                try {
                    await axios.post(
                        ADD_COMMENT,
                        {
                            comment: commentArr,
                            entity_name: entityName,
                            entity_id: entityId,
                        },
                        {
                            headers: {
                                'x-auth-token': getLocalStorage('authToken'),
                            },
                        }
                    )
                    editor?.commands.clearContent(true)
                } catch (e) {
                    toast('Failed to add comment. ', {
                        className: 'custom-toaster toaster-error',
                    })
                    debugLog(e)
                }
            }
        }
        return
    }

    async function onDelete(commentId: string) {
        try {
            const response = await axios.post(
                DELETE_COMMENT,
                {
                    comment_id: commentId,
                },
                {
                    headers: {
                        'x-auth-token': getLocalStorage('authToken'),
                    },
                }
            )
            if (response.status === 200) {
                removeComment({
                    commentId,
                    entity_id: String(entityId),
                    entity_name: entityName,
                })
                toast('Comment deleted successfully. ', {
                    className: 'custom-toaster toaster-success',
                })
            }
        } catch (e) {
            toast('Failed to delete comment. ', {
                className: 'custom-toaster toaster-error',
            })
        }
    }

    return { editor, onComment, onDelete }
}

export default useCommentEditor
