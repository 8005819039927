import type {
    Callback,
    Delay,
    TimeOutSetter,
    TimeOutRef,
} from './types/debounce'
/**
 * @param {Callback} callback
 * @param {Delay} delay
 * @param {TimeOutSetter} timeOutSetter
 * @param {TimeOutRef} timeOutRef
 * @returns {Function} function
 */
export function debounce(
    callback: Callback,
    delay: Delay,
    timeOutSetter: TimeOutSetter,
    timeOutRef: TimeOutRef
): Function {
    return function (...args: any) {
        if (timeOutRef) {
            clearTimeout(timeOutRef)
            timeOutSetter(setTimeout(() => callback.apply(null, args), delay))
        } else {
            timeOutSetter(setTimeout(() => callback.apply(null, args), delay))
        }
    }
}
