import axios from 'axios'
import { toast } from 'react-toastify'
import { debugLog, getLocalStorage } from 'src/helpers'
import { FILE_TYPES, SUPPORT_FILE_TYPES } from 'src/helpers/enums'
import { getImageUrl, getLibraryFileUrl } from 'src/helpers/get-image-url'
import {
    DOWNLOAD_ANNOTATION_FILE_IMAGE,
    GET_FILE_REQUEST_ID_FOR_ANNOTATION,
    UPLOAD_FILE_TO_ANNOTATION,
} from 'src/services/api/endpoints'

export async function generateRequestId({
    totalFiles,
    entity_id = 0,
}: {
    totalFiles: number
    entity_id?: number
}): Promise<string | null> {
    try {
        const { data, status } = await axios.post(
            GET_FILE_REQUEST_ID_FOR_ANNOTATION,
            {
                entity_name: 'media',
                entity_id: entity_id,
                number_of_files: totalFiles,
                action: 'INSERT',
            },
            {
                headers: {
                    'x-auth-token': getLocalStorage('authToken'),
                },
            }
        )
        if (status === 200) {
            return data.data.request_id
        } else {
            return null
        }
    } catch (e) {
        debugLog(e)
        toast('Failed to upload the files', {
            className: 'toaster-error',
        })
        return null
    }
}

export async function uploadFileToAnnotation({
    file,
    requestId,
    uploadProgress,
}: {
    file: File
    requestId: string
    uploadProgress?: (progress: any) => void
}): Promise<{ id: number; name: string; gid: string } | null | undefined> {
    try {
        const formData = new FormData()
        formData.append('toupload', file)
        formData.append('type', 'document')

        const { data, status } = await axios.request({
            url: UPLOAD_FILE_TO_ANNOTATION,
            method: 'post',
            headers: {
                'x-auth-token': getLocalStorage('authToken'),
                'request-id': requestId,
            },
            data: formData,
            onUploadProgress: (progress: any) => {
                uploadProgress && uploadProgress(progress)
            },
        })
        if (status === 200) {
            return data.data
        } else {
            return null
        }
    } catch (e) {
        debugLog(e)
        toast('Failed to upload the files', {
            className: 'toaster-error',
        })
    }
}

export function getAnnotationFileImageUrl(
    path: string,
    entityName: string
): string {
    if (!path) return ''
    if (path.length <= 0) return ''
    const firstLetter = path.slice(0, 1)
    if (firstLetter !== '/') {
        path = `/${path}`
    }
    const authToken = getLocalStorage('authToken')
    if (authToken) {
        return (
            DOWNLOAD_ANNOTATION_FILE_IMAGE +
            '/' +
            entityName +
            path +
            '?token=' +
            authToken
        )
    }
    return ''
}

export function checkFileType(name: string) {
    const nameSplit = name.split('.')
    const fileType = nameSplit[nameSplit.length - 1].toLowerCase()
    if (
        SUPPORT_FILE_TYPES.includes(fileType) &&
        window.location.pathname === '/support'
    ) {
        return true
    } else if (FILE_TYPES.includes(fileType)) {
        return true
    }
    return false
}

export const getThumbnail = (_file: any) => {
    if (_file?.from_library) {
        return getLibraryFileUrl(_file?.thumbnail || _file?.gid || _file?.url)
    }
    return getImageUrl(_file?.thumbnail || _file?.gid || _file?.url)
}
