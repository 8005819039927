import * as React from 'react'
import { DeleteIco, SettingsIco } from 'src/components/svg-icons'
import {
    Download,
    UploadFile,
} from 'src/components/svg-icons/3d-renderer/actions'
import { Box, Button, Image, Input, Link, Text } from 'src/components/utility'
import { useConfiguratorContext } from '../../configurator.context'
import { getLocalImageUrl } from 'src/helpers/get-image-url'
import { DivisionLine } from 'src/components/division-line/division-line'
import { uuid } from 'src/helpers'
import { captureImage, createMaterial } from '../../helper/create-material'
import * as THREE from 'three'

export interface IArtworkAdvancedProps {}

export default function ArtworkAdvanced(props: IArtworkAdvancedProps) {
    const { activeArtWork, highlights, setActiveArtwork, setArtwork } =
        useConfiguratorContext()
    const inputRef = React.useRef<HTMLInputElement | undefined>()

    const artwork = activeArtWork
        ? getLocalImageUrl(
              new File([activeArtWork.blob], activeArtWork.name)
          ) || ''
        : highlights.length === 1 && highlights[0].mesh.material.map
        ? captureImage(highlights[0].mesh)
        : 'https://i0.wp.com/sumac.com.hk/wp-content/uploads/2022/11/placeholder.png?ssl=1'

    function handleOnUpload(e: React.ChangeEvent<HTMLInputElement>) {
        if (!e.target.files) return
        const file = e.target.files[0]
        setActiveArtwork({
            blob: new Blob([file]),
            id: uuid(),
            name: file.name,
            path: file.webkitRelativePath,
        })
        setArtwork((artwork) => [
            ...artwork,
            {
                blob: new Blob([file]),
                id: uuid(),
                name: file.name,
                path: file.webkitRelativePath,
            },
        ])

        highlights.map((highlight) => {
            if (highlight.isActive) {
                highlight.mesh.material.map = createMaterial(
                    getLocalImageUrl(file) || '',
                    {
                        offset: {
                            u: highlight.mesh.material.map?.offset?.x || 0,
                            v: highlight.mesh.material.map?.offset?.y || 0,
                        },
                        scale: {
                            u: highlight.mesh.material?.map?.repeat?.x || 0,
                            v: highlight.mesh.material?.map?.repeat?.y || 0,
                        },
                    }
                ).upscale
            }
        })
    }

    function handleOnDelete() {
        highlights.map((highlight) => {
            if (highlight.isActive) {
                highlight.mesh.material.map = null
            }
        })
        setActiveArtwork(undefined)
    }

    return (
        <Box px="16px">
            <Box>
                <Box>
                    <Image
                        className="img-clear"
                        width="100%"
                        height="100px"
                        src={artwork}
                    />
                    <Box
                        display="flex"
                        alignItems="center"
                        gridColumnGap="20px"
                    >
                        {false && (
                            <Button
                                p="0px"
                                bg="transparent"
                                border="none"
                                onClick={() => {}}
                            >
                                <SettingsIco />
                            </Button>
                        )}
                        <Button
                            p="0px"
                            bg="transparent"
                            border="none"
                            onClick={() =>
                                inputRef.current &&
                                inputRef.current.click &&
                                inputRef.current.click()
                            }
                        >
                            <UploadFile />
                        </Button>
                        <Input
                            autoComplete="false"
                            type="file"
                            ref={inputRef}
                            onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                            ) => handleOnUpload(e)}
                            onClick={(e: any) => {
                                e.target.value = null
                            }}
                            display="none"
                            bg="none"
                        />

                        <Link
                            href={artwork}
                            download={'base_map' + '.png'}
                            bg="white"
                            style={{
                                border: 'none',
                                borderRadius: '4px',
                                padding: '8px',
                                alignItems: 'center',
                                justifyContent: 'center',
                                display: 'flex',
                            }}
                            className="download-link"
                        >
                            <Download />
                        </Link>
                        <Button
                            onClick={() => handleOnDelete()}
                            p="0px"
                            bg="transparent"
                            border="none"
                        >
                            <DeleteIco />
                        </Button>
                    </Box>
                </Box>
                <DivisionLine />
                <Box mt="16px">
                    <Box>
                        <Text my="0px" mb="8px" fontSize="12px">
                            Scale
                        </Text>
                        <UVValues type="repeat" />
                    </Box>
                    <Box mt="16px">
                        <Text my="0px" mb="8px" fontSize="12px">
                            Offset
                        </Text>
                        <UVValues type="offset" />
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

function UVValues({ type }: { type: 'offset' | 'repeat' }) {
    const { highlights } = useConfiguratorContext()
    const [Uvs, setUvs] = React.useState({
        u: highlights[0]?.mesh?.material?.map
            ? highlights[0]?.mesh?.material?.map[type]?.x
            : 0,
        v: highlights[0]?.mesh?.material?.map
            ? highlights[0]?.mesh?.material?.map[type]?.y
            : 0,
    })
    function handleUvChange(
        e: React.ChangeEvent<HTMLInputElement>,
        coord: 'u' | 'v' | 'uv'
    ) {
        if (coord === 'uv') {
            setUvs({
                u: e.target.value,
                v: e.target.value,
            })
        } else {
            setUvs({
                ...Uvs,
                [coord]: e.target.value,
            })
        }
        highlights.map((highlight) => {
            if (highlight.isActive && highlight.mesh.material.map) {
                highlight.mesh.material.map[type] = new THREE.Vector2(
                    coord === 'uv' ? e.target.value : Uvs.u,
                    coord === 'uv' ? e.target.value : Uvs.v
                )
            }
        })
    }
    return (
        <Box display="flex" flexDirection="column" gridRowGap="12px">
            <Box display="flex" gridColumnGap="12px">
                <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    gridRowGap="4px"
                >
                    <Text my="0px" fontSize="12px">
                        U
                    </Text>
                    <Input
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            handleUvChange(e, 'u')
                        }
                        bg="transparent"
                        border="solid"
                        borderRadius="4px"
                        borderColor="gray350"
                        borderWidth={1}
                        value={Uvs.u}
                        px="8px"
                        py="4px"
                        width="58px"
                        placeholder={highlights.length > 1 ? 'Mixed' : Uvs.u}
                    />
                </Box>
                <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    gridRowGap="4px"
                >
                    <Text my="0px" fontSize="12px">
                        V
                    </Text>
                    <Input
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            handleUvChange(e, 'v')
                        }
                        bg="transparent"
                        border="solid"
                        borderRadius="4px"
                        borderColor="gray350"
                        borderWidth={1}
                        value={Uvs.v}
                        px="8px"
                        py="4px"
                        width="58px"
                        placeholder={highlights.length > 1 ? 'Mixed' : Uvs.v}
                    />
                </Box>
            </Box>
            <Input
                mt="12px"
                className="intensity-slider"
                width="100%"
                value={Uvs.u}
                onChange={(e) => handleUvChange(e, 'uv')}
                type="range"
                min="0"
                step="0.001"
                max="1"
                bg="trasparent"
            ></Input>
        </Box>
    )
}
