import React from 'react'
import { ExtensionIcoSM } from 'src/components/file-display/file-display-2'
import { processFileName } from 'src/components/file-display/file-display.helper'
import { Box, Text } from 'src/components/utility'

function FileBadge({ fileName }: { fileName: string }) {
    const displayName = processFileName(fileName)
    return (
        <Box display="flex" alignItems="center" gridGap="4px">
            <ExtensionIcoSM
                type={fileName
                    ?.split('.')
                    [fileName.split('.').length - 1].toLowerCase()}
            />
            <Text
                className="overflow__elipses"
                fontSize="12px"
                my="0px"
                fontFamily="Rubik"
                color="primary"
            >
                {displayName}
            </Text>
        </Box>
    )
}

export default FileBadge
