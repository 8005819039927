import { Box } from '../utility'

export const SideBarGroupedNavigationLinks = ({
    children,
}: {
    children: React.ReactNode
}) => {
    return (
        <Box
            display="flex"
            width="100%"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
        >
            {children}
        </Box>
    )
}
