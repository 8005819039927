import * as React from 'react'
import { DragDropArea } from 'src/components-v2/drag-drop-modal/drag-drop-area'
import { Modal } from 'src/components/modals/modal'
import { PlusIco } from 'src/components/svg-icons'
import { Box, Button, Image, Input, Text } from 'src/components/utility'
import { useConfiguratorContext } from '../../configurator.context'
import { getLocalImageUrl } from 'src/helpers/get-image-url'
import 'src/components/brightness-slider/slider.style.css'
import { createMaterial } from '../../helper/create-material'

export interface IArtworkProps {}

export default function ArtworkBasic(props: IArtworkProps) {
    const [dragdropModal, setDragdropModal] = React.useState(false)
    const { artWork, setArtwork, highlights, activeArtWork, setActiveArtwork } =
        useConfiguratorContext()

    function handelArtworkUpdate(artwork: {
        name: string
        path: string
        blob: Blob
        id: string
    }) {
        highlights.map((highlight) => {
            if (highlight.isActive && artwork?.blob) {
                const baseMap = createMaterial(
                    getLocalImageUrl(new File([artwork.blob], artwork?.name)) ||
                        '',
                    {
                        offset: {
                            u: highlight.mesh.material.map.offset.x,
                            v: highlight.mesh.material.map.offset.y,
                        },
                        scale: {
                            u: highlight.mesh.material.map.repeat.x,
                            v: highlight.mesh.material.map.repeat.y,
                        },
                    }
                ).upscale

                highlight.mesh.material.map = baseMap
            }
        })
    }

    function handleScaleOffsetUpdate(config: {
        scale?: number
        offset?: number
    }) {
        highlights.map((highlight) => {
            if (highlight.isActive && config.scale) {
                highlight.mesh.material.map.repeat.x = config.scale
                highlight.mesh.material.map.repeat.y = config.scale
            }
            if (highlight.isActive && config.offset) {
                highlight.mesh.material.map.offset.x = config.offset
                highlight.mesh.material.map.offset.y = config.offset
            }
        })
    }

    return (
        <Box px="16px">
            <Box
                alignItems="center"
                display="flex"
                flexWrap="wrap"
                gridColumnGap="8px"
                gridRowGap="8px"
            >
                {artWork.map((artwork, key: number) => {
                    return (
                        <Box
                            border="solid"
                            borderColor={
                                activeArtWork?.id === artwork.id
                                    ? 'primary'
                                    : 'white'
                            }
                            borderRadius="4px"
                            onClick={() => {
                                setActiveArtwork(artwork)
                                handelArtworkUpdate(artwork)
                            }}
                            borderWidth={1}
                            display="flex"
                            alignItems="center"
                        >
                            <Image
                                border="solid"
                                borderColor="white"
                                borderRadius="4px"
                                borderWidth={1}
                                width="48px"
                                height="48px"
                                src={getLocalImageUrl(
                                    new File([artwork.blob], artwork.name)
                                )}
                            />
                        </Box>
                    )
                })}
                <Button
                    type="button"
                    onClick={() => {
                        setDragdropModal(true)
                    }}
                    className="dashed__border"
                    border="none"
                    bg={'F0F2F3'}
                    width="48px"
                    height="48px"
                >
                    <PlusIco />
                </Button>
                <DragDropModal
                    onClick={() => {
                        setDragdropModal(false)
                    }}
                    isModal={dragdropModal}
                    isLoading={false}
                    setModal={(bool) => setDragdropModal(bool)}
                />
            </Box>

            <Box mt="16px" mb="16px">
                <Text my="0px" fontSize="12px">
                    Scale
                </Text>
                <Input
                    mt="12px"
                    className="intensity-slider"
                    width="100%"
                    onChange={(e) =>
                        handleScaleOffsetUpdate({
                            scale: Number(e.target.value),
                        })
                    }
                    type="range"
                    min="0"
                    step="0.001"
                    max="1"
                    bg="trasparent"
                ></Input>
            </Box>
            <Box>
                <Text my="0px" fontSize="12px">
                    Offset
                </Text>
                <Input
                    mt="12px"
                    className="intensity-slider"
                    width="100%"
                    onChange={(e) =>
                        handleScaleOffsetUpdate({
                            offset: Number(e.target.value),
                        })
                    }
                    type="range"
                    min="0"
                    step="0.01"
                    max="100"
                    bg="trasparent"
                ></Input>
            </Box>
        </Box>
    )
}

function DragDropModal({
    onClick,
    isLoading,
    isModal,
    setModal,
}: {
    onClick: (files: any) => void
    isLoading: boolean
    isModal: boolean
    setModal: (x: boolean) => void
}) {
    const { setArtwork, artWork } = useConfiguratorContext()
    const [fileModalData, setFileModalData] = React.useState<{
        isFileModal: boolean
        files: { name: string; path: string; blob: Blob; id: string }[]
    }>({
        isFileModal: false,
        files: [],
    })

    return (
        <Modal
            onClose={() => {
                setModal(false)
                setFileModalData({
                    files: [],
                    isFileModal: false,
                })
            }}
            isModal={isModal}
            onClick={() => {
                setArtwork([...fileModalData.files, ...artWork])
                onClick(fileModalData)
            }}
            primaryButtonTitle="Upload"
            secondaryButtonTitle="Cancel"
            title="Upload Files"
            isLoading={isLoading}
            disablePrimaryButton={isLoading || !fileModalData.files.length}
        >
            <Box
                p="24px"
                width="100%"
                height="330px"
                overflowY={fileModalData.files.length > 8 ? 'auto' : 'hidden'}
            >
                <DragDropArea
                    multiple={true}
                    height="100%"
                    fileModal={fileModalData}
                    updateFileCb={(fileModal) => {
                        setFileModalData({
                            files: [...fileModal.files],
                            isFileModal: true,
                        })
                    }}
                />
            </Box>
        </Modal>
    )
}
