import { useApolloClient, useQuery } from '@apollo/client'
import React from 'react'
import { Modal } from 'src/components/modals/modal'
import { Box, Text } from 'src/components/utility'
import { GetProductTechPackImages } from '../../services/graphql/entities/product/product.queries'
import { Loader } from 'src/components/loader/loader'
import { FileDisplay2 } from 'src/components/file-display/file-display-2'
import { getImageUrl, getLibraryFileUrl } from 'src/helpers/get-image-url'
import { getThumbnail } from 'src/components/annotation-comments/annotation-comments.helper'
import axios from 'axios'
import { ASSIGN_FILE_FROM_LIBRARY } from 'src/services'
import { getLocalStorage } from 'src/helpers'
import { ASSIGN_FILE_FROM_FILES } from 'src/services/api/endpoints'
import { toast } from 'react-toastify'

function AssignFromTodoModal({
    onComplete,
    onClose,
    isOpen,
    entityId,
    entityName,
    productId,
}: {
    onComplete?: () => void
    onClose: () => void
    isOpen: boolean
    entityId: number
    productId: number
    entityName:
        | 'todos'
        | 'products'
        | 'sub_todos'
        | 'collections'
        | 'media'
        | 'product_variants'
}) {
    const { data, loading } = useQuery(GetProductTechPackImages, {
        skip: !productId,
        fetchPolicy: 'no-cache',
        variables: {
            id: productId,
        },
    })
    const client = useApolloClient()

    if (loading) {
        return <Loader />
    }

    const filesArray = data?.product_variants_by_pk?.todos.reduce(
        (acc: any, todo: any) => {
            todo.sub_todos.forEach((subTodo: any) => {
                acc = [...acc, ...subTodo.files]
                subTodo.sub_todo_sections.forEach((subTodoSection: any) => {
                    acc = [...acc, ...subTodoSection.files]
                })
            })

            return acc
        },
        []
    )

    async function fileClickHandler(fileData: any) {
        const payload: any = {
            entity_name: entityName,
            entity_id: entityId,
            assigned_as: 'cover_pic',
        }
        if (!fileData.from_library) {
            payload.file_ids = [fileData.id]
            try {
                const response = await axios.post(
                    ASSIGN_FILE_FROM_FILES,
                    payload,
                    {
                        headers: {
                            'x-auth-token': getLocalStorage('authToken'),
                        },
                    }
                )
                if (response.status === 200) {
                    onComplete && onComplete()
                    client.refetchQueries({
                        include: 'active',
                    })
                    toast('Thumbnail assigned successfully', {
                        className: 'custom-toaster toaster-success',
                    })
                }
            } catch {
                toast('Failed to assign thumbnail', {
                    className: 'custom-toaster toaster-error',
                })
            }
        } else {
            payload.library_file_ids = [fileData.id]
            try {
                const response = await axios.post(
                    ASSIGN_FILE_FROM_LIBRARY,
                    payload,
                    {
                        headers: {
                            'x-auth-token': getLocalStorage('authToken'),
                        },
                    }
                )
                if (response.status === 200) {
                    onComplete && onComplete()
                    client.refetchQueries({
                        include: 'active',
                    })
                    toast('Thumbnail assigned successfully', {
                        className: 'custom-toaster toaster-success',
                    })
                }
            } catch {
                toast('Failed to assign thumbnail', {
                    className: 'custom-toaster toaster-error',
                })
            }
        }
        onClose()
    }

    const allowedFileTypes = ['png', 'jpg', 'jpeg']

    const allowedFiles = filesArray.filter((file: any) => {
        const fileData = file.library_file
            ? {
                  ...file.library_file,
                  from_library: true,
              }
            : file.file
            ? {
                  ...file.file,
                  from_library: false,
              }
            : null
        if (!fileData) return false
        const type = fileData.name
            ?.split('.')
            [fileData.name.split('.').length - 1].toLowerCase()
        return allowedFileTypes.includes(type)
    })

    return (
        <Modal
            onClose={onClose}
            isModal={isOpen}
            onClick={() => {}}
            disableActions={true}
            primaryButtonTitle="Save"
            secondaryButtonTitle="Cancel"
            title="Select from Todo Techpacks"
            bg="white"
            preventDefault={true}
        >
            <Box
                display="flex"
                flexWrap="wrap"
                gridGap="12px"
                p="16px"
                maxWidth="80vw"
                maxHeight="80vh"
                overflow={'auto'}
                onClick={(e) => e.preventDefault()}
            >
                {allowedFiles?.length > 0 ? (
                    allowedFiles.map((file: any, key: number) => {
                        const fileData = file.library_file
                            ? {
                                  ...file.library_file,
                                  from_library: true,
                              }
                            : file.file
                            ? {
                                  ...file.file,
                                  from_library: false,
                              }
                            : null

                        return (
                            <FileDisplay2
                                isDownloadable
                                key={key}
                                onClick={() => fileClickHandler(fileData)}
                                name={fileData.name}
                                url={
                                    fileData.from_library
                                        ? getLibraryFileUrl(fileData.gid)
                                        : getImageUrl(fileData.gid)
                                }
                                thumbnail={getThumbnail(fileData)}
                                onDelete={() => {}}
                                width="80px"
                                height="84px"
                                isDeletePermitted={false}
                                onDragStart={(e) => {
                                    e.dataTransfer.setData(
                                        'application/json',
                                        JSON.stringify(fileData)
                                    )
                                }}
                                fileId={file.id}
                                id={`file_display_${file.id}`}
                            />
                        )
                    })
                ) : (
                    <Text>No files found</Text>
                )}
            </Box>
        </Modal>
    )
}

export default AssignFromTodoModal
